import ArrowCircleRight from '@mui/icons-material/ArrowCircleRight';
import CheckBox from '@mui/icons-material/CheckBox';
import Calendar from '@mui/icons-material/CalendarMonth';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import Download from '@mui/icons-material/Download';
import East from '@mui/icons-material/East';
import Error from '@mui/icons-material/Error';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Menu from '@mui/icons-material/Menu';
import North from '@mui/icons-material/North';
import Search from '@mui/icons-material/Search';
import Settings from '@mui/icons-material/Settings';
import South from '@mui/icons-material/South';
import Twitter from '@mui/icons-material/Twitter';
import Warning from '@mui/icons-material/Warning';
import West from '@mui/icons-material/West';
import YouTube from '@mui/icons-material/YouTube';
import Add from '@mui/icons-material/Add';
import AirplaneTicket from '@mui/icons-material/AirplaneTicket';

import { SvgIconProps, SvgIconTypeMap } from '@primitives';
import { SprinklesProps, useSprinklesProps } from '@theme';

const appIcons = {
  search: Search,
  arrowRight: East,
  arrowLeft: West,
  arrowUp: North,
  arrowDown: South,
  arrowCircleRight: ArrowCircleRight,
  calendar: Calendar,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  download: Download,

  expandMore: ExpandMore,
  expandLess: ExpandLess,
  settings: Settings,
  close: Close,
  menu: Menu,
  checkbox: CheckBox,
  checkboxEmpty: CheckBoxOutlineBlank,
  error: Error,
  warning: Warning,
  facebook: Facebook,
  instagram: Instagram,
  linkedIn: LinkedIn,
  twitter: Twitter,
  youTube: YouTube,
  ticket: AirplaneTicket,
  plus: Add,
} as const;

export type AppIcons = typeof appIcons;
export type AppIcon = AppIcons[AppIconName];
export type AppIconName = keyof AppIcons;
export type { AppIconName as IconName };

export type IconProps<
  D extends React.ElementType = SvgIconTypeMap['defaultComponent'],
  P = NoProps
> = SvgIconProps<
  D,
  {
    name: AppIconName;
  } & SprinklesProps &
    P
>;
const Icon = ({ name, ...rest }: IconProps) => {
  const Component = appIcons[name];
  const props = useSprinklesProps(rest);
  return <Component fontSize="inherit" {...props} />;
};

export default Icon;
