import { RecipeVariants } from '@theme/utils/createRecipeObj';
import {
  createUseRecipeHook,
  PropsWithClassName,
  SprinklesProps,
  useSprinkles,
  useSprinklesProps,
} from '@theme';

import { root } from './Divider.css';
import { Sprinkles } from '@theme/_sprinkles.css';
import classNames from 'classnames';

export type DividerStyleProps = RecipeVariants<typeof root.fn> & {
  margin?: Sprinkles['margin'];
  marginStart?: Sprinkles['margin'];
  marginEnd?: Sprinkles['margin'];
} & SprinklesProps;

const useDividerRecipe = createUseRecipeHook(root);

export const useDividerStyle = <P extends PropsWithClassName<DividerStyleProps>>({
  margin,
  marginEnd,
  marginStart,
  ...props
}: P) => {
  const inlineSprinkles: Sprinkles = {
    ...(props.direction === 'vertical'
      ? {
          marginLeft: marginStart ?? margin,
          marginRight: marginEnd ?? margin,
        }
      : {
          marginTop: marginStart ?? margin,
          marginBottom: marginEnd ?? margin,
        }),
  };

  const className = classNames(useSprinkles(inlineSprinkles), props.className);

  return useDividerRecipe(useSprinklesProps({ ...props, className }));
};
