import { useNextHydrated } from '@lib/utils/createToggleStore';
import TagManager from 'react-gtm-module';
import React from 'react';

const GOOGLE_TAG_MANAGER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

const tagManagerArgs = GOOGLE_TAG_MANAGER_ID
  ? {
      gtmId: GOOGLE_TAG_MANAGER_ID,
    }
  : null;

export const SeoScripts = () => {
  // const { asPath } = useRouter();
  const nextHydrated = useNextHydrated();

  React.useEffect(() => {
    if (!tagManagerArgs) return;
    TagManager.initialize(tagManagerArgs);
  }, []);

  if (!nextHydrated) return <></>;

  return <></>;
};
/*
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-XXXXXXX');</script>
<!-- End Google Tag Manager -->

Additionally, paste this code immediately after the opening <body> tag:

<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-XXXXXXX"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
*/
