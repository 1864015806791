import { createField } from '@formie/parse/formieParser';
import { useFormField } from '@formie/useForm';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@primitives';
import { gql } from 'graphql-request';
import { FieldErrors } from './FieldErrors';

gql`
  fragment fieldRadio on Field_Radio {
    ...fieldBase
    layout
    options {
      ...fieldOption
    }
  }
`;

export const Field_Radio = createField<'Field_Radio'>(({ field, ..._props }) => {
  const { name, handle, required, options, instructions, layout, labelPosition } = field;

  const { value, setValue, error, errors } = useFormField(handle, 'single');

  const handleChange = (_ev: React.SyntheticEvent<Element, Event>, val: string) => {
    setValue(val);
  };

  return (
    <FormControl required={required} error={error}>
      <FormLabel component="legend" sx={{ mb: 1 }} hidden={labelPosition === 'hidden'}>
        {name}
      </FormLabel>
      <FormHelperText>{instructions}</FormHelperText>
      <RadioGroup
        {...{
          value,
          name: handle,
          onChange: handleChange,
          row: layout === 'horizontal',
          sx: { mb: 1 },
        }}>
        {options.map((v) => (
          <FormControlLabel key={v.value} value={v.value} control={<Radio />} label={v.label} />
        ))}
      </RadioGroup>
      <FieldErrors errors={errors} />
    </FormControl>
  );
});
