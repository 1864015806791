import Txt from '@primitive/Txt';
import { DefaultSelectField } from '@formie/Fields/DefaultSelectField';
import { getOrderByOptions, OrderByQueryString, useEntryIndex } from '@lib/features/entryIndexes';
import { keys } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import React from 'react';

const sortOptions = getOrderByOptions();

export const EntryIndexSort = () => {
  const t = useTranslations();

  const { trigger, orderBy } = useEntryIndex(({ trigger, args: { orderBy } }) => ({
    trigger,
    orderBy,
  }));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    trigger({
      orderBy: event.target.value as OrderByQueryString,
    });
  };

  const options = keys(sortOptions).map((key) => ({ value: key, label: sortOptions[key] }));

  return (
    <>
      <Txt variant="bodyAlt" color="primary">
        {t('actions.sortBy')}
      </Txt>

      <DefaultSelectField select value={orderBy} onChange={handleChange} options={options} />
    </>
  );
};
