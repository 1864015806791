import { sprinkles, Sprinkles } from '@theme/_sprinkles.css';
import classNames from 'classnames';

export type SprinklesProps = {
  cx?: Sprinkles;
};

export type UseSprinklesProps = SprinklesProps & {
  className?: string;
};

export const useSprinkles = (values: Sprinkles) => sprinkles(values);

export const useSprinklesProps = <T extends UseSprinklesProps>({ cx = {}, ...props }: T) => {
  const className = classNames(sprinkles(cx), props.className);

  return {
    ...props,
    className,
  };
};
