import IconButton from '@component/IconButton';
import Flex from '@componentPrimitives/Flex';
import Txt, { TxtProps } from '@componentPrimitives/Txt';
import { useEntryIndex } from '@lib/features/entryIndexes';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { CircularProgress, FormControl, Input, InputAdornment, Stack } from '@mui/material';
import { useTranslations } from 'next-intl';
import React, { useEffect, useState } from 'react';
import * as style from './Search.css';

export type SearchFormProps = {
  InputProps?: TxtProps<'input'>;
  liveSubmit?: boolean;
  showCount?: boolean;
  showSpinner?: boolean;
  term?: string;
};

const SearchForm = React.forwardRef<HTMLFormElement, SearchFormProps>(function SearchForm(
  { liveSubmit, InputProps, showCount, showSpinner, term: customTerm = '' },
  ref
) {
  const t = useTranslations('search');
  const [term, setTerm] = useState(customTerm);
  const triggerSearch = useEntryIndex((s) => s.trigger);
  const { nResults, isLoading, hasQuery, query } = useEntryIndex(
    ({ entryCount, isLoading, args }) => ({
      hasQuery: !!args.query?.length,
      query: args.query,
      nResults: entryCount,
      isLoading,
    })
  );

  const handleSubmit = (ev: React.SyntheticEvent<HTMLFormElement>) => {
    ev.preventDefault();

    if (term) triggerSearch({ query: term });
    // if (term) triggerSearch({ query: term });
    // router.push(`/search/${term}`);
  };

  useEffect(() => {
    if (customTerm) triggerSearch({ query: customTerm });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customTerm]);

  React.useEffect(() => {
    liveSubmit && triggerSearch({ query: term });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  return (
    <>
      <Flex
        ref={ref}
        as="form"
        onSubmit={handleSubmit}
        noValidate
        direction="row"
        alignItems="center"
        className={style.form}>
        <Txt
          as="input"
          variant="large"
          placeholder={liveSubmit ? t('autoInputPlaceholder') : t('inputPlaceholder')}
          type="text"
          {...mergePropsClassName(InputProps, style.input)}
          value={term}
          onChange={(ev) => {
            setTerm(ev.target.value);
          }}
        />
        <IconButton type="submit" icon="search" disableBorder variant="text" underline="none" />
      </Flex>
      <Flex spacing="xs" direction="row" alignItems="center">
        {hasQuery && showCount && <Txt variant="large">{t('nResults', { nResults, query })}</Txt>}
        {isLoading && showSpinner && <CircularProgress color="primary" size={18} />}
      </Flex>
    </>
  );
});

export default SearchForm;
