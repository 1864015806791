import { mapPxToStrings } from '../utils/common';

// ----------------------------------------------------------------------------------------------------
// --- Breakpoints ---

export const breakpoints = {
  // _: 0,
  xs: 0,
  sm: 576, // - Mobile
  md: 768, // - Smallest tablet
  lg: 1024, // - Largest tablet
  xl: 1200, // - Smallest desktop
  mx: 1512, // - Large desktop
};
export type Breakpoint = keyof typeof breakpoints;

/**
 * Breakpoints with device names
 */
// export const screen = {
//   tablet: 'sm',
//   desktop: 'md',
//   desktopLarge: 'lg',
// };

export const breakpointsPx = mapPxToStrings(breakpoints);
