import { AppGlobal, parseGlobals } from '@lib/features/globals/globals';
import { gql } from 'graphql-request';
import client from '@lib/fetch/client';
import { getSdk } from './_generated/getGlobals.generated';
import { withSite } from '@lib/hooks';

// ---------------------------------------------------------------------------------------------- //
// ---- GQL parts

gql`
  fragment globalDefaults on defaults_GlobalSet {
    __typename
    image {
      ...image
    }
  }

  fragment globalTheme on theme_GlobalSet {
    __typename
    colorTheme {
      label
    }
  }

  fragment globalEventSettings on eventSettings_GlobalSet {
    __typename
    vivaticketUrl
    bookingUrl
  }

  fragment globalSubscribeForm on subscribeForm_GlobalSet {
    __typename
    heading
    content: htmlContentSimple
    form {
      ...formieForm
    }
  }

  fragment globalSocial on social_GlobalSet {
    __typename
    heading
    socialLinks {
      ...socialLinks
    }
  }
  fragment globalOpenHours on openHours_GlobalSet {
    __typename
    heading
    content: htmlContentSimple
    linkCta {
      ...linkField
    }
  }
  fragment globalCopyright on copyright_GlobalSet {
    __typename
    label
  }
  fragment globalAcknowledgementOfCountry on acknowledgementOfCountry_GlobalSet {
    __typename
    content: htmlContentSimple
    images {
      ...imageBase
      ...imageSize_squareSmFit
    }
  }

  fragment globals on GlobalSetInterface {
    ...globalDefaults
    ...globalTheme
    ...globalEventSettings
    ...globalSubscribeForm
    ...globalSocial
    ...globalOpenHours
    ...globalCopyright
    ...globalAcknowledgementOfCountry
  }

  query globals($site: [String] = "default") {
    globalSets(site: $site) {
      ...globals
    }
  }
`;

// ---------------------------------------------------------------------------------------------- //
// ---- Set up the Types

export type AppGlobals = ReturnOrPromiseType<typeof getGlobals>;

// ---------------------------------------------------------------------------------------------- //
// ---- Parsing Functions

/**
 * Parsed the queried fragment
 */

export const getGlobals = async () => {
  const sdk = getSdk(client);
  const data = await sdk.globals(withSite());
  const globals = parseGlobals(data.globalSets);
  return {
    defaults: globals.find(({ __typename }) => __typename === 'defaults_GlobalSet') as
      | AppGlobal<'defaults_GlobalSet'>
      | undefined,
    theme: globals.find(({ __typename }) => __typename === 'theme_GlobalSet') as
      | AppGlobal<'theme_GlobalSet'>
      | undefined,
    eventSettings: globals.find(({ __typename }) => __typename === 'eventSettings_GlobalSet') as
      | AppGlobal<'eventSettings_GlobalSet'>
      | undefined,
    subscribeForm: globals.find(({ __typename }) => __typename === 'subscribeForm_GlobalSet') as
      | AppGlobal<'subscribeForm_GlobalSet'>
      | undefined,
    social: globals.find(({ __typename }) => __typename === 'social_GlobalSet') as
      | AppGlobal<'social_GlobalSet'>
      | undefined,
    openHours: globals.find(({ __typename }) => __typename === 'openHours_GlobalSet') as
      | AppGlobal<'openHours_GlobalSet'>
      | undefined,
    copyright: globals.find(({ __typename }) => __typename === 'copyright_GlobalSet') as
      | AppGlobal<'copyright_GlobalSet'>
      | undefined,
    acknowledgementOfCountry: globals.find(
      ({ __typename }) => __typename === 'acknowledgementOfCountry_GlobalSet'
    ) as AppGlobal<'acknowledgementOfCountry_GlobalSet'> | undefined,
  };
};

// ---------------------------------------------------------------------------------------------- //
// ---- The Getter ----
