import { OverrideProps } from '@componentPrimitives/Polymorphic';
import { TxtStyleProps, useTxtStyle } from '@primitive/Txt';
import classNames from 'classnames';
import * as style from './Link.css';

export type LinkStyleProps = OverrideProps<TxtStyleProps, style.LinkVariants>;

export type UseTxtStyleProps = LinkStyleProps & {
  className?: string;
};

export const useLinkStyle = <T extends UseTxtStyleProps>({ underline, ...rest }: T) => {
  const props = useTxtStyle(rest);

  const linkStyleClass = style.root({ underline });

  const className = classNames(props.className, linkStyleClass);

  return {
    ...props,
    className,
  };
};
