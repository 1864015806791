import Grid from '@componentPrimitives/Grid';
import Btn from '@componentPrimitives/Btn';
import Txt from '@primitive/Txt';
import { Alert, CircularProgress, Collapse, Stack } from '@primitives';
import { useTranslations } from 'next-intl';
import { useForm } from './useForm';

export const FormieSubmitRow = () => {
  const { isLoading, isError, isSuccess, messages } = useForm(
    ({ isLoading, isError, isSuccess, messages }) => ({ isLoading, isError, isSuccess, messages })
  );

  // const values = useForm((state) => state.values);
  // console.log(values);

  const t = useTranslations();

  const spacing = 4;
  return (
    <Stack textAlign="left">
      <Collapse in={isError} unmountOnExit>
        <Alert severity="error" sx={{ mb: spacing }}>
          {/* <AlertTitle>{t('feedback.error')}</AlertTitle> */}
          <Txt html variant="small">
            {messages.error}
          </Txt>
        </Alert>
      </Collapse>
      <Collapse in={isSuccess} unmountOnExit>
        <Alert severity="success" sx={{ mb: spacing }}>
          {/* <AlertTitle>{t('feedback.success')}</AlertTitle> */}
          <Txt html>{messages.success}</Txt>
        </Alert>
      </Collapse>
      <Grid>
        <Grid.Col xs={6}>
          <Btn type="submit" variant="filled" disabled={isLoading}>
            {t('actions.submit')}
          </Btn>
        </Grid.Col>
        <Grid.Col xs={6} cx={{ textAlign: 'right' }}>
          {isLoading && <CircularProgress color="primary" size={40} />}
        </Grid.Col>
      </Grid>
    </Stack>
  );
};
