import React from 'react';
import { TruncateOptions } from 'lodash';
import truncate from 'lodash/truncate';
import { stripHtml } from '@lib/utils/htmlHelpers';

export type TruncateProps = {
  /**
   * Character limit
   */
  maxChars?: number;
  /**
   * Provide customised truncating options
   */
  truncateOptions?: TruncateOptions;
};

export const useTruncateProps = <P extends React.PropsWithChildren<TruncateProps>>(props: P) => {
  const { maxChars, truncateOptions, children: childrenOriginal, ...rest } = props;

  let children = childrenOriginal;

  // Perform truncation
  if (typeof childrenOriginal === 'string' && (maxChars || truncateOptions)) {
    children = truncate(stripHtml(childrenOriginal), {
      length: maxChars,
      ...truncateOptions,
    });
  }

  return {
    childrenOriginal,
    props: { ...rest, children }, //as Omit<P, keyof TruncateProps>,
  };
};
