import Btn from '@componentPrimitives/Btn';
import Divider from '@componentPrimitives/Divider';
import Flex from '@componentPrimitives/Flex';
import Card from '@components/Card';
import { useEntryTypeLabel } from '@lib/hooks';
import { EntryCardProps } from './EntryCard';
import * as style from './EntryCard.css';

export type SearchResultCardProps = EntryCardProps;

export const SearchResultCard = ({
  item,

  ...props
}: SearchResultCardProps) => {
  const entryTypeLabel = useEntryTypeLabel(item);
  const { uri: href, title, entrySummary: description, entryImage: image, postDate } = item;

  return (
    <Card
      {...props}
      elevation={false}
      rounded={false}
      colorSet="paper"
      item={{
        href,
        title,
        // meta: postDate,
        description,
        image,
      }}>
      <Card.Body cx={{ pX: 'none', pY: 'md' }}>
        <Flex direction="row" spacing="sm" wrap alignItems="center" justifyContent="start">
          <Card.Title variant="h6" className={style.searchResultTitle} />
          <Btn as="span" variant="outlined" size="small">
            {entryTypeLabel}
          </Btn>
        </Flex>
        {/* <Card.Meta cx={{ mT: '3xs' }} /> */}
        <Card.Description maxChars={200} cx={{ mY: 'xs' }} />
      </Card.Body>
      <Divider />
    </Card>
  );
};
export const SearchResultCardCompact = ({ item, ...props }: SearchResultCardProps) => {
  const entryTypeLabel = useEntryTypeLabel(item);
  const { uri: href, title } = item;

  return (
    // <Link  underline="none">
    <Flex
      className={style.searchResult}
      as={'a'}
      href={href}
      direction="row"
      justifyContent="between"
      cx={{ pX: 'xs', pY: 'xs' }}>
      <Btn variant="nav">{title}</Btn>
      {/* <Card.Title variant="h6" /> */}
      <Card.Cta variant="outlined" size="small">
        {entryTypeLabel}
      </Card.Cta>
    </Flex>
  );
};
