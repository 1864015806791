import Logo from '@component/Logo';
import TopButton from '@component/TopButton';
import Divider from '@componentPrimitives/Divider';
import Flex from '@componentPrimitives/Flex';
import Grid from '@componentPrimitives/Grid';
import Container from '@componentPrimitives/Container';
import OpenHours from '@components/OpenHours';
import Box from '@primitive/Box';
import * as style from './Footer.css';
import { AcknowledgementOfCountry } from './Footer_AcknowledgementOfCountry';
import { Copyright } from './Footer_Copyright';
import { FooterNav } from './Footer_FooterNav';
import { PoliciesNav } from './Footer_PoliciesNav';
import { FooterSocialLinks } from './Footer_Social';
import { SubscribeForm } from './Footer_SubscribeForm';
import { useInView } from 'framer-motion';
import React from 'react';
import { DarkTheme } from '@components/ThemeProvider';
import { useIsRTC } from '@lib/hooks';

const Footer = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { margin: '-200px' });
  const isRtc = useIsRTC();
  return (
    <DarkTheme>
      <Box
        ref={ref}
        as="footer"
        colorTheme="dark"
        colorSet="paper"
        paper
        className={style.root({ inView })}>
        <Container>
          <Grid cx={{ textAlign: 'right', mB: 'md' }}>
            <Grid.Col className={style.gridCol}>
              <TopButton cx={{ marginLeft: 'auto' }} />
            </Grid.Col>
          </Grid>
          <Grid
            className={style.mainRow}
            rowGutter
            cx={{
              justifyContent: 'between',
            }}>
            <Grid.Col className={style.gridCol} lg={4}>
              <AcknowledgementOfCountry cx={{ mB: 'md' }} />
              <Logo variant={isRtc ? 'rtcMono' : 'shma'} cx={{ marginY: 'lg' }} />
              <FooterSocialLinks />
            </Grid.Col>
            <Grid.Col className={style.gridCol} lg={7}>
              <SubscribeForm cx={{ mB: 'md' }} />
              <FooterNav cx={{ mB: 'md' }} />
              <OpenHours />
            </Grid.Col>
          </Grid>
          <Divider margin="md" />
          <Flex
            direction={{ xs: 'column-reverse', lg: 'row' }}
            wrap="wrap"
            justifyContent="between"
            alignItems={{ lg: 'center' }}
            columnGap="xl"
            rowGap="xs"
            className={style.endRow}
            cx={{ mY: 'md' }}>
            <Copyright />
            <PoliciesNav />
          </Flex>
        </Container>
      </Box>
    </DarkTheme>
  );
};

export default Footer;
