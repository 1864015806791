import { useEntryIndex } from '@lib/features/entryIndexes';
import { useEffect } from 'react';

export const EntryIndexLoader = () => {
  const trigger = useEntryIndex((s) => s.trigger);

  useEffect(() => {
    trigger({ includeCategories: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
