import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { FieldBaseFragmentDoc, FieldCommonFragmentDoc, FieldAttributeFragmentDoc, FieldOptionFragmentDoc } from '../../gql/_generated/fieldCommon.generated';
export type FieldSingleLineTextFragment = { __typename: 'Field_SingleLineText', defaultValue?: string | null, id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldMultiLineTextFragment = { __typename: 'Field_MultiLineText', defaultValue?: string | null, id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldEmailFragment = { __typename: 'Field_Email', defaultValue?: string | null, id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldNumberFragment = { __typename: 'Field_Number', defaultValue?: string | null, id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldPhoneFragment = { __typename: 'Field_Phone', defaultValue?: string | null, id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export type FieldDateFragment = { __typename: 'Field_Date', id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export const FieldSingleLineTextFragmentDoc = gql`
    fragment fieldSingleLineText on Field_SingleLineText {
  ...fieldBase
}
    `;
export const FieldMultiLineTextFragmentDoc = gql`
    fragment fieldMultiLineText on Field_MultiLineText {
  ...fieldBase
}
    `;
export const FieldEmailFragmentDoc = gql`
    fragment fieldEmail on Field_Email {
  ...fieldBase
}
    `;
export const FieldNumberFragmentDoc = gql`
    fragment fieldNumber on Field_Number {
  ...fieldBase
}
    `;
export const FieldPhoneFragmentDoc = gql`
    fragment fieldPhone on Field_Phone {
  ...fieldBase
}
    `;
export const FieldDateFragmentDoc = gql`
    fragment fieldDate on Field_Date {
  ...fieldCommon
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;