import Divider from '@componentPrimitives/Divider';
import React from 'react';
import { EntryIndexFilter, EntryIndexFilterProps } from './EntryIndex.Filter';
import {
  EntryIndexFiltersPopover,
  EntryIndexFiltersPopoverProps,
} from './EntryIndex.FiltersPopover';

export type EntryIndexFiltersProps = {
  items?: EntryIndexFilterProps[];
  FiltersPopoverProps?: EntryIndexFiltersPopoverProps;
};

export const EntryIndexFilters = ({ items = [], FiltersPopoverProps }: EntryIndexFiltersProps) => {
  return (
    <EntryIndexFiltersPopover {...FiltersPopoverProps}>
      {items.map((item, i) => (
        <React.Fragment key={i}>
          {!!i && <Divider />}
          <EntryIndexFilter {...item} />
        </React.Fragment>
      ))}
    </EntryIndexFiltersPopover>
  );
};
