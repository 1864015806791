import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Box, { BoxProps } from '@primitive/Box';
import * as style from './Card.css';
import { CardCta } from './Card.Cta';

export type CardFootProps = BoxProps<'div'>;

export const CardFoot = ({ children, ...props }: CardFootProps) => {
  return (
    <Box {...props} {...mergePropsClassName(props, style.foot)}>
      {children || <CardCta />}
    </Box>
  );
};
