/* eslint-disable @typescript-eslint/no-explicit-any */
import { isString } from 'lodash';

const pageTemplates = ['standard', 'overview', 'home'] as const;

export type PageTemplate = typeof pageTemplates[number];

export const parsePageTemplate = (data: any) => {
  return (
    isString(data) && pageTemplates.includes(data as any) ? data : 'standard'
  ) as PageTemplate;
};
