import { DefaultSelectField } from '@formie/Fields/DefaultSelectField';
import { CategoryTypeId } from '@lib/features/categories';
import { useEntryIndex, useEntryIndexCategorySelection } from '@lib/features/entryIndexes';
import Txt from '@primitive/Txt';
import Flex, { FlexProps } from '@componentPrimitives/Flex';
import { keys } from '@liquorice/allsorts-craftcms-nextjs';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslations } from 'next-intl';
import React from 'react';

export type EntryIndexFilterProps = FlexProps<
  'div',
  {
    categoryTypeId?: CategoryTypeId;
    /** The label for this filter */
    label?: string;
    /** Label for 'select all' option */
    labelAll?: string;
    /** Include a 'select all' option */
    includeAll?: boolean;
    variant?: 'select' | 'radio' | 'checkbox';
  }
>;

export const EntryIndexFilter = ({
  categoryTypeId: maybeCategoryTypeId,
  label,
  labelAll,
  includeAll,
  variant = 'checkbox',
  ...props
}: EntryIndexFilterProps) => {
  const t = useTranslations();

  const { selection, selectionAdd, selectionRemove } = useEntryIndexCategorySelection();
  const primaryCategoryTypeId = useEntryIndex((s) => keys(s.categories))[0];
  const categoryTypeId = maybeCategoryTypeId ?? primaryCategoryTypeId;
  const categories = useEntryIndex((s) =>
    categoryTypeId in s.categories ? s.categories[categoryTypeId as keyof typeof s.categories] : []
  );

  // const [selection, setSelection] = useState<ID[]>([]);

  const handleChange = (_ev: React.ChangeEvent<HTMLInputElement>) => {
    // const newValue = ev.target.value;
    // const categoryIds = newValue === '_all_' ? [] : [newValue];
    // setSelection(categoryIds);
    // trigger({ categoryIds });
  };

  const handleChangeCheckbox =
    (optionValue: ID) => (_ev: React.SyntheticEvent<Element, Event>, checked: boolean) => {
      if (checked) {
        selectionAdd(optionValue);
      } else {
        selectionRemove(optionValue);
      }
    };

  if (!categories?.length) return null;

  const options = [
    ...(includeAll
      ? [
          {
            value: '_all_',
            label: labelAll ?? t('common.all'),
          },
        ]
      : []),
    ...(categories?.map(({ id, title }) => ({ value: id, label: title ?? id })) ?? []),
  ];

  let fieldOutput: React.ReactNode;

  switch (variant) {
    case 'select':
      fieldOutput = (
        <DefaultSelectField onChange={handleChange} value={selection} options={options} />
      );
      break;

    default:
      fieldOutput = (
        <FormControl>
          <FormGroup sx={{ mb: 1 }}>
            {options.map((v) => (
              <FormControlLabel
                key={v.value}
                control={
                  <Checkbox
                    checked={selection.includes(v.value)}
                    onChange={handleChangeCheckbox(v.value)}
                    name={v.value}
                  />
                }
                label={v.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      );
      break;
  }

  return (
    <Flex {...props}>
      <Txt as="span" variant="bodyAlt" bold>
        {label ?? t('actions.filterByCategory')}
      </Txt>
      {fieldOutput}
    </Flex>
  );
};
