import Box from '@componentPrimitives/Box';
import Btn from '@componentPrimitives/Btn';
import CtaButton from '@components/CtaButton';
import { SearchResultCard, SearchResultCardCompact } from '@components/EntryCard';
import EntryIndex from '@components/EntryIndex';
import { useEntryIndex } from '@lib/features/entryIndexes';
import useResizeObserver from '@react-hook/resize-observer';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useTranslations } from 'next-intl';
import React from 'react';
import * as style from './SearchDialog.css';

export const SearchDialogResults = () => {
  const t = useTranslations('search');
  const query = useEntryIndex((s) => s.args.query);
  const results = useEntryIndex((s) => s.entries);

  const ref = React.useRef<HTMLDivElement>(null);

  const [posY, setPosY] = React.useState<number>();

  useResizeObserver(ref, () => {
    setPosY(ref.current?.offsetTop);
  });
  const hasQuery = !!query?.length;
  const viewAllUrl = `/search/${query}`;
  const showViewAll = hasQuery && !!results.length;

  return hasQuery ? (
    <Box
      ref={ref}
      style={assignInlineVars(style.searchDialogVars.results, {
        y: `${posY ?? 0}px`,
      })}
      className={style.resultsWrap}>
      <Box paper elevation className={style.resultsInner}>
        <EntryIndex.Grid CardGridProps={{ rowGutter: false, Component: SearchResultCardCompact }} />
        {showViewAll && (
          <Box cx={{ p: 'sm' }}>
            <CtaButton href={viewAllUrl} size="small" variant="filled">
              {t('viewAll')}
            </CtaButton>
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
};
