import Txt, { TxtProps } from '@primitive/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useCard } from './useCard';
import * as style from './Card.css';
import { isArray } from 'lodash';

export type CardMetaProps<D extends React.ElementType = 'h2'> = TxtProps<D>;

export const CardMeta = <D extends React.ElementType>({ children, ...props }: CardMetaProps<D>) => {
  const { meta } = useCard();
  const content = children ?? meta;

  const hasContent = !!content && (!isArray(content) || content.length);

  return hasContent ? (
    <Txt as="span" {...mergePropsClassName(props, style.meta)}>
      {content}
    </Txt>
  ) : null;
};
