import Box, { BoxProps } from '@primitive/Box';
import Txt from '@primitive/Txt';
import { useGlobal } from '@lib/store';
import Flex from '@componentPrimitives/Flex';
import Image from '@components/Image';
import * as style from './AcknowledgementOfCountry.css';

export const AcknowledgementOfCountry = (props: BoxProps) => {
  const acknowledgementOfCountry = useGlobal('acknowledgementOfCountry');
  if (!acknowledgementOfCountry) return null;

  const { content, images } = acknowledgementOfCountry;

  return (
    <Box {...props}>
      <Flex direction="row" spacing="sm" cx={{ mB: 'sm' }}>
        {images.map((image, i) => (
          <Image key={i} alt="" {...image} transform="squareSmFit" className={style.flag} />
        ))}
      </Flex>
      <Txt html variant="bodyAlt">
        {content}
      </Txt>
    </Box>
  );
};
