import type { LogoSvgProps } from './Logo';

export const LogoSvg_RTC = ({ mono, ...props }: LogoSvgProps) => (
  <svg
    {...props}
    width="335"
    height="53"
    viewBox="0 0 335 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M91.1934 51.3972V28.959H101.545V31.0033H93.6099V38.5673H101.545V40.6116H93.6099V51.3972H91.1934Z"
      fill="currentColor"
    />
    <path
      d="M120.511 40.1777C120.511 46.838 117.907 51.7726 112.019 51.7726C106.131 51.7726 103.527 46.838 103.527 40.1777C103.527 33.5175 106.131 28.5864 112.019 28.5864C117.907 28.5864 120.511 33.5147 120.511 40.1777ZM106.13 40.1777C106.13 46.4996 108.052 49.7233 112.018 49.7233C115.985 49.7233 117.907 46.4996 117.907 40.1777C117.907 33.8559 115.987 30.6322 112.019 30.6322C108.051 30.6322 106.131 33.8559 106.131 40.1777"
      fill="currentColor"
    />
    <path
      d="M122.865 28.959H129.652C134.61 28.959 136.687 31.9345 136.687 35.7158C136.687 39.249 134.92 42.2231 130.953 42.2859L137.246 51.3972H134.3L127.173 40.3635V40.238H129.683C132.565 40.238 134.084 38.5645 134.084 35.7447C134.084 32.5542 132.565 31.0033 129.311 31.0033H125.281V51.3972H122.865V28.959Z"
      fill="currentColor"
    />
    <path
      d="M152.275 36.118C151.993 33.1728 150.383 30.6322 147.222 30.6322C143.101 30.6322 141.273 33.8558 141.273 40.1777C141.273 46.4996 143.101 49.7233 147.285 49.7233C150.724 49.7233 152.832 48.2972 152.832 42.4089H145.27V40.3646H155.25V43.4663C155.25 48.5489 152.368 51.7726 147.068 51.7726C141.18 51.7691 138.67 46.8408 138.67 40.1806C138.67 33.5203 141.18 28.5864 147.068 28.5864C151.778 28.5864 154.54 31.6247 154.691 36.118H152.275Z"
      fill="currentColor"
    />
    <path
      d="M174.075 40.1777C174.075 46.838 171.471 51.7726 165.583 51.7726C159.695 51.7726 157.091 46.838 157.091 40.1777C157.091 33.5175 159.695 28.5864 165.583 28.5864C171.471 28.5864 174.075 33.5147 174.075 40.1777ZM159.694 40.1777C159.694 46.4996 161.616 49.7233 165.582 49.7233C169.549 49.7233 171.471 46.4996 171.471 40.1777C171.471 33.8559 169.551 30.6322 165.583 30.6322C161.615 30.6322 159.695 33.8559 159.695 40.1777"
      fill="currentColor"
    />
    <path
      d="M174.508 28.959H187.65V31.1901H182.287V51.3972H179.87V31.1901H174.508V28.959Z"
      fill="currentColor"
    />
    <path
      d="M186.228 28.959H199.369V31.1901H194.006V51.3972H191.589V31.1901H186.228V28.959Z"
      fill="currentColor"
    />
    <path
      d="M200.836 51.3972V28.959H212.115V31.0033H203.253V38.5673H212.115V40.6116H203.253V49.3487H212.115V51.393L200.836 51.3972Z"
      fill="currentColor"
    />
    <path
      d="M215.428 51.3972V28.959H218.031L227.422 45.9748H227.482V28.959H229.9V51.3972H227.607L217.907 33.5453H217.844V51.3972H215.428Z"
      fill="currentColor"
    />
    <path
      d="M238.487 29.3301H251.628V31.5612H246.265V51.7683H243.849V31.5612H238.487V29.3301Z"
      fill="currentColor"
    />
    <path
      d="M254.439 29.3301H261.227C266.185 29.3301 268.262 32.3056 268.262 36.0869C268.262 39.6201 266.495 42.5942 262.528 42.657L268.82 51.7683H265.875L258.747 40.7346V40.6091H261.258C264.14 40.6091 265.659 38.9363 265.659 36.1158C265.659 32.9253 264.139 31.3744 260.885 31.3744H256.856V51.7683H254.439V29.3301Z"
      fill="currentColor"
    />
    <path
      d="M290.544 29.3301H297.452C303.341 29.3301 306.254 34.1032 306.254 40.5492C306.254 46.9952 303.344 51.7683 297.452 51.7683H290.544V29.3301ZM292.96 49.7226H297.452C301.295 49.7226 303.65 46.7167 303.65 40.5492C303.65 34.3817 301.298 31.3744 297.452 31.3744H292.96V49.7226Z"
      fill="currentColor"
    />
    <path
      d="M308.219 51.7683V29.3301H319.498V31.3744H310.636V38.9384H319.498V40.9827H310.636V49.7198H319.498V51.7641L308.219 51.7683Z"
      fill="currentColor"
    />
    <path
      d="M323.439 44.9671C323.439 48.3762 324.524 50.0808 328.181 50.0808C330.939 50.0808 332.582 48.8415 332.582 45.8356C332.582 39.7604 321.454 42.7663 321.454 34.9887C321.454 30.9903 324.181 28.9453 328.054 28.9453C332.083 28.9453 334.501 31.4555 334.501 35.7007H332.083C332.083 32.599 330.657 30.9903 327.744 30.9903C325.482 30.9903 323.867 32.3226 323.867 34.7716C323.867 40.784 334.993 37.2509 334.993 45.7426C334.993 50.2055 331.771 52.1272 327.927 52.1272C323.186 52.1272 321.019 49.4928 321.019 44.9678L323.439 44.9671Z"
      fill="currentColor"
    />
    <path
      d="M286.951 51.7687L284.201 45.3692H274.685L271.909 51.7687H269.601L279.431 28.7524L289.371 51.7687H286.951ZM275.531 43.3552H283.332L279.473 34.1113L275.531 43.3552Z"
      fill="currentColor"
    />
    <path
      d="M123.953 23.017L121.204 16.6175H111.688L108.912 23.017H106.604L116.434 0.000671387L126.374 23.017H123.953ZM112.533 14.6035H120.335L116.475 5.36103L112.533 14.6035Z"
      fill="currentColor"
    />
    <path
      d="M91.0942 0.579346H97.8814C102.84 0.579346 104.917 3.5549 104.917 7.33619C104.917 10.8694 103.149 13.8435 99.1827 13.9062L105.475 23.0176H102.53L95.4021 11.9839V11.8584H97.9124C100.795 11.8584 102.313 10.1849 102.313 7.3651C102.313 4.17454 100.794 2.62367 97.5402 2.62367H93.5108V23.0176H91.0942V0.579346Z"
      fill="currentColor"
    />
    <path
      d="M127.629 0.579346H134.416C139.375 0.579346 141.451 3.5549 141.451 7.33619C141.451 10.8694 139.684 13.8435 135.717 13.9062L142.01 23.0176H139.064L131.937 11.9839V11.8584H134.447C137.33 11.8584 138.848 10.1849 138.848 7.3651C138.848 4.17454 137.329 2.62367 134.075 2.62367H130.045V23.0176H127.629V0.579346Z"
      fill="currentColor"
    />
    <path
      d="M144.312 23.0157V0.577454H155.592V2.62178H146.729V10.1858H155.592V12.2301H146.729V20.9671H155.592V23.0115L144.312 23.0157Z"
      fill="currentColor"
    />
    <path
      d="M182.418 0.579346H189.205C194.164 0.579346 196.24 3.5549 196.24 7.33619C196.24 10.8694 194.473 13.8435 190.506 13.9062L196.799 23.0176H193.854L186.726 11.9839V11.8584H189.236C192.119 11.8584 193.637 10.1849 193.637 7.3651C193.637 4.17454 192.118 2.62367 188.864 2.62367H184.835V23.0176H182.418V0.579346Z"
      fill="currentColor"
    />
    <path
      d="M198.193 0.579346H211.335V2.81048H205.972V23.0176H203.555V2.81048H198.193V0.579346Z"
      fill="currentColor"
    />
    <path
      d="M215.005 16.2145C215.005 19.6236 216.089 21.3281 219.747 21.3281C222.505 21.3281 224.148 20.0888 224.148 17.083C224.148 11.0078 213.019 14.0137 213.019 6.23608C213.019 2.23766 215.747 0.192627 219.62 0.192627C223.649 0.192627 226.067 2.70292 226.067 6.94806H223.649C223.649 3.84633 222.223 2.23766 219.31 2.23766C217.048 2.23766 215.433 3.56999 215.433 6.01895C215.433 12.0314 226.559 8.49823 226.559 16.9899C226.559 21.4529 223.337 23.3746 219.493 23.3746C214.751 23.3746 212.584 20.7402 212.584 16.2152L215.005 16.2145Z"
      fill="currentColor"
    />
    <path
      d="M178.262 23.017L175.512 16.6175H165.996L163.22 23.017H160.912L170.742 0.000671387L180.682 23.017H178.262ZM166.842 14.6035H174.643L170.784 5.36103L166.842 14.6035Z"
      fill="currentColor"
    />
    <path
      d="M251.655 23.1772L246.538 18.1792C244.72 21.6933 242.538 23.1772 239.721 23.1772C237.88 23.1971 236.105 22.4888 234.783 21.2065C233.461 19.9241 232.698 18.1716 232.662 16.3301C232.662 13.4518 234.328 11.2404 237.843 9.66561C235.995 7.87718 235.328 6.57516 235.328 4.9693C235.328 2.18197 237.843 0.000183105 240.722 0.000183105C242.121 0.00606365 243.464 0.556336 244.465 1.53455C245.466 2.51276 246.048 3.84216 246.086 5.24141C246.086 7.45281 244.361 9.3921 241.723 10.3311L245.962 14.5128L249.235 8.21062H251.749L247.598 16.1179L253.264 21.6313L251.655 23.1772ZM239.448 11.2715C236.541 12.3289 234.904 14.271 234.904 16.4514C234.936 17.6679 235.435 18.8253 236.299 19.683C237.162 20.5407 238.323 21.0327 239.54 21.0567C241.599 21.0567 243.145 19.9352 244.841 16.5726L239.448 11.2715ZM243.841 5.02993C243.832 4.6269 243.742 4.2298 243.576 3.86268C243.409 3.49555 243.17 3.16602 242.872 2.89405C242.575 2.62207 242.225 2.41329 241.845 2.28036C241.464 2.14742 241.06 2.09308 240.658 2.12064C239.88 2.10493 239.125 2.38875 238.55 2.9135C237.975 3.43825 237.623 4.16384 237.568 4.9404C237.568 6.06196 238.295 7.21313 240.113 8.75765C242.903 7.75734 243.841 6.6957 243.841 5.02993Z"
      fill="currentColor"
    />
    <path
      d="M83.1483 45.1108C83.7505 45.3907 84.263 45.8326 84.6283 46.3871C84.9937 46.9416 85.1977 47.5868 85.2173 48.2506C85.2011 48.7353 85.0894 49.212 84.8887 49.6535C84.688 50.095 84.4022 50.4926 84.0476 50.8235C83.693 51.1544 83.2767 51.4121 82.8223 51.5818C82.368 51.7515 81.8847 51.83 81.4 51.8126C80.9144 51.8345 80.4293 51.7596 79.9729 51.5923C79.5165 51.4249 79.098 51.1685 78.7416 50.8379C78.3853 50.5072 78.0982 50.109 77.8972 49.6664C77.6962 49.2238 77.5853 48.7456 77.5708 48.2597C77.581 47.6026 77.7756 46.9616 78.1326 46.4098C78.4896 45.858 78.9945 45.4176 79.5897 45.139V45.9031C79.1836 46.1516 78.847 46.4989 78.6114 46.9126C78.3757 47.3262 78.2486 47.7929 78.2419 48.2689C78.3015 49.0615 78.6636 49.8007 79.2532 50.3338C79.8427 50.8668 80.6147 51.1527 81.4092 51.1324C82.1999 51.1439 82.9652 50.8529 83.5485 50.319C84.1319 49.785 84.4893 49.0484 84.5476 48.2597C84.5296 47.7776 84.3923 47.3074 84.1481 46.8912C83.9039 46.475 83.5604 46.1259 83.1483 45.8749V45.1108Z"
      fill="currentColor"
    />
    <path
      d="M85.0864 44.3421H77.6909V40.6101H78.3606V43.6604H81.0182V40.7144H81.6879V43.6604H84.4196V40.6101H85.0893L85.0864 44.3421Z"
      fill="currentColor"
    />
    <path
      d="M85.0864 35.1025L78.5806 39.1446V39.1622H85.0864V39.8439H77.6909V38.9232L83.8274 35.1208V35.1025H77.6944V34.4208H85.0864V35.1025Z"
      fill="currentColor"
    />
    <path
      d="M85.0865 32.0902H78.3572V33.653H77.6875V29.8506H78.3572V31.4057H85.0865V32.0902Z"
      fill="currentColor"
    />
    <path
      d="M85.0864 23.6915H77.6909V19.9596H78.3606V23.0099H81.0182V20.0639H81.6879V23.0099H84.4196V19.9596H85.0893L85.0864 23.6915Z"
      fill="currentColor"
    />
    <path
      d="M85.0866 25.4168L81.741 27.7283V27.0656C81.741 25.8588 81.0692 25.1412 80.0597 25.1412C79.3597 25.1412 78.3573 25.5373 78.3573 27.1016V28.4008H85.0866V29.0811H77.6911V27.0283C77.6547 26.6974 77.6901 26.3625 77.7947 26.0465C77.8994 25.7305 78.0709 25.4408 78.2976 25.197C78.5243 24.9532 78.8009 24.7612 79.1085 24.6339C79.4161 24.5066 79.7475 24.4472 80.0802 24.4595C80.644 24.4416 81.193 24.6425 81.6121 25.0201C82.0312 25.3977 82.2881 25.9228 82.3289 26.4855L85.0866 24.561V25.4168Z"
      fill="currentColor"
    />
    <path
      d="M85.0864 16.7191H77.6909V13.2776H78.3606V16.0395H81.0359V13.3791H81.7055V16.0395H85.0864V16.7191Z"
      fill="currentColor"
    />
    <path
      d="M85.2167 9.11169C85.1787 10.0869 84.7616 11.0087 84.0542 11.681C83.3468 12.3533 82.4049 12.7229 81.429 12.7111C80.4388 12.7467 79.475 12.3875 78.7496 11.7125C78.0242 11.0375 77.5967 10.1019 77.561 9.11169C77.5942 8.12689 78.015 7.19506 78.732 6.51907C79.4489 5.84308 80.4038 5.47767 81.3889 5.5024C82.373 5.48099 83.326 5.84751 84.0422 6.52282C84.7584 7.19812 85.1803 8.12801 85.2167 9.11169ZM78.2307 9.12085C78.2779 9.92749 78.6391 10.6836 79.2369 11.2272C79.8347 11.7709 80.6216 12.0588 81.429 12.0294C82.2253 12.0411 82.9951 11.7436 83.5765 11.1994C84.158 10.6553 84.5058 9.90697 84.547 9.11169C84.5123 8.30799 84.1626 7.55025 83.5736 7.00238C82.9846 6.4545 82.2035 6.16054 81.3994 6.18408C80.5905 6.1573 79.8037 6.45068 79.2098 7.00052C78.6158 7.55036 78.2628 8.31228 78.2272 9.12085"
      fill="currentColor"
    />
    <path
      d="M85.0866 1.27066L81.741 3.58216V2.91952C81.741 1.71266 81.0692 0.995033 80.0597 0.995033C79.3597 0.995033 78.3573 1.39121 78.3573 2.95547V4.25467H85.0866V4.93494H77.6911V2.88216C77.6547 2.55126 77.6901 2.21641 77.7947 1.9004C77.8994 1.5844 78.0709 1.29465 78.2976 1.05089C78.5243 0.807123 78.8009 0.615069 79.1085 0.4878C79.4161 0.36053 79.7475 0.301032 80.0802 0.313356C80.644 0.295497 81.193 0.496391 81.6121 0.874003C82.0312 1.25162 82.2881 1.77669 82.3289 2.33935L85.0866 0.414868V1.27066Z"
      fill="currentColor"
    />
    {/* icon */}
    <path
      d="M51.982 26.0652C51.9822 22.6519 51.31 19.272 50.0039 16.1185C48.6978 12.965 46.7833 10.0996 44.3698 7.68603C41.9563 5.27244 39.091 3.35789 35.9375 2.0517C32.784 0.745513 29.4041 0.0732717 25.9909 0.0733643C22.5775 0.0731791 19.1976 0.745351 16.044 2.0515C12.8904 3.35764 10.025 5.27218 7.61144 7.68578C5.19784 10.0994 3.2833 12.9648 1.97716 16.1183C0.67101 19.2719 -0.00116169 22.6518 -0.000976524 26.0652C-0.000602623 32.9585 2.73796 39.5693 7.61231 44.4435C12.4867 49.3178 19.0976 52.0561 25.9909 52.0563C32.884 52.0559 39.4947 49.3175 44.3689 44.4433C49.2431 39.5691 51.9816 32.9584 51.982 26.0652ZM34.9852 29.8472C34.9554 29.8835 34.9182 29.9132 34.8761 29.9342C34.8341 29.9552 34.788 29.9671 34.7411 29.9691C34.6941 29.9711 34.6472 29.9631 34.6035 29.9458C34.5598 29.9284 34.5203 29.902 34.4875 29.8683L27.4191 22.6758C27.5413 22.3375 27.5922 21.9774 27.5685 21.6184C27.5248 21.0015 27.2628 20.4203 26.8294 19.9792C26.3959 19.538 25.8195 19.2657 25.2034 19.2111C24.8458 19.1813 24.4858 19.2257 24.146 19.3415L15.3167 10.357L25.4452 10.4451C25.4452 10.4451 35.0677 10.0391 37.5737 19.3415C38.8779 24.1844 36.4775 28.032 34.9852 29.8451M42.1488 40.2465L30.5293 40.1478L21.2008 30.7248L21.1197 40.0639L12.5561 39.9934L12.676 26.1639L12.757 16.8248L12.8127 10.5135L42.1488 40.2465Z"
      fill={mono ? 'currentColor' : '#AF471B'}
    />
  </svg>
);
