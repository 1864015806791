import Flex, { FlexProps } from '@componentPrimitives/Flex';
import SocialLinks from '@components/SocialLinks';
import { useGlobal } from '@lib/store';
import Txt from '@primitive/Txt';

export const FooterSocialLinks = (props: FlexProps) => {
  const social = useGlobal('social');

  if (!social) return null;

  const { heading, socialLinks } = social;
  // console.log(socialLinks);

  return socialLinks ? (
    <Flex spacing="xs" {...props}>
      <Txt as="h5" variant="bodyAlt" bold>
        {heading}
      </Txt>
      <SocialLinks links={socialLinks} />
    </Flex>
  ) : null;
};
