import { createField } from '@formie/parse/formieParser';
import { useFormField } from '@formie/useForm';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import { DefaultTextField, DefaultTextFieldProps } from './DefaultTextField';
import { FieldErrors } from './FieldErrors';

gql`
  fragment fieldSingleLineText on Field_SingleLineText {
    ...fieldBase
  }

  fragment fieldMultiLineText on Field_MultiLineText {
    ...fieldBase
  }

  fragment fieldEmail on Field_Email {
    ...fieldBase
  }

  fragment fieldNumber on Field_Number {
    ...fieldBase
  }

  fragment fieldPhone on Field_Phone {
    ...fieldBase
  }

  fragment fieldDate on Field_Date {
    # This is different due to a bug in the date field "defaultValue"
    ...fieldCommon
  }
`;

export const Field_Text = createField(({ field, ..._props }) => {
  const {
    // defaultValue: _defaultValue,

    handle,
    name,
    placeholder,
    required,
    instructions,
  } = field;

  // const _defaultValue = maybeGet(field, 'defaultValue');

  const { value, setValue, error, errors } = useFormField(handle);

  // Modify loaded value here
  switch (field.__typename) {
    case 'Field_Date':
      break;
  }

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const valueRaw = ev.target.value;
    let newValue = valueRaw;

    // Modify value before saving
    switch (field.__typename) {
      case 'Field_Date':
        // console.log('toISOString', dayjs(valueRaw).toISOString());
        if (valueRaw) newValue = `${valueRaw}T00:00:00.000Z`;
        break;
    }

    setValue(newValue);
  };

  const fieldProps: DefaultTextFieldProps = {
    value,
    error,
    name: handle,
    helperText: instructions,
    label: name,
    placeholder,
    required,
    onChange: handleChange,
  };

  switch (field.__typename) {
    case 'Field_MultiLineText':
      fieldProps.multiline = true;
      fieldProps.minRows = 3;
      break;

    case 'Field_Email':
      fieldProps.type = 'email';
      break;

    case 'Field_Number':
      fieldProps.type = 'number';
      break;

    case 'Field_Phone':
      fieldProps.type = 'tel';
      break;

    case 'Field_Date':
      fieldProps.type = 'date';
      fieldProps.InputLabelProps = { ...fieldProps.InputLabelProps, shrink: true };
      if (value && typeof value === 'string') fieldProps.value = dayjs(value).format('YYYY-MM-DD');
      break;
  }

  return (
    <>
      <DefaultTextField {...fieldProps} />
      <FieldErrors errors={errors} />
    </>
  );
});
