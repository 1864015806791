/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Props } from './PolymorphicTypes';

/**
 * Remove properties `K` from `T`.
 * Distributive for union types.
 */
export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export type OverrideProps<T, U> = DistributiveOmit<T, keyof U> & U;

export type DefaultPropsMap = { [P in keyof React.ReactHTML]?: React.ComponentPropsWithoutRef<P> };

export const createDefaultsMap = <Map extends DefaultPropsMap>(map: Map) => {
  return map;
};

export const useDefaultsMap = <C extends React.ElementType>(map: DefaultPropsMap, component: C) => {
  const defaultProps =
    map && typeof component === 'string' && component in map
      ? map[component as keyof DefaultPropsMap]
      : null;

  return defaultProps ?? undefined;
};

export const usePolymorphicProps = <C extends React.ElementType, P extends Props<C>>(
  props: P,
  as: C,
  map?: DefaultPropsMap
) => {
  const { as: Component = as, ...rest } = props;

  const defaultProps =
    map && typeof Component === 'string' && Component in map
      ? map[Component as keyof DefaultPropsMap]
      : null;

  return {
    Component,
    props: {
      ...defaultProps,
      ...rest,
    },
  } as {
    Component: C;
    props: React.ComponentPropsWithoutRef<C>;
  };
};

export const combineComponentChildren = <
  Root extends (props?: any) => React.ReactElement | null,
  Children extends Record<string, (props?: any) => React.ReactElement | null>
>(
  root: Root,
  childComponents: Children
) => {
  const combined = root as Root & Children;

  (Object.keys(childComponents) as (keyof Children)[]).forEach(
    (name) => (combined[name] = childComponents[name] as any)
  );

  return combined;
};
