import { Field } from '@formie/parse/formieParser';
import { Box, Collapse } from '@primitives';
import { Field_Address } from './Fields/Field_Address';
import { Field_Agree } from './Fields/Field_Agree';
import { Field_Checkboxes } from './Fields/Field_Checkboxes';
import { Field_Dropdown } from './Fields/Field_Dropdown';
import { Field_Name } from './Fields/Field_Name';
import { Field_Radio } from './Fields/Field_Radio';
import { Field_Text } from './Fields/Field_Text';
import { useForm } from './useForm';

export type FormieFieldsProps = {
  fields: Field[];
};

export const FormieField = ({ field }: { field: Field }) => {
  // Calculate any conditions
  // const conditionalShow = useFormFieldCondition(field.conditions, field.enableConditions);

  const enabled = useForm((s) => s.fieldStatuses[field.handle]);

  // if (!conditionalShow) return <></>;
  let inner: JSX.Element;

  switch (field.__typename) {
    case 'Field_Address':
      inner = <Field_Address field={field} />;
      break;
    case 'Field_Name':
      inner = <Field_Name field={field} />;
      break;
    case 'Field_Agree':
      inner = <Field_Agree field={field} />;
      break;
    case 'Field_Dropdown':
      inner = <Field_Dropdown field={field} />;
      break;
    case 'Field_Checkboxes':
      inner = <Field_Checkboxes field={field} />;
      break;
    case 'Field_Radio':
      inner = <Field_Radio field={field} />;
      break;
    default:
      inner = <Field_Text field={field} />;
  }

  return (
    <Collapse in={enabled} /* unmountOnExit */>
      <Box mb={3} textAlign="left">
        {inner}
      </Box>
    </Collapse>
  );
};

export const FormieFields = ({ fields }: FormieFieldsProps) => {
  return (
    <>
      {fields.map((v) => (
        <FormieField key={v.id} field={v} />
      ))}
    </>
  );
};
