import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { FieldBaseFragmentDoc, FieldCommonFragmentDoc, FieldAttributeFragmentDoc, FieldOptionFragmentDoc } from '../../gql/_generated/fieldCommon.generated';
export type FieldRadioFragment = { __typename: 'Field_Radio', layout?: string | null, defaultValue?: string | null, id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, options?: Array<{ __typename: 'FieldOption', value?: string | null, label?: string | null, isDefault?: boolean | null } | null> | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export const FieldRadioFragmentDoc = gql`
    fragment fieldRadio on Field_Radio {
  ...fieldBase
  layout
  options {
    ...fieldOption
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;