// import { IS_DEV } from '@lib/constants';
import MuiThemeProvider from '@mui/system/ThemeProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license-pro';
import { theme, themeDark } from '@theme/mui/muiTheme';
import { PropsWithChildren } from 'react';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUIX_LICENSE_KEY ?? '');

export type ThemeProviderProps = PropsWithChildren<{
  mode?: 'light' | 'dark';
  // cssReset?: boolean;
}>;
const defaultMode = 'light'; // darkMode ? 'dark' : 'light';

const ThemeProvider = ({ children, mode = defaultMode }: ThemeProviderProps) => {
  const darkMode = mode === 'dark';
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiThemeProvider theme={darkMode ? themeDark : theme}>
        {/* {cssReset && <CssBaseline />} */}
        {children}
      </MuiThemeProvider>
    </LocalizationProvider>
  );
};

export default ThemeProvider;
