import Txt, { TxtProps } from '@primitive/Txt';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useCard } from './useCard';
import * as style from './Card.css';

export type CardTitleProps<D extends React.ElementType = 'h2'> = TxtProps<D>;

export const CardTitle = <D extends React.ElementType>({
  children,
  ...props
}: CardTitleProps<D>) => {
  const { title, titleComponent } = useCard();

  return (
    <Txt as={titleComponent || 'h2'} variant="h4" {...mergePropsClassName(props, style.title)}>
      {children || title}
    </Txt>
  );
};
