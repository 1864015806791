import { toNumber } from '@liquorice/allsorts-craftcms-nextjs';
import { Field } from './parse/formieParser';
import {
  FieldStatuses,
  FieldValue,
  FormValues,
  isFieldValueComplex,
  isFieldValueMulti,
  isFieldValueSingle,
} from './useForm';

type FieldConditionCompare = '=' | '!=' | '>' | '<' | 'contains' | 'startsWith' | 'endsWith';

type FieldCondition = {
  id: string;
  field: string;
  condition: FieldConditionCompare;
  value: string;
};

type FieldConditionsRule = 'all' | 'any';

type FieldConditions = {
  showRule: 'show' | 'hide';
  conditionRule: FieldConditionsRule;
  conditions: [FieldCondition, ...FieldCondition[]];
};

export const isFieldConditions = (x: unknown): x is FieldConditions => {
  if (!x || typeof x !== 'object') return false;

  if (!Object.prototype.hasOwnProperty.call(x, 'showRule')) return false;
  if (!Object.prototype.hasOwnProperty.call(x, 'conditionRule')) return false;
  if (!Object.prototype.hasOwnProperty.call(x, 'conditions')) return false;

  return true;
};

/**
 * Extract the field name, then current value from those provided
 * Field name comes formatted as `{FIELD_NAME}` or `{FIELD_NAME.SUBFIELD_NAME}`
 */
const extractFieldValue = (
  fieldIdentifierRaw: string,
  fieldValues: FormValues
): FieldValue<'single' | 'multi'> => {
  const fieldNameRe = /{(.*)}/g;

  const fieldIdentifier: string = (fieldNameRe.exec(fieldIdentifierRaw) ?? [])[1] ?? '';

  const fieldIdentifierArr = fieldIdentifier.split('.');

  let currentObj: FormValues | FieldValue<'complex'> | null = fieldValues;

  const fieldValue = fieldIdentifierArr.reduce((result, nodeName, i) => {
    const thisFieldValue = currentObj ? currentObj[nodeName] : null;

    // Nothing to do
    if (!thisFieldValue) return null;

    // Set the new queried object, and bail
    if (isFieldValueComplex(thisFieldValue)) {
      currentObj = thisFieldValue;
      return null;
    }

    const isLast = i === fieldIdentifierArr.length - 1;

    // Set the new queried object, and bail
    if (isLast) return thisFieldValue;

    return result;
  }, null as FieldValue<'multi' | 'single'> | null);

  return fieldValue ?? '';
};

export const evaluateConditionsMet = (
  conditions: FieldCondition[],
  rule: FieldConditionsRule,
  fieldValues: FormValues
) => {
  return conditions.reduce((met, conditionSetting, compareIndex) => {
    const { field: fieldNameRaw, condition: compare, value: compareValue } = conditionSetting;

    const fieldValue = extractFieldValue(fieldNameRaw, fieldValues);

    const fieldValueSimple: string | string[] =
      isFieldValueSingle(fieldValue) || isFieldValueMulti(fieldValue) ? fieldValue : '';

    const fieldValueSingle: string = isFieldValueSingle(fieldValue)
      ? fieldValue
      : isFieldValueMulti(fieldValue) && fieldValue.length === 1
      ? fieldValue[0]
      : '';

    // if 'all' required, and already failed:
    if (rule === 'all' && !met && compareIndex) return met;
    // if 'any' required, and already passed:
    if (rule === 'any' && met) return met;

    switch (compare) {
      case '=':
        met = fieldValueSingle === compareValue;
        break;
      case '!=':
        met = fieldValueSingle !== compareValue;
        break;
      case '>':
        met = toNumber(fieldValueSingle) > toNumber(compareValue);
        break;
      case '<':
        met = toNumber(fieldValueSingle) < toNumber(compareValue);
        break;
      case 'contains':
        met = !!compareValue && fieldValueSimple.includes(compareValue);
        break;
      case 'startsWith':
        met = !!compareValue && fieldValueSingle.startsWith(compareValue);
        break;
      case 'endsWith':
        met = !!compareValue && fieldValueSingle.endsWith(compareValue);
        break;
    }

    return met;
  }, false);
};

export const parseFormieFieldCondition = (data?: Maybe<string>): FieldConditions | null => {
  const maybeConditions = !!data && JSON.parse(data);

  if (isFieldConditions(maybeConditions)) return maybeConditions;

  return null;
};

export const evaluateFieldConditions = (
  fieldConditions: FieldConditions,
  fieldValues: FormValues
) => {
  let show = true;

  const { showRule, conditionRule, conditions } = fieldConditions;

  const conditionsMet = evaluateConditionsMet(conditions, conditionRule, fieldValues);

  if (showRule === 'show' && !conditionsMet) show = false;
  if (showRule === 'hide' && conditionsMet) show = false;

  return show;
};

export const applyFieldConditions = (fields: Field[], values: FormValues): FieldStatuses => {
  const statuses = fields.reduce((results, { handle, conditions, enableConditions }) => {
    let enabled = true;

    if (enableConditions && isFieldConditions(conditions)) {
      enabled = evaluateFieldConditions(conditions, values);
    }

    return { ...results, [handle]: enabled };
  }, {} as FieldStatuses);

  return statuses;
};
