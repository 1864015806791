/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import * as Typed from '@liquorice/allsorts-craftcms-nextjs/lib/sanitiser/sanitiserTypes';
import { EntriesFragment } from 'gql/_generated/entries.generated';
import { sanitiseAnything, SanitisedElement } from '@lib/parse/sanitiseElements';

/**
 * @example type Entry {
 *  __typename: 'page_default_Entry'
 *  sectionId: 'page',
 *  typeAlias: 'page',
 * }
 * @example type Entry {
 *  __typename: 'page_overview_Entry'
 *  sectionId: 'page',
 *  typeAlias: 'pageOverview',
 * }
 */

/**
 * Union of top level "Entries" defined with a '__typename'
 */
type RawEntries = EntriesFragment;

/**
 * __typename of top level Entry
 */
type EntryTypename = Typed.Typename<RawEntries>;

/**
 * Inferred 'sectionId' from {@link EntryType}
 * Matches 'section' argument in Entry queries
 */
export type EntrySectionId<T extends EntryTypename = EntryTypename> =
  T extends `${infer S}_${string}_Entry` ? S : never;

type EntrySubSection<T extends EntrySectionId> =
  EntrySectionIdToTypename<T> extends `${T}_${infer U}_Entry` ? U : never;

type EntrySectionIdToTypename<
  S extends EntrySectionId,
  U extends EntrySubSection<S> | null = null
> = Extract<EntryTypename, `${S}_${U extends null ? string : U}_Entry`>;

// ----------------------------------------------------------------------------------------------------
// ---- Extracted sanitised types ----

export type SanitisedEntry<T extends EntryTypename = EntryTypename> = SanitisedElement<T>;

// ----------------------------------------------------------------------------------------------------

/**
 * Sanitise a single {@link EntriesFragment} through the {@link sanitiseAnything}`.one()` function
 * Limit the valid return Type by providing a single or array of {@link EntryTypename}
 */
export const sanitiseEntry = <
  T extends EntriesFragment,
  Name extends EntryTypename = Typed.Typename<T>
>(
  maybeEntry?: T | null,
  typeNames?: MaybeArrayOf<Name>
) => (maybeEntry ? (sanitiseAnything.one(maybeEntry, typeNames) as SanitisedEntry<Name>) : null);

/**
 * Augments the result of {@link sanitiseEntry}
 */
export const parseSanitisedEntry = <T extends EntryTypename>(
  sanitisedEntry: SanitisedEntry<T> | null
) => {
  if (!sanitisedEntry) return null;
  // const typename = sanitisedEntry.__typename as T;
  // const [sectionId, subsectionType] = typename.split('_');

  // const entryMeta = {
  //   _typeAlias: [sectionId, 'default'].includes(subsectionType) ? sectionId : `${sectionId}${subsectionType.}` ,
  //   _sectionId: sectionId,
  // }

  return { ...(sanitisedEntry as SanitisedEntry<T>) };
};

/**
 * Sanitise and parse a single  {@link EntriesFragment}
 */
export const parseEntry = <
  T extends EntriesFragment,
  Name extends EntryTypename = Typed.Typename<T>
>(
  maybeEntry?: T | null,
  typeNames?: MaybeArrayOf<Name>
) => {
  const typeNamesArr = makeNonNullableArray(typeNames);
  const sanitisedEntry = sanitiseEntry(maybeEntry, typeNamesArr.length ? typeNamesArr : undefined);
  return sanitisedEntry ? parseSanitisedEntry<Name>(sanitisedEntry) : null;
};

/**
 * Sanitise and parse multiple {@link EntriesFragment}
 */
export const parseEntries = <
  T extends EntriesFragment,
  Name extends EntryTypename = Typed.Typename<T>
>(
  maybeEntries: MaybeArrayOf<T>,
  typeNames?: MaybeArrayOf<Name>
) => {
  const rawEntriesArr = makeNonNullableArray(maybeEntries);
  const parsedEntries = rawEntriesArr.map((e) => parseEntry(e, typeNames));
  return makeNonNullableArray(parsedEntries);
};

export type Entry<
  T extends EntrySectionId = EntrySectionId,
  U extends EntrySubSection<T> | null = null
> = Exact<Typed.ExtractByTypename<ReturnType<typeof parseEntry>, EntrySectionIdToTypename<T, U>>>;

// ----------------------------------------------------------------------------------------------------
// ---- Type guards ----

interface IsEntryTypeGuard {
  (x: any): x is Entry;
  <T extends EntrySectionId>(x: any, sectionId: T): x is Entry<T>;
  <T extends EntrySectionId, U extends EntrySubSection<T>>(
    x: any,
    sectionId: T,
    subSection: U
  ): x is Entry<T, U>;
}

export const isEntry: IsEntryTypeGuard = <T extends EntrySectionId, U extends EntrySubSection<T>>(
  x: any,
  sectionId?: T,
  subSection?: U | null
): x is Entry<T, U> => {
  if (!x || typeof x !== 'object' || typeof x.__typename !== 'string') return false;

  const [theSection, theSubSection, interfaceType] = `${x.__typename}`.split('_');

  if (interfaceType !== 'Entry') return false;
  if (sectionId && sectionId !== theSection) return false;
  if (subSection && subSection !== theSubSection) return false;

  return true;
};
