/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldValue, FormValues, isFieldValueMulti, isFieldValueSingle } from '@formie/useForm';
import { isArray, keys, makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';

const formatMutationString = (str: string) => `"${str}"`;

const parseMutationPartial = (value: FieldValue | FormValues | string[], key?: string): string => {
  let valueString = '';

  // ----------------------------------------------------------------------------------------------
  // Format an array if strings simple string

  if (isArray(value)) {
    const cleanArr = makeNonNullableArray(value);
    const innerStr = cleanArr.map((v) => formatMutationString(v)).join(', ');

    valueString = `[${innerStr}]`;
  }
  // ----------------------------------------------------------------------------------------------
  // ---- Format a simple string ----
  else if (isFieldValueSingle(value)) {
    valueString = formatMutationString(value);
  }
  // ----------------------------------------------------------------------------------------------
  // ---- Format a complex field ----
  else if (isFieldValueMulti(value)) {
    return parseMutationPartial(keys(value) as any, key);
  } else {
    const valueStringArr = keys(value).reduce((result, item) => {
      result.push(parseMutationPartial(value[item] as any, item));
      return result;
    }, [] as string[]);
    const strInner = valueStringArr.join(', ');
    valueString = key ? `{${strInner}}` : strInner;
  }

  return key ? `${key}: ${valueString}` : valueString;
};

export type FormSubmissionProps = {
  mutationName: string;
  formValues: FormValues;
};

export const parseSubmitFormQuery = ({ mutationName, formValues }: FormSubmissionProps) => {
  const argsStr = parseMutationPartial(formValues);
  const str = `
  mutation submitForm {
    ${mutationName}(${argsStr}) {
      uid
    }
  }`;

  return str;
};
