import Card from '@components/Card';
import { fmtCategoryNames } from '@lib/utils/format';
import { EntryCardProps } from './EntryCard';

export type LearningCardProps = EntryCardProps<'learning'>;

export const LearningCard = ({ item, ...props }: LearningCardProps) => {
  const { uri: href, title, entrySummary: description, entryImage: image, learningLevel } = item;

  // const padding
  const learningLevelNames = fmtCategoryNames(learningLevel);
  return (
    <Card
      {...props}
      item={{
        href,
        title,
        meta: learningLevelNames,
        description,
        image,
      }}>
      <Card.Image ratio="landscape" transform="landscapeSmCrop" />
      <Card.Body cx={{ pX: 'xs', pT: 'md' }}>
        <Card.Title variant="h6" />
        <Card.Meta cx={{ mT: '3xs' }} />
        <Card.Description maxChars={100} cx={{ mY: 'xs' }} />
      </Card.Body>
      <Card.Foot cx={{ pX: 'xs', pB: 'md' }}>
        <Card.Cta variant="outlined" size="small" endIcon="arrowRight" />
      </Card.Foot>
    </Card>
  );
};
