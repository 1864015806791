import Icon from '@components/Icon';
import Link from '@components/Link';
import { NavMenuItem } from '@lib/features/navigation/navigation';
import { TxtProps } from '@primitive/Txt';
import * as style from './MegaMenu.css';

export type MegaMenuSubMenuItemProps = {
  item: NavMenuItem;
  TxtProps?: TxtProps;
  size?: 'normal' | 'large';
};

export const MegaMenuSubMenuItem = ({ item, size, TxtProps }: MegaMenuSubMenuItemProps) => {
  const { title, href, newWindow } = item;

  return (
    <Link
      className={style.subMenuItemButton({ size })}
      href={href ?? ''}
      underline="none"
      target={newWindow ? '_blank' : '_self'}>
      {title}
      <Icon className={style.subMenuItemButtonIcon} name="arrowRight" fontSize="small" />
    </Link>
  );
  // <Txt /* variant="h6" */ as="span" {...TxtProps}>
  // </Txt>
};
