import Btn, { BtnProps } from '@componentPrimitives/Btn';
import Flex, { FlexProps } from '@componentPrimitives/Flex';
import {
  selectActiveCategories,
  useEntryIndex,
  useEntryIndexCategorySelection,
} from '@lib/features/entryIndexes';
import { useTranslations } from 'next-intl';

export type EntryIndexCategoryChipsProps = FlexProps & {
  BtnProps?: Partial<BtnProps<'button'>>;
};

export const EntryIndexCategoryChips = ({ BtnProps, ...props }: EntryIndexCategoryChipsProps) => {
  const t = useTranslations('common');
  const { list } = useEntryIndex(selectActiveCategories);
  const { selectionRemove, clearAll } = useEntryIndexCategorySelection();

  return list.length ? (
    <Flex direction="row" spacing="3xs" alignItems="center" wrap {...props}>
      {list.map((cat) => (
        <Btn
          key={cat.id}
          onClick={() => selectionRemove(cat.id)}
          endIcon="close"
          size="small"
          {...BtnProps}>
          {cat.title}
        </Btn>
      ))}
      <Btn onClick={clearAll} variant="nav" endIcon="close" size="small" cx={{ mL: 'xs' }}>
        {t('clearAll')}
      </Btn>
    </Flex>
  ) : null;
};
