import Btn, { BtnBaseProps, BtnProps } from '@componentPrimitives/Btn';
import classNames from 'classnames';
import React from 'react';
import * as style from './MegaMenu.css';
import { useMegaMenuItem } from './useMegaMenu';

export type MegaMenuTopLevelButtonProps = React.ComponentPropsWithoutRef<'span'> &
  BtnBaseProps & {
    itemId: ID;
  };

export const MegaMenuTopLevelButton = ({
  itemId,
  className,
  ...props
}: MegaMenuTopLevelButtonProps) => {
  const { item, active, toggleActive } = useMegaMenuItem(itemId);

  const { title, href, newWindow } = item;

  const handleClick = () => {
    toggleActive();
  };

  const hasChildren = !!item.subItems?.length;

  return (
    <Btn
      {...{
        variant: 'nav',
        underline: active ? 'always' : 'hover',
        className: classNames(className, style.topLevelButton({ active })),
        ...props,
      }}
      {...(!hasChildren && !!href
        ? {
            href,
            target: newWindow ? '_blank' : '_self',
          }
        : {
            onClick: handleClick,
          })}>
      {title}
    </Btn>
  );
};
