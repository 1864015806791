import { createRef } from 'react';

const refs = {
  layout: createRef<HTMLDivElement>(),
};

export type AppRefs = typeof refs;
export type AppRefId = keyof AppRefs;

export const useAppRef = (id: AppRefId) => {
  return refs[id];
};

export const appRefs: AppRefs = refs;
