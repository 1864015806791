// import NavBarDrawer from '@components/NavBarDrawer';
import Btn from '@componentPrimitives/Btn';
import { useTranslations } from 'next-intl';
import { useMegaMenu } from './useMegaMenu';

export const MegaMenuHamburger = () => {
  const t = useTranslations('common');
  const { open, toggleOpen } = useMegaMenu(({ open, toggleOpen }) => ({ open, toggleOpen }));

  const handleClick = () => {
    toggleOpen();
  };

  return (
    <Btn onClick={handleClick} variant="nav" underline="none" endIcon={open ? 'close' : 'menu'}>
      {t(open ? 'close' : 'menu')}
    </Btn>
  );
};
