import Btn from '@componentPrimitives/Btn';
import { createToggleStore } from '@lib/utils/createToggleStore';
import { useTranslations } from 'next-intl';

export const useSearchDialogToggle = createToggleStore();

export const SearchDialogToggleButton = () => {
  const t = useTranslations('common');
  const { value: open, toggle: toggleOpen } = useSearchDialogToggle();

  const handleClick = () => {
    toggleOpen();
  };

  return (
    <Btn onClick={handleClick} variant="nav" underline="none" endIcon={open ? 'close' : 'search'}>
      {t(open ? 'close' : 'search')}
    </Btn>
  );
};
