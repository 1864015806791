import Head from '@component/Head';

import ThemeProvider from '@component/ThemeProvider';

import Layout from '@layouts/Layout';
import { getDefaultMessages } from '@lib/l10n';
import { AppContext, AppContextProvider, useAppRef } from '@lib/store';
import { NextIntlProvider } from 'next-intl';
import { PropsWithChildren } from 'react';

export type AppRootProps = PropsWithChildren<{
  appContext?: AppContext;
}>;

const AppRoot = ({ children, appContext = {}, ...props }: AppRootProps) => {
  const layoutRef = useAppRef('layout');

  return (
    <AppContextProvider value={appContext}>
      <NextIntlProvider messages={getDefaultMessages()}>
        <Head />
        <ThemeProvider>
          <Layout ref={layoutRef} {...props}>
            {children}
          </Layout>
        </ThemeProvider>
      </NextIntlProvider>
    </AppContextProvider>
  );
};

export default AppRoot;
