import Box from '@primitive/Box';
import { useAppRef } from '@lib/store';
import { Drawer, DrawerProps } from '@primitives';
import { useBreakpointMin } from '@theme';
import * as styles from './NavBarDrawer.css';

export type NavBarDrawerProps = DrawerProps;

const NavBarDrawer = ({ children, PaperProps = {}, ...props }: NavBarDrawerProps) => {
  const BREAKPOINT_NAV = 'lg';

  const isDesktop = useBreakpointMin(BREAKPOINT_NAV);

  const layoutRef = useAppRef('layout');

  return (
    <Drawer
      {...{
        ModalProps: {
          disableScrollLock: false,
        },
        elevation: 0,
        anchor: isDesktop ? 'top' : 'left',
        container: () => layoutRef?.current ?? null,
        className: styles.root(),
        PaperProps: {
          ...PaperProps,
          className: [styles.paper, PaperProps.className].join(' '),
        },
        ...props,
      }}>
      <Box className={styles.inner}>{children}</Box>
    </Drawer>
  );
};

export default NavBarDrawer;
