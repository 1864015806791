import Card from '@components/Card';
import { isEntry } from '@lib/features/entries';
import { EntryCardProps } from './EntryCard';
import { ArticleCard } from './EntryCard.Article';
import { ProfileCard } from './EntryCard.Profile';
import { EventCard } from './EntryCard.Event';
import { LearningCard } from './EntryCard.Learning';

export const EntryCardDefault = (props: EntryCardProps) => {
  if (!props.item) return null;

  if (isEntry(props.item, 'article'))
    return <ArticleCard {...(props as EntryCardProps<'article'>)} />;
  if (isEntry(props.item, 'event')) return <EventCard {...(props as EntryCardProps<'event'>)} />;
  if (isEntry(props.item, 'learning'))
    return <LearningCard {...(props as EntryCardProps<'learning'>)} />;
  if (isEntry(props.item, 'profile'))
    return <ProfileCard {...(props as EntryCardProps<'profile'>)} />;

  const { uri: href, title, entrySummary: description, entryImage: image } = props.item;

  return (
    <Card
      {...props}
      padding="lg"
      item={{
        title,
        description,
        href,
        image,
      }}
    />
  );
};
