import Box from '@componentPrimitives/Box';
import Btn, { BtnProps } from '@componentPrimitives/Btn';
import Flex from '@componentPrimitives/Flex';
import Txt from '@componentPrimitives/Txt';
import { useIsDesktop } from '@components/NavBar';
import { Drawer, Popover, PopoverProps } from '@mui/material';
import { useTranslations } from 'next-intl';
import React, { useRef } from 'react';

export type EntryIndexFiltersPopoverProps = {
  children: React.ReactNode;
  buttonLabel?: React.ReactNode;
  BtnProps?: BtnProps<'button'>;
  PopoverProps?: Partial<PopoverProps>;
};

export const EntryIndexFiltersPopover = ({
  children,
  buttonLabel,
  BtnProps,
  PopoverProps,
}: EntryIndexFiltersPopoverProps) => {
  const isDesktop = useIsDesktop();
  const t = useTranslations();
  const filterBtnRef = useRef<HTMLButtonElement>(null);
  const [filtersOpen, setFiltersOpen] = React.useState(false);

  const handleFilterClick = () => setFiltersOpen(!filtersOpen);
  const handleClose = () => setFiltersOpen(false);

  const btnProps: BtnProps = {
    variant: filtersOpen ? 'filled' : 'outlined',
    endIcon: 'plus',
    ...BtnProps,
    onClick: handleFilterClick,
    children: buttonLabel ?? t('actions.filters'),
  };
  return (
    <>
      <Btn ref={filterBtnRef} {...btnProps} />
      {isDesktop ? (
        <Popover
          open={filtersOpen}
          keepMounted
          onClose={handleClose}
          anchorEl={filterBtnRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          {...PopoverProps}>
          <Flex
            spacing="xs"
            // bordered
            // rounded
            cx={{ padding: 'md' }}>
            {children}
          </Flex>
        </Popover>
      ) : (
        <Drawer
          open={filtersOpen}
          keepMounted
          onClose={handleClose}
          anchor="right"
          sx={{ zIndex: 4000 }}
          PaperProps={{
            sx: {
              // width: '100vw',
              minWidth: 300,
              maxWidth: 400,
            },
          }}>
          <Flex spacing="xs" cx={{ padding: 'md' }}>
            <Flex direction="row" justifyContent="between" alignItems="center">
              <Btn {...btnProps} />
              <Btn variant="nav" endIcon="close" size="small" onClick={handleClose}>
                {t('common.close')}{' '}
              </Btn>
            </Flex>
            {children}
            <Box>
              <Btn onClick={handleClose}>{t('common.apply')}</Btn>
            </Box>
          </Flex>
        </Drawer>
      )}
    </>
  );
};
