import { isString } from 'lodash';
import React from 'react';

export type SelectElementInput = React.RefObject<HTMLElement> | HTMLElement | string | null;

/**
 * Retrieve an HTMLElement from a range of inputs
 */
export const selectHTMLElement = (ref?: SelectElementInput) => {
  let el: HTMLElement | null = null;

  if (ref instanceof HTMLElement) {
    el = ref;
  } else if (isString(ref)) {
    // Escape a numbered Id string. See https://stackoverflow.com/a/20306237
    ref = ref.replace(/^#([0-9])/, '#\\3$1 ');

    const maybeElement = document.querySelector(ref);
    if (maybeElement instanceof HTMLElement) el = maybeElement;
  } else {
    el = ref?.current ?? null;
  }

  return el;
};
