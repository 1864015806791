import AppRoot from '@/components/AppRoot';
import type { AppProps } from 'next/app';

import '@theme/global.css';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <AppRoot appContext={pageProps.appContext}>
      <Component {...pageProps} />
    </AppRoot>
  );
}
