import type { LogoSvgProps } from './Logo';

export const LogoSvg_RTCsm = (props: LogoSvgProps) => (
  <svg
    {...props}
    width="52"
    height="53"
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51.9822 26.0653C51.9824 22.652 51.3103 19.2721 50.0042 16.1186C48.6981 12.9651 46.7836 10.0997 44.3701 7.68615C41.9565 5.27256 39.0912 3.35801 35.9377 2.05182C32.7843 0.745635 29.4044 0.0733938 25.9911 0.0734864C22.5778 0.0733012 19.1978 0.745473 16.0442 2.05162C12.8907 3.35777 10.0253 5.2723 7.61169 7.6859C5.19809 10.0995 3.28355 12.9649 1.9774 16.1185C0.671255 19.272 -0.00091755 22.652 -0.000732384 26.0653C-0.000358483 32.9586 2.7382 39.5694 7.61256 44.4437C12.4869 49.3179 19.0978 52.0563 25.9911 52.0564C32.8843 52.0561 39.495 49.3176 44.3692 44.4434C49.2434 39.5692 51.9819 32.9585 51.9822 26.0653ZM34.9854 29.8473C34.9556 29.8837 34.9184 29.9133 34.8764 29.9343C34.8343 29.9553 34.7883 29.9672 34.7413 29.9692C34.6943 29.9712 34.6475 29.9632 34.6038 29.9459C34.5601 29.9285 34.5205 29.9022 34.4877 29.8685L27.4193 22.676C27.5415 22.3376 27.5924 21.9776 27.5688 21.6186C27.5251 21.0016 27.263 20.4205 26.8296 19.9793C26.3962 19.5381 25.8197 19.2658 25.2037 19.2112C24.846 19.1814 24.486 19.2258 24.1463 19.3416L15.3169 10.3571L25.4455 10.4453C25.4455 10.4453 35.0679 10.0392 37.574 19.3416C38.8781 24.1845 36.4778 28.0321 34.9854 29.8452M42.149 40.2466L30.5295 40.1479L21.201 30.725L21.12 40.064L12.5564 39.9935L12.6762 26.164L12.7573 16.825L12.813 10.5136L42.149 40.2466Z"
      fill="#AF471B"
    />
  </svg>
);
