import Card, { CardProps } from '@components/Card';
import { Entry, EntrySectionId, isEntry } from '@lib/features/entries';
import { useEvent } from '@lib/hooks';

export type EntryCardProps<T extends EntrySectionId = EntrySectionId> = CardProps<Entry<T>>;

const EntryCard = ({ item, ...props }: EntryCardProps) => {
  const { eventDateFormatted } = useEvent(item);

  if (!item) return null;

  const { uri: href, title, entrySummary: description, entryImage: image } = item;
  const meta: string[] = [];

  switch (true) {
    case isEntry(item, 'event'):
      if (eventDateFormatted) meta.push(eventDateFormatted);
      break;

    default:
      break;
  }

  return (
    <Card
      {...props}
      padding="lg"
      item={{
        meta,
        title,
        description,
        href,
        image,
      }}>
      <Card.Image />
      <Card.Body>
        <Card.Meta />
        <Card.Title />
        <Card.Description />
      </Card.Body>
      <Card.Foot />
    </Card>
  );
};

export default EntryCard;
