import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { FieldBaseFragmentDoc, FieldCommonFragmentDoc, FieldAttributeFragmentDoc, FieldOptionFragmentDoc } from '../../gql/_generated/fieldCommon.generated';
export type FieldNameFragment = { __typename: 'Field_Name', useMultipleFields?: boolean | null, firstNameCollapsed?: boolean | null, firstNameDefaultValue?: string | null, firstNameEnabled?: boolean | null, firstNameErrorMessage?: string | null, firstNameLabel?: string | null, firstNamePlaceholder?: string | null, firstNamePrePopulate?: string | null, firstNameRequired?: boolean | null, middleNameCollapsed?: boolean | null, middleNameDefaultValue?: string | null, middleNameEnabled?: boolean | null, middleNameErrorMessage?: string | null, middleNameLabel?: string | null, middleNamePlaceholder?: string | null, middleNamePrePopulate?: string | null, middleNameRequired?: boolean | null, lastNameCollapsed?: boolean | null, lastNameDefaultValue?: string | null, lastNameEnabled?: boolean | null, lastNameErrorMessage?: string | null, lastNameLabel?: string | null, lastNamePlaceholder?: string | null, lastNamePrePopulate?: string | null, lastNameRequired?: boolean | null, prefixCollapsed?: boolean | null, prefixDefaultValue?: string | null, prefixEnabled?: boolean | null, prefixErrorMessage?: string | null, prefixLabel?: string | null, prefixPlaceholder?: string | null, prefixPrePopulate?: string | null, prefixRequired?: boolean | null, defaultValue?: string | null, id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, inputTypeName?: string | null, instructions?: string | null, handle?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, prefixOptions?: Array<{ __typename: 'FieldAttribute', value?: string | null, label?: string | null } | null> | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export const FieldNameFragmentDoc = gql`
    fragment fieldName on Field_Name {
  ...fieldBase
  useMultipleFields
  firstNameCollapsed
  firstNameDefaultValue
  firstNameEnabled
  firstNameErrorMessage
  firstNameLabel
  firstNamePlaceholder
  firstNamePrePopulate
  firstNameRequired
  middleNameCollapsed
  middleNameDefaultValue
  middleNameEnabled
  middleNameErrorMessage
  middleNameLabel
  middleNamePlaceholder
  middleNamePrePopulate
  middleNameRequired
  lastNameCollapsed
  lastNameDefaultValue
  lastNameEnabled
  lastNameErrorMessage
  lastNameLabel
  lastNamePlaceholder
  lastNamePrePopulate
  lastNameRequired
  prefixCollapsed
  prefixDefaultValue
  prefixEnabled
  prefixErrorMessage
  prefixLabel
  prefixPlaceholder
  prefixPrePopulate
  prefixRequired
  prefixOptions {
    ...fieldAttribute
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;