import NextAnchor, {
  NextAnchorProps,
  NextAnchorPropsWithRef
} from '@componentPrimitives/NextAnchor';
import {
  createDefaultsMap,
  OverrideProps,
  Polymorphic,
  useDefaultsMap
} from '@componentPrimitives/Polymorphic';
import { SprinklesProps, useSprinklesProps } from '@theme/utils/useSprinkle';
import React from 'react';
import { BtnIconsProps, useBtnIcons } from './useBtnIcons';
import { BtnStyleProps, useBtnStyle } from './useBtnStyle';

export type BtnBaseProps = {
  // sx?: SxProps<Theme>;
  // linkComponent?: React.ElementType;
} & BtnStyleProps &
  BtnIconsProps &
  SprinklesProps;

export type BtnProps<
  C extends React.ElementType = 'button',
  P = NoProps
> = Polymorphic.PropsWithRef<C, BtnBaseProps & P>;

export interface BtnComponent<P = NoProps> {
  (props: OverrideProps<BtnBaseProps & NextAnchorProps, P>): React.ReactElement | null;
  <C extends React.ElementType = 'button'>(
    props: Polymorphic.Props<C, OverrideProps<BtnBaseProps, P>>
  ): React.ReactElement | null;
}

export interface BtnComponentWithRef<P = NoProps> {
  (props: OverrideProps<BtnBaseProps & NextAnchorPropsWithRef, P>): React.ReactElement | null;
  <C extends React.ElementType = 'button'>(
    props: Polymorphic.PropsWithRef<C, OverrideProps<BtnBaseProps, P>>
  ): React.ReactElement | null;
}

/**
 * A Map of HTML tag > props
 */
const buttonDefaultsMap = createDefaultsMap({
  button: {
    type: 'button',
    role: 'button',
  },
});

const Btn: BtnComponentWithRef = React.forwardRef(function Btn<C extends React.ElementType>(
  { as, ...rest }: BtnProps<C>,
  ref?: Polymorphic.Ref<C>
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let props: React.ComponentPropsWithoutRef<any> = rest;

  // Apply icon props
  props = useBtnIcons(props);

  // Apply Sprinkles props
  props = useSprinklesProps(props);

  // Apply style props
  props = useBtnStyle(props);

  const isLink = as === 'a' || 'href' in props;

  /**
   * Force {@link NextAnchor} as anchor component
   */
  const Component = isLink ? NextAnchor : as || 'button';

  /**
   * Select any default props for the Component
   */
  const defaults = useDefaultsMap(buttonDefaultsMap, Component);

  props = {
    ...defaults,
    ...props,
  };

  delete props.customText;

  return <Component ref={ref} {...props} />;
});

export default Btn;
