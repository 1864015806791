import { CommonLinks, getCommonLinks } from '@lib/features/commonLinks';
import { AppIndexData } from '@lib/features/entryIndexes';
import { AppGlobals, getGlobals } from '@lib/features/globals/getGlobals';
import { AppNavigationMenus, getAppNavigationMenus } from '@lib/features/navigation/navigation';
import { View } from '@lib/fetch/getViewByUri';
import { createContext } from 'react';

export type AppContext = {
  nav?: AppNavigationMenus;
  indexData?: AppIndexData | null;
  globals?: AppGlobals;
  commonLinks?: CommonLinks;
  view?: View | null;
};

const initialState: AppContext = {};

export const getAppContext = async (defaults?: Partial<AppContext>): Promise<AppContext> => {
  const nav = await getAppNavigationMenus();
  const globals = await getGlobals();
  const commonLinks = await getCommonLinks();

  return {
    ...initialState,
    ...defaults,
    nav,
    commonLinks,
    globals,
  };
};

export const appContext = createContext<AppContext>(initialState);

export const { Provider: AppContextProvider } = appContext;
