import React from 'react';
import NextHead from 'next/head';
import { useIsRTC } from '@lib/hooks';
import { useTranslations } from 'next-intl';

const Head = () => {
  const isRtc = useIsRTC();
  const t = useTranslations('common');
  const title = isRtc ? t('siteNameRtc') : t('siteName');
  const iconPath = isRtc ? '/rtc' : '';

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href={`${iconPath}/favicon.ico`} />
      <link rel="apple-touch-icon" sizes="180x180" href={`${iconPath}/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" sizes="32x32" href={`${iconPath}/favicon-32x32.png`} />
      <link rel="icon" type="image/png" sizes="16x16" href={`${iconPath}/favicon-16x16.png`} />
      <link rel="manifest" href={`${iconPath}/site.webmanifest`} />
    </NextHead>
  );
};

export default Head;
