/* eslint-disable @typescript-eslint/no-explicit-any */
import { stripHtmlTags } from '@lib/utils/htmlHelpers';
import { isString, makeNonNullableArray, toSnakeCase } from '@liquorice/allsorts-craftcms-nextjs';
import { toNumber } from 'lodash';
// import { ColorValue, colorValues, vars } from '@theme/_vars.css';
import { Table_TableMakerField } from '_generated/types';

export const parseMaterialIcon = (maybeString: any) => {
  if (!isString(maybeString)) return null;
  return toSnakeCase(maybeString);
};

export type Alignment = 'left' | 'auto' | 'right';
export type TextAlignment = 'auto' | 'center' | 'left' | 'right';

/**
 * Check and set the 'alignment' field
 */
export const parseTextAlignment = (maybeAlignment: any): TextAlignment => {
  switch (maybeAlignment) {
    case 'center':
      return 'center';
    case 'left':
      return 'left';
    case 'right':
      return 'right';
    default:
      return 'auto';
  }
};
/**
 * Check and set the 'alignment' field
 */
export const parseAlignment = (maybeAlignment: any): Alignment => {
  switch (maybeAlignment) {
    case 'left':
      return 'left';
    case 'right':
      return 'right';
    default:
      return 'auto';
  }
};

export const sanitiseHeading = (maybeHeading?: Maybe<string>) => {
  return stripHtmlTags(maybeHeading, ['strong', 'br']);
};

export const parseTable = (data?: Maybe<Table_TableMakerField>) => {
  if (!data) return null;

  const { columns: maybeColumns, rows: maybeRows } = data;
  // const align: ('left' | 'right' | 'center')[] = ['left', 'right', 'center'];
  const columns = makeNonNullableArray(maybeColumns);
  const rows = makeNonNullableArray(maybeRows);

  return {
    columns,
    rows,
  };
};

export const parseNColumns = (data: any) => {
  const allowedNColumns = [2, 3, 4];

  const n = toNumber(data);

  return allowedNColumns.includes(n) ? (n as 2 | 3 | 4) : null;
};
