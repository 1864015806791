import React from 'react';
import { OverrideProps } from './polymorphicUtils';

export type AsProp<C extends React.ElementType> = {
  as?: C;
};

export type Ref<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref'];

export type Props<C extends React.ElementType, P = NoProps> = OverrideProps<
  React.ComponentPropsWithoutRef<C>,
  P
> &
  AsProp<C>;

export type PropsWithRef<C extends React.ElementType, P = NoProps> = Props<C, P> & {
  ref?: Ref<C>;
};

export type Component<D extends React.ElementType, P = NoProps> = <C extends React.ElementType = D>(
  props: Props<C, P>
) => React.ReactElement | null;

export type ForwardRefComponent<D extends React.ElementType, P = NoProps> = <
  C extends React.ElementType = D
>(
  props: PropsWithRef<C, P>
) => React.ReactElement | null;
