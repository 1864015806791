import Btn, { BtnComponentWithRef, BtnProps } from '@componentPrimitives/Btn';
import { Polymorphic } from '@componentPrimitives/Polymorphic';
import Icon, { IconName } from '@components/Icon';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import * as style from './IconButton.css';

export type IconButtonBaseProps = {
  icon: IconName;
};

export type IconButtonProps<C extends React.ElementType = 'button'> = BtnProps<
  C,
  IconButtonBaseProps
>;

const IconButton: BtnComponentWithRef<IconButtonBaseProps> = React.forwardRef(function IconButton<
  C extends React.ElementType
>({ icon, _children, ...props }: IconButtonProps<C>, ref?: Polymorphic.Ref<C>) {
  return (
    <Btn ref={ref} square variant="filled" {...mergePropsClassName(props, style.root)}>
      <Icon name={icon} /* fontSize="large" */ className={style.icon} />
    </Btn>
  );
});

export default IconButton;
