import Container from '@componentPrimitives/Container';
import Flex from '@componentPrimitives/Flex';
import { useTxtStyle } from '@componentPrimitives/Txt';
import { CtaNav } from '@components/NavBar/NavBar.CtaNav';
import NavBarDrawer from '@components/NavBarDrawer';
import OpenHours from '@components/OpenHours';
import classNames from 'classnames';
// import NavBarDrawer from '@components/NavBarDrawer';
import * as style from './MegaMenu.css';
import { MegaMenuTopLevel } from './MegaMenu.TopLevel';
import { MegaMenuTopLevelItem } from './MegaMenu.TopLevelItem';
import { useMegaMenu } from './useMegaMenu';

/**
 * Display the entire menu in a Drawer
 */
export const MegaMenuTopLevelDrawer = () => {
  const { open, toggleOpen } = useMegaMenu(({ open, toggleOpen }) => ({ open, toggleOpen }));

  const ids = useMegaMenu((s) => s.ids);
  const btnClassName = classNames(
    useTxtStyle({ variant: 'h5' }).className,
    style.topLevelButtonMobile
  );

  return (
    <>
      <NavBarDrawer
        open={open}
        onClose={() => toggleOpen(false)}
        keepMounted
        PaperProps={{ className: style.itemPaper }}>
        <Container>
          <Flex spacing="gutter">
            <MegaMenuTopLevel
              direction="column"
              spacing="none"
              ButtonProps={{
                unstyled: true,
                variant: 'unstyled',
                className: btnClassName,
                endIcon: 'arrowRight',
              }}
            />
            <CtaNav BtnProps={{ color: 'primary', fullWidth: true }} />
            <OpenHours disableCta />
          </Flex>

          {ids.map((id) => (
            <MegaMenuTopLevelItem key={id} itemId={id} />
          ))}
        </Container>
      </NavBarDrawer>
    </>
  );
};
