import { OverrideProps } from '@componentPrimitives/Polymorphic';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import * as React from 'react';
import { LinkStyleProps, useLinkStyle } from './useLinkStyle';

// Add support for the sx prop for consistency with the other branches.

export type LinkProps = OverrideProps<React.ComponentPropsWithRef<'a'>, NextLinkProps> &
  LinkStyleProps;

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  props = useLinkStyle(props);

  return <NextLink ref={ref} {...props} />;
});
export default Link;
