import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Box, { BoxProps } from '@primitive/Box';
import { sprinkles, Sprinkles } from '@theme/_sprinkles.css';
import * as styles from './Shim.css';

export type ShimProps<D extends React.ElementType = 'div', P = NoProps> = BoxProps<
  D,
  P & {
    InnerProps?: BoxProps;
    ratio?: Sprinkles['paddingTop'];
  } & styles.ShimVariants
>;

const Shim = <T extends TagName>({
  InnerProps,
  round,
  ratio,
  children,
  ...props
}: ShimProps<T>) => {
  children = children && <Box {...mergePropsClassName(InnerProps, styles.inner)}>{children}</Box>;
  return (
    <Box
      {...mergePropsClassName(
        props,
        [styles.root({ round }), sprinkles({ paddingTop: ratio })].join(' ')
      )}>
      {children}
    </Box>
  );
};

export default Shim;
