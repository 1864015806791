import { Polymorphic } from '@componentPrimitives/Polymorphic';
import Box, { BoxComponent, BoxProps } from '@primitive/Box';
import React from 'react';
import { GridColStyleProps, useGridColStyle } from './useGridStyle';

type GridColBaseProps = GridColStyleProps;

export type GridColProps<C extends React.ElementType = 'div', P = NoProps> = BoxProps<
  C,
  GridColBaseProps & P
>;

export const GridCol: BoxComponent<'div', GridColBaseProps> = React.forwardRef(function GridCol<
  C extends React.ElementType
>(props: GridColProps<C>, ref?: Polymorphic.Ref<C>) {
  const boxProps = useGridColStyle(props);

  return <Box ref={ref} {...boxProps} />;
});
