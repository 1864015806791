import { filterBlocks, parseSanitisedBlocks, SanitisedBlock } from '@lib/features/blocks/blocks';
import { CommonLinkId, CommonLinks } from '@lib/features/commonLinks';
import { isEntry } from '@lib/features/entries';
import { AppGlobals } from '@lib/features/globals/getGlobals';
import { ImageEntry } from '@lib/features/images';
import { AppNavigationMenuName } from '@lib/features/navigation/navigation';
import { View } from '@lib/fetch/getViewByUri';
import { useIsRTC } from '@lib/hooks';
import { maybeGet, trimSlashes } from '@liquorice/allsorts-craftcms-nextjs';
import { ColorTheme } from '@theme/partials/palette.css';
import { useRouter } from 'next/router';
import React from 'react';
import { appContext } from './context';
// import { appContext } from './context';

/**
 * Select the current {@link View} object
 */
export const useView = (): View | null => {
  const { view } = React.useContext(appContext) ?? {};
  return view || null;
};

// export const useIndexData = <T extends EntryIndexKey>(indexKey: T) => {
//   const { indexData } = React.useContext(appContext) ?? {};
//   return indexData ? indexData[indexKey] : undefined;
// };

/**
 * Check if a string matches the start of the current href path
 */
export const useIsCurrentHref = (str?: string | null) => {
  const currentPath = trimSlashes(useRouter().asPath ?? '');
  const path = trimSlashes(str ?? '');

  if (!path || !currentPath) return false;

  if (currentPath === path) return true;

  if (currentPath.startsWith(path)) return true;

  return false;
};

export const useGlobal = <T extends keyof AppGlobals>(name: T): AppGlobals[T] => {
  const { globals } = React.useContext(appContext) ?? {};
  return globals ? globals[name] : undefined;
};

/**
 * Select the global default {@link ImageEntry}
 */
export const useDefaultImage = (maybeImage?: Maybe<ImageEntry>) => {
  const { image } = useGlobal('defaults') ?? {};
  return maybeImage?.src ? maybeImage : image ?? null;
};

/**
 * Select a globally available Nav menu
 */
export const useNavMenu = (name: AppNavigationMenuName) => {
  const { nav } = React.useContext(appContext) ?? {};
  return (nav && name in nav ? nav[name] : null) ?? { items: [], name };
};

/**
 * Select the current View Blocks
 */
export const useViewBlocks = () => {
  const { view } = React.useContext(appContext) ?? {};

  const blocks = maybeGet(view, 'blocks') ?? [];

  const parsedBlocks = parseSanitisedBlocks(blocks as SanitisedBlock[]);

  return filterBlocks(parsedBlocks);
};

/**
 * Select link info for significant App pages
 */
export const useAppLink = <T extends CommonLinkId>(typeId?: T): CommonLinks[T] | undefined => {
  const { commonLinks } = React.useContext(appContext) ?? {};

  return commonLinks && typeId ? commonLinks[typeId] : undefined;
};

export const useGlobalColorTheme = (): ColorTheme => {
  const themeColor = useGlobal('theme')?.colorTheme;
  return themeColor ?? 'default';
};

export const useViewColorTheme = (): ColorTheme => {
  const defaultTheme = 'default';

  const view = useView();
  const globalTheme = useGlobalColorTheme();
  if (isEntry(view, 'eventIndex')) return globalTheme;
  return maybeGet(view, 'colorTheme') ?? defaultTheme;
};

// ------------------------------------------------------------------------------------------------
// ---- View Callbacks ----

// export type ViewCallback<R, P> = (maybeView: Maybe<Partial<View>>, args?: P) => R;

// export const createViewCallback = <R, P>(cb: ViewCallback<R, P>) => cb;

// export const useIsAppPage = createViewCallback<boolean, CommonLinkId>((view, linkId) => {
//   const viewId = view?.id;
//   return useAppLink(linkId)?.id === viewId && !!viewId;
// });

// export const useIsViewHomepage = createViewCallback((view) => {
//   return useIsAppPage(view, 'home');
// });

// export const useIsViewOverviewTemplate = createViewCallback((view) => {
//   return useIsViewHomepage(view) || maybeGet(view, 'typeHandle') === 'overview';
// });

// const viewCallbacks = {
//   isAppPage: useIsAppPage,
//   isHomePage: useIsViewHomepage,
//   isOverviewTemplate: useIsViewOverviewTemplate,
// };

// type ViewCallbacks = typeof viewCallbacks;
// type ViewCallbackKey = keyof ViewCallbacks;
// type ViewCallbackProps<T extends ViewCallbackKey> = Parameters<ViewCallbacks[T]>[1];
// type ViewCallbackReturnType<T extends ViewCallbackKey> = ReturnType<ViewCallbacks[T]>;

// export const useViewCallback = <T extends ViewCallbackKey>(
//   callbackName: ViewCallbackKey,
//   args?: ViewCallbackProps<T>
// ) => {
//   const view = useView();
//   const cb = viewCallbacks[callbackName];
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   return cb(view, args as any) as ViewCallbackReturnType<T>;
// };

// export type ViewTemplate = 'standard' | 'overview' | 'home';

// export const useViewTemplate = <T extends ViewCallbackKey>(
//   callbackName: ViewCallbackKey,
//   args?: ViewCallbackProps<T>
// ) => {
//   const view = useView();
//   const cb = viewCallbacks[callbackName];
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   return cb(view, args as any) as ViewCallbackReturnType<T>;
// };
