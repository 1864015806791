import Link from '@component/Link';
import { mergeProps } from '@liquorice/allsorts-craftcms-nextjs';
import Box, { BoxProps } from '@primitive/Box';
import * as style from './Logo.css';
import { LogoSvg_Primary } from './Logo.Primary';
import { LogoSvg_RTC } from './Logo.Rtc';
import { LogoSvg_RTCsm } from './Logo.RtcSm';
import { LogoSvg_SHMA } from './Logo.Shma';

export type LogoProps = BoxProps<
  'span',
  {
    homeLink?: boolean;
    variant?: 'default' | 'shma' | 'rtc' | 'rtcMono' | 'rtcSm';
  }
>;

export type LogoSvgProps = React.ComponentPropsWithoutRef<'svg'> & {
  /** force monochrome */
  mono?: boolean;
};

const Logo = ({ homeLink, variant = 'default', ...props }: LogoProps) => {
  let SvgComponent;

  let SvgComponentProps: Partial<LogoSvgProps> = {};
  switch (variant) {
    case 'shma':
      SvgComponent = LogoSvg_SHMA;
      break;
    case 'rtc':
      SvgComponent = LogoSvg_RTC;
      break;
    case 'rtcMono':
      SvgComponent = LogoSvg_RTC;
      SvgComponentProps = { mono: true };
      break;
    case 'rtcSm':
      SvgComponent = LogoSvg_RTCsm;
      break;

    default:
      SvgComponent = LogoSvg_Primary;
  }
  // const SvgComponent = IS_RTC ? LogoSvg_RTC : variant === 'shma' ? LogoSvg_SHMA : LogoSvg_Primary;

  let inner = <SvgComponent className={style.svg} {...SvgComponentProps} />;

  if (homeLink)
    inner = (
      <Link className={style.link} href="/">
        {inner}
      </Link>
    );

  return <Box {...mergeProps(props, { className: style.link })}>{inner}</Box>;
};

export default Logo;
