import Flex, { FlexProps } from '@componentPrimitives/Flex';
import IconButton, { IconButtonProps } from '@components/IconButton';
import { Block } from '@lib/features/blocks';

export type SocialLinksProps = FlexProps<
  'div',
  {
    links: Block<'socialLinks_links_BlockType'>;
    IconButtonProps?: Partial<IconButtonProps<'a'>>;
  }
>;

const SocialLinks = ({ links, IconButtonProps, ...props }: SocialLinksProps) => {
  const { instagram, linkedin, facebook, twitter, youtube } = links;

  return (
    <Flex as="span" direction="row" spacing="2xs" {...props}>
      {facebook && (
        <IconButton {...IconButtonProps} href={facebook} target="_blank" icon="facebook" />
      )}
      {twitter && <IconButton {...IconButtonProps} href={twitter} target="_blank" icon="twitter" />}
      {instagram && (
        <IconButton {...IconButtonProps} href={instagram} target="_blank" icon="instagram" />
      )}
      {youtube && <IconButton {...IconButtonProps} href={youtube} target="_blank" icon="youTube" />}
      {linkedin && (
        <IconButton {...IconButtonProps} href={linkedin} target="_blank" icon="linkedIn" />
      )}
    </Flex>
  );
};

export default SocialLinks;
