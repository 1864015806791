import Logo from '@component/Logo';
import Box from '@componentPrimitives/Box';
import Container from '@componentPrimitives/Container';
import Divider from '@componentPrimitives/Divider';
import Flex from '@componentPrimitives/Flex';
import MegaMenu from '@components/MegaMenu';
import {
  createMegaMenuStore,
  MegaMenuProvider,
  useMegaMenu,
} from '@components/MegaMenu/useMegaMenu';
import SearchDialog from '@components/SearchDialog';
import { IS_RTC } from '@lib/constants';
import { useNavMenu, useViewColorTheme } from '@lib/store';
import { useRouterChangeStart } from '@lib/utils/useRouter';
import useSize from '@react-hook/size';
import { useBreakpointMin } from '@theme';
import { useEffect, useRef } from 'react';
import { HideOnScroll } from './HideOnScroll';
import * as style from './NavBar.css';
import { CtaNav } from './NavBar.CtaNav';
import { useNavbarState } from './useNavbarState';

const NavBarInner = () => {
  const navBarRef = useRef(null);

  const { megaMenuOpen, closeMegaMenu, setMegaMenuMode } = useMegaMenu((s) => ({
    setMegaMenuMode: s.setMode,
    megaMenuOpen: s.open,
    closeMegaMenu: () => s.toggleOpen(false),
  }));

  useRouterChangeStart(closeMegaMenu);

  const { setResponsiveVariant, setHeight } = useNavbarState();
  const [, navBarHeight] = useSize(navBarRef);

  const isDesktop = useBreakpointMin('lg', true);

  useEffect(() => {
    setHeight(navBarHeight);
  }, [setHeight, navBarHeight]);

  useEffect(() => {
    setResponsiveVariant(isDesktop ? 'desktop' : 'mobile');
    // Tie the MegaMenu mode to the version of the header
    setMegaMenuMode(isDesktop ? 'desktop' : 'mobile');
  }, [setResponsiveVariant, setMegaMenuMode, isDesktop]);

  /** Color scheme set from CMS, as set for this specific Entry View */
  const viewColorTheme = useViewColorTheme();
  /** Disable the color scheme if mega menu is open */
  const currentColorTheme = megaMenuOpen ? 'default' : viewColorTheme;
  const hasColorTheme = !!currentColorTheme;

  return (
    <Box
      ref={navBarRef}
      colorTheme={viewColorTheme}
      colorSet="paper"
      paper
      className={style.root({
        withViewColorTheme: hasColorTheme,
      })}>
      <HideOnScroll in={!!megaMenuOpen ? true : undefined}>
        <Box
          className={style.inner}
          {...(currentColorTheme !== viewColorTheme && {
            colorTheme: currentColorTheme,
          })}
          colorSet="paper"
          paper>
          <Container className={style.container}>
            <Logo
              homeLink
              variant={IS_RTC ? (isDesktop ? 'rtc' : 'rtcSm') : 'default'}
              className={IS_RTC ? style.logoRTC : style.logo}
            />
            {isDesktop ? (
              <>
                <MegaMenu.TopLevel direction="row" spacing="xs" />
                <MegaMenu.SubLevelDrawer />
                <Divider direction="vertical" />
                <SearchDialog />
                <CtaNav
                  className={style.ctaNav}
                  // Use Primary color unless the page color scheme is set
                  BtnProps={{ color: 'primary' }}
                />
              </>
            ) : (
              <Flex direction="row" spacing="xs" cx={{ mL: 'auto' }}>
                <SearchDialog />
                <MegaMenu.Hamburger />
                <MegaMenu.TopLevelDrawer />
              </Flex>
            )}
          </Container>
        </Box>
      </HideOnScroll>
    </Box>
  );
};

// Wrap the NavBar in the MegaMenu provider to provide context
const NavBar = () => {
  const primaryNav = useNavMenu('primaryNav');

  return (
    <MegaMenuProvider createStore={() => createMegaMenuStore({ items: primaryNav.items ?? [] })}>
      <NavBarInner />
    </MegaMenuProvider>
  );
};

export default NavBar;
