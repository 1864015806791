import NavBarDrawer from '@components/NavBarDrawer';
// import NavBarDrawer from '@components/NavBarDrawer';
import * as style from './MegaMenu.css';
import { MegaMenuTopLevelItem } from './MegaMenu.TopLevelItem';
import { useMegaMenu } from './useMegaMenu';

/**
 * Display the currently active item in its own Drawer
 */
export const MegaMenuSubLevelDrawer = () => {
  const { activeItem, ids, setActiveItem } = useMegaMenu(({ activeItem, setActiveItem, ids }) => ({
    activeItem,
    setActiveItem,
    ids,
  }));

  const open = activeItem !== null;

  const handleClose = () => {
    setActiveItem(null);
  };

  return (
    <NavBarDrawer
      open={open}
      onClose={handleClose}
      keepMounted
      PaperProps={{ className: style.itemPaper }}>
      {ids.map((id) => (
        <MegaMenuTopLevelItem key={id} itemId={id} />
      ))}
    </NavBarDrawer>
  );
};
