export enum ORDER_BY_FIELD_ENUM {
  SEARCH_SCORE = 'score',
  DATE = 'postDate',
  TITLE = 'title',
}

export enum ORDER_BY_ORDER_ENUM {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type OrderByQueryString =
  | `${ORDER_BY_FIELD_ENUM | string}`
  | `${ORDER_BY_FIELD_ENUM | string} ${ORDER_BY_ORDER_ENUM}`;

export const getOrderByOptions = () => {
  // <T extends keyof ORDER_BY_FIELD_ENUM>(exclude?: T[]) => {
  return {
    // [`${ORDER_BY_FIELD_ENUM.SEARCH_SCORE} ${ORDER_BY_ORDER_ENUM.DESC}`]: 'Relevance',
    [`${ORDER_BY_FIELD_ENUM.DATE} ${ORDER_BY_ORDER_ENUM.DESC}`]: 'Newest',
    [`${ORDER_BY_FIELD_ENUM.TITLE} ${ORDER_BY_ORDER_ENUM.ASC}`]: 'Title (A-Z)',
    [`${ORDER_BY_FIELD_ENUM.TITLE} ${ORDER_BY_ORDER_ENUM.DESC}`]: 'Title (Z-A)',
  } as Record<OrderByQueryString, string>;
};

export const getOrderByDefault = () => {
  return `${ORDER_BY_FIELD_ENUM.DATE} ${ORDER_BY_ORDER_ENUM.DESC}` as OrderByQueryString;
};
