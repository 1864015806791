import client from '@lib/fetch/client';
import { withSite } from '@lib/hooks';
import { toString } from '@liquorice/allsorts-craftcms-nextjs';
import { parseUri } from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';

import { CommonLinkFragment, getSdk } from './_generated/getCommonLinks.generated';

// ---------------------------------------------------------------------------------------------- //
// ---- Common Links ----

gql`
  fragment commonLink on EntryInterface {
    id
    uri
    title
  }
  query commonLinks($site: [String] = "default") {
    home: entry(site: $site, uri: "__home__") {
      ...commonLink
    }
    search: entry(site: $site, section: "search") {
      ...commonLink
    }
    articleIndex: entry(site: $site, section: "articleIndex") {
      ...commonLink
    }
    eventIndex: entry(site: $site, section: "eventIndex") {
      ...commonLink
    }
    learningIndex: entry(site: $site, section: "learningIndex") {
      ...commonLink
    }
    profileIndex: entry(site: $site, section: "profileIndex") {
      ...commonLink
    }
  }
`;

export type CommonLink = {
  id: ID;
  title: string;
  uri: string | null;
};

export const parseCommonLink = (data: Maybe<CommonLinkFragment>): CommonLink => {
  return {
    id: toString(data?.id),
    title: toString(data?.title),
    uri: parseUri(data?.uri) ?? null,
  };
};

export const getCommonLinks = async () => {
  const sdk = getSdk(client);
  const commonLinks = (await sdk.commonLinks(withSite())) ?? {};

  return {
    home: parseCommonLink(commonLinks.home),
    search: parseCommonLink(commonLinks.search),
    articleIndex: parseCommonLink(commonLinks.articleIndex),
    eventIndex: parseCommonLink(commonLinks.eventIndex),
    profileIndex: parseCommonLink(commonLinks.profileIndex),
    learningIndex: parseCommonLink(commonLinks.learningIndex),
  };
};

export type CommonLinks = ReturnOrPromiseType<typeof getCommonLinks>;
export type CommonLinkId = keyof CommonLinks;
