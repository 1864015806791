import { ParsedForm } from '@formie/parse/formieParser';
import { FormieFields } from './FormieFields';
import { FormieSubmitRow } from './FormieSubmitRow';
import { createFormStore, FormContextProvider, useForm } from './useForm';

export type FormieProps = {
  form?: ParsedForm;
};

const FormieForm = ({ form }: FormieProps) => {
  const submit = useForm((s) => s.submit);

  const { formFields: fields = [] } = form ?? {};

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    submit();
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <FormieFields fields={fields} />
      <FormieSubmitRow />
    </form>
  );
};

const Formie = ({ form }: FormieProps) => {
  const mutationName = form?.submissionMutationName;

  return mutationName ? (
    <FormContextProvider createStore={() => createFormStore(mutationName, form)}>
      <FormieForm form={form} />
    </FormContextProvider>
  ) : (
    <></>
  );
};

export default Formie;
