import { NavMenuItem } from '@lib/features/navigation/navigation';
import createStore, { StoreApi } from 'zustand';
import createContext from 'zustand/context';

type MegaMenuMode = 'mobile' | 'desktop';

export interface MegaMenuState {
  mode: MegaMenuMode;
  setMode: (value: MegaMenuMode) => void;
  open: boolean;
  toggleOpen: (value?: boolean) => void;
  items: Record<ID, NavMenuItem>;
  ids: ID[];
  /** Index of currently open item */
  activeItem: ID | null;
  // activeItemPrev: ID | null;
  setActiveItem: (value: ID | null) => void;
}

export type CreateMegaMenuStoreProps = {
  items: NavMenuItem[];
};

export const createMegaMenuStore = ({ items: itemArr = [] }: CreateMegaMenuStoreProps) => {
  const ids = itemArr.map((v) => v.id);

  const items = itemArr.reduce(
    (results, item) => ({
      ...results,
      [item.id]: item,
    }),
    {} as Record<ID, NavMenuItem>
  );

  return createStore<MegaMenuState>((set, get) => ({
    mode: 'mobile',
    setMode: (mode: MegaMenuMode) => set({ mode }),
    open: false,
    toggleOpen: (value) => {
      const isOpen = !!get().open;
      const open = value ?? !isOpen;
      set({ open, activeItem: null });
    },
    items,
    ids,
    activeItem: null,
    // activeItemPrev: null,
    setActiveItem: (activeItem) =>
      set(({ mode, open }) => ({
        activeItem,
        /**
         * in 'desktop' mode, open and close according to an activeItem existing,
         * otherwise, allow it to be managed independently
         */
        open: mode === 'desktop' ? activeItem !== null : open,
      })),
  }));
};

export const { Provider: MegaMenuProvider, useStore: useMegaMenu } =
  createContext<StoreApi<MegaMenuState>>();

export const useMegaMenuItem = (id: ID) => {
  return useMegaMenu(({ items, activeItem, setActiveItem }) => {
    const isActive = activeItem === id;

    return {
      item: items[id],
      active: isActive,
      toggleActive: (active?: boolean) => {
        const newValue = active ?? !isActive;
        setActiveItem(newValue ? id : null);
      },
    };
  });
};

/**
 * Use information about the current variant of the mega menu (ie desktop/mobile)
 */
export const useMegaMenuVariant = () => {
  const variant = useMegaMenu((s) => s.mode);

  return {
    variant,
    isDesktop: variant === 'desktop',
    isMobile: variant !== 'desktop',
  };
};
