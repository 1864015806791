import Btn, { BtnProps } from '@componentPrimitives/Btn';
import Icon from '@components/Icon';
import { useTranslations } from 'next-intl';

export type TopButtonProps = BtnProps;

const TopButton = ({ ...props }: TopButtonProps) => {
  const t = useTranslations('common');

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Btn
      onClick={handleScrollToTop}
      aria-label={t('top')}
      variant="filled"
      rounded={false}
      {...props}>
      <Icon name="arrowUp" />
    </Btn>
  );
};

export default TopButton;
