import { OverrideProps } from '@componentPrimitives/Polymorphic';
import Link, { LinkProps } from 'next/link';
import React from 'react';

export type NextAnchorProps = OverrideProps<
  React.ComponentProps<'a'>,
  Omit<LinkProps, 'as'> & { asHref?: LinkProps['as'] }
>;

export type NextAnchorPropsWithRef = NextAnchorProps & React.RefAttributes<HTMLAnchorElement>;

/**
 * An anchor component with NextJs Link properties
 * The `as` prop has been moved to `asHref` so as not to conflict with the Polymorphic component `as` prop
 */
const NextAnchor = React.forwardRef<HTMLAnchorElement, NextAnchorPropsWithRef>(function NextAnchor(
  { asHref, ...props },
  ref
) {
  return <Link ref={ref} {...props} as={asHref} />;
});

export default NextAnchor;
