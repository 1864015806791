import Btn, { BtnComponentWithRef } from '@componentPrimitives/Btn';
import React from 'react';

// export type CtaButtonProps = Link;

const CtaButton: BtnComponentWithRef = React.forwardRef(function CtaButton(props, ref) {
  return <Btn ref={ref} variant="filled" endIcon="arrowRight" {...props} />;
});

export default CtaButton;
