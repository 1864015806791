import client from '@lib/fetch/client';
import { keys, values } from '@liquorice/allsorts-craftcms-nextjs';
import { useEffect } from 'react';
import createStore, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { applyFieldConditions } from './fieldConditions';
import { parseFormSubmissionErrors } from './parse/formieErrorsParser';
import { parseSubmitFormQuery } from './parse/formieMutation';
import { ParsedForm } from './parse/formieParser';

// ------------------------------------------------------------------------------------------------
// ---- Value Types ----

export type FieldValueSingle = string;
export type FieldValueMulti = string[];
export type FieldValueComplex = Record<string, string>;

export type FieldValueTypes = {
  single: FieldValueSingle;
  multi: FieldValueMulti;
  complex: FieldValueComplex;
};

export type FieldValueType = keyof FieldValueTypes;
export type FieldValue<T extends FieldValueType = FieldValueType> = FieldValueTypes[T];

export type FormValues = Record<string, FieldValue | null>;

// ---- Type Guards ----

export const isFieldValueSingle = (x?: FieldValue | FormValues): x is FieldValueSingle => {
  return !x || typeof x === 'string';
};

export const isFieldValueMulti = (x: FieldValue | FormValues): x is FieldValueMulti => {
  if (isFieldValueSingle(x)) return false;
  const nonBoolValues = values(x).filter((x) => typeof x !== 'boolean');
  return nonBoolValues.length ? false : true;
};

export const isFieldValueComplex = (x: FieldValue | FormValues): x is FieldValueComplex => {
  if (isFieldValueSingle(x)) return false;
  if (isFieldValueMulti(x)) return false;
  return true;
};

// ------------------------------------------------------------------------------------------------
// ---- Error Types ----

export type FieldStatus = boolean;
export type FieldStatuses = Record<string, FieldStatus>;

// ------------------------------------------------------------------------------------------------
// ---- Error Types ----

export type FieldErrors = string[];
export type FormErrors = Record<string, FieldErrors>;

// ------------------------------------------------------------------------------------------------
// ---- Form State ----

export type FormState = {
  mutationName: string;
  fieldStatuses: FieldStatuses;
  values: FormValues;
  errors: FormErrors;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  messages: {
    error: string;
    success: string;
  };
  setValue: (name: string, value: FieldValue | null) => void;
  unsetValue: (name: string) => void;
  submit: () => void;
};

export const submitForm = async (mutationName: string, formValues: FormValues) => {
  try {
    const query = parseSubmitFormQuery({ mutationName, formValues });
    const data = await client.request(query);
    return data;
  } catch (error) {
    return error;
  }
};

/**
 * Create the form context state
 */
export const createFormStore = (mutationName: string, form: ParsedForm) => {
  const { formFields, settings } = form;

  return createStore<FormState>((set, get) => ({
    mutationName,
    values: {},
    fieldStatuses: {},
    errors: {},
    isLoading: false,
    isSuccess: false,
    isError: false,
    messages: {
      error: settings?.errorMessageHtml ?? 'An error occurred',
      success: settings?.submitActionMessageHtml ?? 'Submitted',
    },
    setValue: (name, value) => {
      set(({ values }) => {
        const newValues = { ...values, [name]: value };
        const newFieldStatuses = applyFieldConditions(formFields, newValues);

        return {
          values: newValues,
          fieldStatuses: newFieldStatuses,
        };
      });
    },
    unsetValue: (name) => {
      set(({ values }) => {
        delete values[name];
        return { values };
      });
    },
    submit: async () => {
      const { values, mutationName } = get();

      const hasValues = !!keys(values).length;

      if (!hasValues) return;

      set({
        isSuccess: false,
        isLoading: true,
        isError: false,
      });

      submitForm(mutationName, values).then((res) => {
        const errors = parseFormSubmissionErrors(res);
        const maybeResult: string | null =
          res && mutationName in res ? res[mutationName]?.uid : null;
        const result = !errors && maybeResult ? maybeResult : null;
        const isSuccess = !!result;

        const newValues = isSuccess ? {} : values;

        set({
          isSuccess: isSuccess,
          isLoading: false,
          isError: !!errors,
          errors: errors ?? {},
          values: newValues,
        });
      });
    },
  }));
};

// ------------------------------------------------------------------------------------------------
// ---- Hooks ----

export const { Provider: FormContextProvider, useStore: useForm } =
  createContext<StoreApi<FormState>>();

export const getFieldDefault = <T extends FieldValueType = 'single'>(
  type?: T
  // defaultValue?: FieldValue<T>
) => (type === 'complex' ? {} : type === 'multi' ? [] : '') as FieldValue<T>;

/**
 * Retrieve and set the value for a given field in the {@link FormState}
 */
export const useFormField = <T extends FieldValueType>(
  name: string,
  type?: T,
  defaultValue?: FieldValue<T>
) => {
  const { value, setValue, unsetValue, errors } = useForm((state) => ({
    value: state.values[name],
    setValue: (value: FieldValue<T> | null) => state.setValue(name, value),
    unsetValue: () => state.unsetValue(name),
    errors: (state.errors ?? {})[name],
  }));

  useEffect(() => {
    setValue(defaultValue ?? getFieldDefault(type));

    return () => unsetValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    value: (value ?? getFieldDefault(type)) as FieldValue<T>,
    setValue,
    unsetValue,
    errors,
    error: !!errors,
  };
};
