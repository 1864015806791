import Link from '@component/Link';
import Txt from '@primitive/Txt';
import { useNavMenu } from '@lib/store';
import { List } from '@primitives';

export const PoliciesNav = () => {
  const { items } = useNavMenu('policiesNav') ?? {};

  return (
    <nav>
      <List
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          columnGap: 2,
        }}>
        {items?.map(({ id, href, title }) => (
          <li key={id}>
            {href ? (
              <Link href={href} variant="small">
                {title}
              </Link>
            ) : (
              <Txt variant="small">{title}</Txt>
            )}
          </li>
        ))}
      </List>
    </nav>
  );
};
