import { createToggleStore } from '@lib/utils/createToggleStore';
import Box, { BoxProps } from '@primitive/Box';
import Txt from '@primitive/Txt';
import { useGlobal } from '@lib/store';
import Formie from '@components/Formie';
import React from 'react';
import { Dialog } from '@mui/material';
import Btn from '@componentPrimitives/Btn';
import Flex, { FlexProps } from '@componentPrimitives/Flex';
import { LightTheme } from '@components/ThemeProvider/LightTheme';
import { useTranslations } from 'next-intl';

export type SubscribeFormProps = FlexProps;

const useSubscribeFormDialog = createToggleStore();

export const SubscribeForm = (props: SubscribeFormProps) => {
  const subscribeForm = useGlobal('subscribeForm');
  const t = useTranslations();
  const { value: dialogOpen, toggle: toggleDialog } = useSubscribeFormDialog();

  if (!subscribeForm) return null;

  const { heading, content } = subscribeForm;
  return (
    <Flex alignItems="start" spacing="xs" {...props}>
      <Txt variant="bodyAlt" as="h2" bold>
        {heading}
      </Txt>
      <Txt html>{content}</Txt>
      <Btn variant="filled" onClick={() => toggleDialog()}>
        {t('cta.signUp')}
      </Btn>
      <LightTheme>
        <Dialog open={dialogOpen} onClose={() => toggleDialog(false)}>
          <Flex colorTheme="default" colorSet="paper" paper cx={{ p: 'lg' }}>
            <Flex direction="row" justifyContent="between" alignItems="start">
              <Txt variant="h5" autoMargin>
                {heading}
              </Txt>
              <Btn variant="nav" endIcon="close" onClick={() => toggleDialog()}>
                {t('common.close')}
              </Btn>
            </Flex>
            <Txt html>{content}</Txt>
            <Formie form={subscribeForm.form ?? undefined} />
          </Flex>
        </Dialog>
      </LightTheme>
    </Flex>
  );
};
