import { Entry, isEntry } from '@lib/features/entries';
import { useTranslations } from 'next-intl';

export const useEntryTypeLabel = (item?: Entry) => {
  const t = useTranslations();

  switch (true) {
    case isEntry(item, 'article'):
      return t('article.singularName');

    case isEntry(item, 'event'):
      return t('event.singularName');
      break;

    case isEntry(item, 'learning'):
      return t('learning.singularName');

    case isEntry(item, 'profile'):
      return t('profile.singularName');

    default:
      return t('page.singularName');
  }
};
