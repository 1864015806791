import * as Types from '../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Category_Accessibility_Category_Fragment = { __typename: 'accessibility_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type Category_AgeGroup_Category_Fragment = { __typename: 'ageGroup_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type Category_ArticleCategory_Category_Fragment = { __typename: 'articleCategory_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type Category_EventBrand_Category_Fragment = { __typename: 'eventBrand_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type Category_EventLocation_Category_Fragment = { __typename: 'eventLocation_Category', locationMapRef?: string | null, id?: string | null, uri?: string | null, title?: string | null, groupHandle: string, content?: string | null };

export type Category_EventType_Category_Fragment = { __typename: 'eventType_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type Category_LearningCategory_Category_Fragment = { __typename: 'learningCategory_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type Category_LearningLevel_Category_Fragment = { __typename: 'learningLevel_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type Category_ProfileCategory_Category_Fragment = { __typename: 'profileCategory_Category', id?: string | null, uri?: string | null, title?: string | null, groupHandle: string };

export type CategoryFragment = Category_Accessibility_Category_Fragment | Category_AgeGroup_Category_Fragment | Category_ArticleCategory_Category_Fragment | Category_EventBrand_Category_Fragment | Category_EventLocation_Category_Fragment | Category_EventType_Category_Fragment | Category_LearningCategory_Category_Fragment | Category_LearningLevel_Category_Fragment | Category_ProfileCategory_Category_Fragment;

export const CategoryFragmentDoc = gql`
    fragment category on CategoryInterface {
  __typename
  id
  uri
  title
  groupHandle
  ... on eventLocation_Category {
    content: htmlContentSimple
    locationMapRef
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;