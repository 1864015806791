import "src/theme/partials/palette.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/theme/partials/palette.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+WUXU+DMBSG7/kVTXqjFzNtKRS6Kwbt/2AfuDEYijq/sv9uOhPTk5kTsyhELVe8b3nPeXoadN919+Q1IGQyqaK27hqmCWXHNfVUrgm1x+WrQhPKrUwU99VQE5pZqfjMV6VLSG1iI1+NnKrc46ux64GnuQCq0oSqdKYU6CHRhBoTKQn6TV2uMTYHe0tNaKFEzjNfnbu9hYmL2FcXrodMpTmgWLqE0ChT+OrqU+JKExpaGQnQw7WrJkwUZ9PgEOiT819rsi/7i4+Dv/Q/3kCTAbOGpgDmFottoCmB2WI1d5jZndY8BFfvL/1/hL7DoBkGzTFojkGzn4HmGDTzodHrrc6eNDt70jEGzb9n0g8YdDoCdDLA9d5j0PMRoMsBJv2IQS9HgF4MAP2EQVe/6kf2dehnDHr1R6FfAPQN3LkGMbfQ3PgxnGE5NZazBTkcy2mwnBbkCCxnh+V0LucNjgYqMjgLAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/theme/partials/typography.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/theme/partials/typography.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tXzFpNzJiaTEwOiAiR2FybmV0dCIsIEludGVyLCBzYW5zLXNlcmlmOwogIC0tXzFpNzJiaTExOiBJbnRlciwgc2Fucy1zZXJpZjsKICAtLV8xaTcyYmkxMjogIkdhcm5ldHQiLCBJbnRlciwgc2Fucy1zZXJpZjsKICAtLV8xaTcyYmkxMzogMS4yOwogIC0tXzFpNzJiaTE0OiAxLjE1OwogIC0tXzFpNzJiaTE1OiAxLjI7CiAgLS1fMWk3MmJpMTY6IDEuMzsKICAtLV8xaTcyYmkxNzogMS40OwogIC0tXzFpNzJiaTE4OiAxLjU7CiAgLS1fMWk3MmJpMTk6IDFyZW07CiAgLS1fMWk3MmJpMWE6IDEuMjVyZW07CiAgLS1fMWk3MmJpMWI6IDAuODc1cmVtOwogIC0tXzFpNzJiaTFjOiBjbGFtcCgyLjM3NXJlbSwgY2FsYygxLjU3MTQzNzVyZW0gKyAyLjIzMnZ3KSwgM3JlbSk7CiAgLS1fMWk3MmJpMWQ6IGNsYW1wKDIuMTI1cmVtLCBjYWxjKDEuNjQyODc1cmVtICsgMS4zMzl2dyksIDIuNXJlbSk7CiAgLS1fMWk3MmJpMWU6IGNsYW1wKDJyZW0sIGNhbGMoMS42Nzg1NjI1cmVtICsgMC44OTN2dyksIDIuMjVyZW0pOwogIC0tXzFpNzJiaTFmOiBjbGFtcCgxLjc1cmVtLCBjYWxjKDEuNDI4NTYyNXJlbSArIDAuODkzdncpLCAycmVtKTsKICAtLV8xaTcyYmkxZzogMS41cmVtOwogIC0tXzFpNzJiaTFoOiAxLjI1cmVtOwogIC0tXzFpNzJiaTFpOiAxLjI1cmVtOwogIC0tXzFpNzJiaTFqOiBjbGFtcCgxLjM3NXJlbSwgY2FsYygxLjIxNDMxMjVyZW0gKyAwLjQ0NnZ3KSwgMS41cmVtKTsKICAtLV8xaTcyYmkxazogMC44NzVyZW07CiAgLS1fMWk3MmJpMWw6IGNsYW1wKDIuNjI1cmVtLCBjYWxjKC0wLjQyODU2MjVyZW0gKyA4LjQ4MnZ3KSwgNXJlbSk7CiAgLS1fMWk3MmJpMW06IDQwMDsKICAtLV8xaTcyYmkxbjogNTAwOwogIC0tXzFpNzJiaTFvOiA2MDA7CiAgLS1fMWk3MmJpMXA6IDYwMDsKICAtLV8xaTcyYmkxcTogNjAwOwp9\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/theme/partials/grid.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/theme/partials/grid.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0tXzFhY2ViNHEwOiAxMjsKICAtLV8xYWNlYjRxMTogMTZweDsKICAtLV8xYWNlYjRxMjogMjRweDsKICAtLV8xYWNlYjRxMzogMjRweDsKfQ==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/theme/partials/spacing.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/theme/partials/spacing.css.ts.vanilla.css\",\"source\":\"OnJvb3QgewogIC0ta2RtdnFpMDogMDsKICAtLWtkbXZxaTE6IDFweDsKICAtLWtkbXZxaTI6IDJweDsKICAtLWtkbXZxaTM6IHZhcigtLV8xYWNlYjRxMyk7CiAgLS1rZG12cWk0OiB2YXIoLS1fMWFjZWI0cTEpOwogIC0ta2RtdnFpNTogNDBweDsKICAtLWtkbXZxaTY6IDAuNXJlbTsKICAtLWtkbXZxaTc6IDAuNzVyZW07CiAgLS1rZG12cWk4OiAxcmVtOwogIC0ta2RtdnFpOTogMS4yNXJlbTsKICAtLWtkbXZxaWE6IDEuNXJlbTsKICAtLWtkbXZxaWI6IDJyZW07CiAgLS1rZG12cWljOiAyLjVyZW07CiAgLS1rZG12cWlkOiBjbGFtcCgyLjVyZW0sIGNhbGMoMS44NTcxMjVyZW0gKyAxLjc4NnZ3KSwgM3JlbSk7CiAgLS1rZG12cWllOiBjbGFtcCgzcmVtLCBjYWxjKDIuMzU3MTI1cmVtICsgMS43ODZ2dyksIDMuNXJlbSk7CiAgLS1rZG12cWlmOiBjbGFtcCgzLjVyZW0sIGNhbGMoMi4yMTQzMTI1cmVtICsgMy41NzF2dyksIDQuNXJlbSk7CiAgLS1rZG12cWlnOiBjbGFtcCgzLjVyZW0sIGNhbGMoMS41NzE0Mzc1cmVtICsgNS4zNTd2dyksIDVyZW0pOwp9\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/theme/_vars.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/theme/_vars.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA42T2U7DMBBF3/mKeQSprjx2lnb4GOQsLaZtbJy0pCD+HTkqwstDkRJFPpm5dzLKJWfMBF8PAIw5a/ut4wQokD8HCAlQ8ggJgugsCcq6snPICoK62sSsJEAuihhW3pLzGNYEWKKI4YYgIVuC0Rx1FzKVDNcQ8HXp+lMIWw83dYo7AlyjSHFPIDKJHcFFuUfGXlDXotF4eApf7wkw6Xj1ppm49p4yG+Ut7z+kjjpyPOYdJ++YSQ+pzi7SMf5r8yVYP2e+sncCbuflruwMbt+oR76C27VGGWm7v2rk98vHoPwf6tOy4DFEZ4+wjNglL/vwSEZo9qiI0HX5lSL0SdCo9rB35jx0rDVH41ZwezTGdb37hcsY0KuxZ3pg5jxFIeMExqpWT9c7hUgwOTWMO+NOd0rTmKIkSMKHBUESUvQpTcKMFYFcIvr9Ay4Jeag0BAAA\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/componentPrimitives/Txt/Txt.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/componentPrimitives/Txt/Txt.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA92W24rCMBBA3/sVeVzBSL1rhf2VJbZpOzWXmsZ1L+y/L1bdjkVSigRkH8ucmTkhQzojxpLD+jgjr6SMUjCVpXEOIhmSUROpSqZwsA2T74AQSs8J04i8M/NC6dsYlpMtjOVgExCSamVpyiSIzzYQNkAFX7wdLuqwAMVpziHLbRtY1YBkJgNFrS4jclAVt5vgJ7gcYlEbxlpoc00+B8IhAZVzA7ZxON40OXOT4c3ndDBA1Zc35w+vmelcFlpwTK5cZI7JtYsETDIXWWBy6yJ3mIxdpMBk4iIlJrmLVJhMXaTGZFaTl5vfamu1jEg4WnCJIKihO1d7nZ8Slyzcw7x/cJhF1zDPsMzOr0zcS0b4lUm6ZKZYRvqV4b1klF+ZtJeM9iuT9ZIp/crkXTILLLN/gv/Un4xpXqX73cbubruubkvcrXrw6B0y0EvG+pVZ97qHg9+h6CczJwxvKpfV5FTB8g9LEx5rwyxodVpxEm5OdVH6P93ifgGNixcwpAoAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = {fn:_7a468({defaultClassName:'aadu9w6',variantClassNames:{color:{error:'aadu9w7',paper:'aadu9w8',paperContrast:'aadu9w9',primary:'aadu9wa',primaryContrast:'aadu9wb',secondary:'aadu9wc',secondaryContrast:'aadu9wd',action:'aadu9we',actionContrast:'aadu9wf'},autoMargin:{true:'aadu9wg'},withLede:{true:'aadu9w4'},bold:{true:'aadu9wi'},variant:{xl:'aadu9wj',h1:'aadu9wk',h2:'aadu9wl',h3:'aadu9wm',h4:'aadu9wn',h5:'aadu9wo',h6:'aadu9wp',lede:'aadu9wq',small:'aadu9wr',large:'aadu9ws',body:'aadu9wt',bodyAlt:'aadu9wu'}},defaultVariants:{},compoundVariants:[]}),variantKeys:['color','autoMargin','withLede','bold','variant']};
export var txtDefaultVars = {fontWeight:'var(--aadu9w3)'};
export var txtVars = {color:'var(--aadu9w0)',display:'var(--aadu9w1)',fontWeight:'var(--aadu9w2)'};
export var typographyClasses = {xl:{vars:{'var(--aadu9w3)':'var(--_1i72bi1q)'},fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1l)',lineHeight:'var(--_1i72bi14)'},h1:{vars:{'var(--aadu9w3)':'var(--_1i72bi1q)'},fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1c)',lineHeight:'var(--_1i72bi14)'},h2:{vars:{'var(--aadu9w3)':'var(--_1i72bi1q)'},fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1d)',lineHeight:'var(--_1i72bi13)'},h3:{vars:{'var(--aadu9w3)':'var(--_1i72bi1q)'},fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1e)',lineHeight:'var(--_1i72bi13)'},h4:{vars:{'var(--aadu9w3)':'var(--_1i72bi1q)'},fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1f)',lineHeight:'var(--_1i72bi13)'},h5:{vars:{'var(--aadu9w3)':'var(--_1i72bi1q)'},fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1g)',lineHeight:'var(--_1i72bi13)'},h6:{vars:{'var(--aadu9w3)':'var(--_1i72bi1q)'},fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1h)',lineHeight:'var(--_1i72bi16)'},lede:{vars:{'var(--aadu9w3)':'var(--_1i72bi1m)'},fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi1j)',lineHeight:'var(--_1i72bi18)'},small:{fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1k)',lineHeight:'var(--_1i72bi17)'},large:{vars:{'var(--aadu9w3)':'var(--_1i72bi1m)'},fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi1i)',lineHeight:'var(--_1i72bi17)'},body:{vars:{'var(--aadu9w3)':'var(--_1i72bi1m)'},fontFamily:'var(--_1i72bi11)',fontSize:'var(--_1i72bi19)',lineHeight:'var(--_1i72bi18)'},bodyAlt:{vars:{'var(--aadu9w3)':'var(--_1i72bi1m)'},fontFamily:'var(--_1i72bi10)',fontSize:'var(--_1i72bi19)',lineHeight:'var(--_1i72bi18)'}};
export var withHtml = 'aadu9w5';
export var withLede = 'aadu9w4';