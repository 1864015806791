import { createField } from '@formie/parse/formieParser';
import { useFormField } from '@formie/useForm';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel } from '@primitives';
import { gql } from 'graphql-request';
import { FieldErrors } from './FieldErrors';

gql`
  fragment fieldAgree on Field_Agree {
    ...fieldBase
    uncheckedValue
    checkedValue
    inputTypeName
    description
    prePopulate
    defaultState
  }
`;

export const Field_Agree = createField<'Field_Agree'>(({ field, ..._props }) => {
  const {
    name,
    handle,
    defaultState,
    instructions,
    description,
    required,
    // checkedValue,
    labelPosition,
    // uncheckedValue,
  } = field;

  // use 1/0 string as value for conditional fields compatibility
  const checkedValue = '1';
  const uncheckedValue = '0';

  const { value, setValue, error, errors } = useFormField(
    handle,
    'single',
    defaultState ? checkedValue : uncheckedValue
  );

  const label =
    makeNonNullableArray(JSON.parse(description ?? ''))[0].content[0].text ?? name ?? '';

  const handleChange = (_ev: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setValue(checked ? checkedValue : uncheckedValue);
  };

  return (
    <FormControl required={required} error={error}>
      <FormLabel component="legend" sx={{ mb: 1 }} hidden={labelPosition === 'hidden'}>
        {name}
      </FormLabel>
      <FormControlLabel
        sx={{ mb: 1 }}
        {...{
          onChange: handleChange,
          label,
          control: <Checkbox checked={value === checkedValue} />,
        }}
      />
      <FormHelperText>{instructions}</FormHelperText>
      <FieldErrors errors={errors} />
    </FormControl>
  );
});
