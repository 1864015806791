import Container from '@componentPrimitives/Container';
import Grid from '@componentPrimitives/Grid';
import {
  createEntryIndexStore,
  EntryIndexContextProvider,
  EntryIndexKey,
  useEntryIndex,
  useEntryIndexQueryParams,
  useIndexData,
} from '@lib/features/entryIndexes';

import { combineComponentChildren } from '@componentPrimitives/Polymorphic';
import { useRouter } from 'next/router';
import React from 'react';
import { EntryIndexCategoryChips } from './EntryIndex.CategoryChips';
import { EntryIndexFeaturedEntry } from './EntryIndex.FeaturedEntry';
import { EntryIndexFilter } from './EntryIndex.Filter';
import { EntryIndexFilters } from './EntryIndex.Filters';
import { EntryIndexFiltersPopover } from './EntryIndex.FiltersPopover';
import { EntryIndexGrid } from './EntryIndex.Grid';
import EntryIndexPagination from './EntryIndex.Pagination';
import { EntryIndexScrollTop } from './EntryIndex.ScrollTop';
import { EntryIndexSort } from './EntryIndex.Sort';

export type EntryIndexProps = React.PropsWithChildren<{
  hideFeaturedEntry?: boolean;
  hideFilters?: boolean;
  hideSort?: boolean;
  indexKey: EntryIndexKey;
}>;

const EntryIndexInner = ({
  children,
  hideFeaturedEntry: _hideFeaturedEntry,
  hideFilters,
  hideSort,
}: // ...props
EntryIndexProps) => {
  const scrollTopRef = React.useRef<HTMLAnchorElement>(null);

  const showFilters = !hideFilters;
  const showSort = !hideSort;

  const {
    featuredEntry,
    isFiltered: _isFiltered,
    page: _page,
  } = useEntryIndex(({ isFiltered, featuredEntry, page }) => ({ isFiltered, featuredEntry, page }));
  const showFeaturedEntry = false; // !hideFeaturedEntry && !isFiltered && page === 1;

  return (
    <Container>
      <Grid cx={{ marginY: 'xl' }} rowGutter>
        {showSort && (
          <Grid.Col md={4}>
            <EntryIndexSort />
          </Grid.Col>
        )}

        {showFilters && (
          <Grid.Col md={8}>
            <EntryIndexFilter />
          </Grid.Col>
        )}
      </Grid>
      <EntryIndexScrollTop ref={scrollTopRef} />
      <EntryIndexFeaturedEntry item={featuredEntry} hidden={!showFeaturedEntry} />
      {/* {showFeaturedEntry && <EntryIndexFeaturedEntry />} */}
      <EntryIndexGrid hideFeatured={showFeaturedEntry} />

      {children}

      <EntryIndexPagination />
    </Container>
  );
};

/**
 * Trigger loading from query params
 */
const EntryIndexInit = () => {
  const r = useRouter();
  const initArgs = useEntryIndexQueryParams();
  const trigger = useEntryIndex((s) => s.trigger);

  React.useEffect(() => {
    if (!initArgs) return;

    trigger(initArgs);

    // Hide the ugly query string
    r.replace(r.asPath, r.route, { shallow: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initArgs]);

  return null;
};

const EntryIndexRoot = ({ children, ...props }: EntryIndexProps) => {
  const { indexKey } = props;
  const initialState = useIndexData(indexKey);

  return (
    <EntryIndexContextProvider
      key={indexKey}
      createStore={() =>
        createEntryIndexStore(indexKey, { ...initialState /* args: initArgs  */ })
      }>
      <>
        {children || <EntryIndexInner {...props} />}
        <EntryIndexInit />
      </>
    </EntryIndexContextProvider>
  );
};

const EntryIndex = combineComponentChildren(EntryIndexRoot, {
  Grid: EntryIndexGrid,
  Pagination: EntryIndexPagination,
  Sort: EntryIndexSort,
  Filter: EntryIndexFilter,
  Filters: EntryIndexFilters,
  FiltersPopover: EntryIndexFiltersPopover,
  ScrollTop: EntryIndexScrollTop,
  CategoryChips: EntryIndexCategoryChips,
});

export default EntryIndex;
