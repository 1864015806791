import { useRouter } from 'next/router';
import { useEffect } from 'react';

/** Fire a callback before each router change */
export const useRouterChangeStart = (fn: () => void) => {
  const { events: routerEvents } = useRouter();

  useEffect(() => {
    if (!routerEvents) return;

    routerEvents.off('routeChangeStart', fn);
    routerEvents.on('routeChangeStart', fn);
    return () => {
      /** Safely remove on unmount */
      routerEvents.off('routeChangeStart', fn);
    };
  }, [fn, routerEvents]);
};
