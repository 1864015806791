import {
  deleteUndefined,
  firstNonNullable,
  humanFileSize,
  makeNonNullableArray,
  toString,
  toStringOrNull,
} from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import {
  AssetFragment,
  EntryLinksFragment,
  LinkCtaMultipleFragment,
  LinkFieldFragment,
} from './_generated/linkField.generated';

gql`
  fragment asset on assetsVolume_Asset {
    __typename
    url
    id
    filename
    extension
    size
    title
  }

  fragment linkField on linkField_Link {
    __typename
    target
    text
    title
    type
    url
    customText
  }

  fragment linkCtaMultiple on linkCtaMultiple_MatrixField {
    ... on linkCtaMultiple_link_BlockType {
      __typename
      linkCta {
        ...linkField
      }
    }
  }

  fragment entryLinks on entryLinks_MatrixField {
    ... on entryLinks_link_BlockType {
      __typename
      linkCta {
        ...linkField
      }
    }
  }
`;

export type FileAsset = NonNullable<ReturnType<typeof parseDocument>>;

export interface Link extends Pick<IntrinsicProps<'a'>, 'target' | 'title'> {
  href: string;
  // text?: string;
  customText?: string;
  children?: string;
  download?: boolean;
  // file?: FileAsset;
}

export const parseDocument = (maybeElement?: MaybeArrayOf<Partial<AssetFragment>>) => {
  const doc = firstNonNullable(maybeElement);
  if (!doc) return null;
  const { size, ...rest } = doc;

  // const summary = toStringOrNull(maybeGet(doc, 'summary')) ?? undefined;
  const title = toStringOrNull(doc.title) ?? undefined;

  return deleteUndefined({ ...rest, title, /* summary, */ size, sizeHuman: humanFileSize(size) });
};

export const parseDocuments = (maybeElements?: MaybeArrayOf<AssetFragment>) => {
  return makeNonNullableArray(maybeElements).reduce((results, item) => {
    const asset = parseDocument(item);
    if (asset) results.push(asset);
    return results;
  }, [] as FileAsset[]);
};

// export const parseLinkFieldElementAsset = (maybeElement: Maybe<LinkFieldFragment['element']>) => {
//   if (!maybeElement || maybeElement.__typename !== 'documentsVolume_Asset') return null;
//   return parseAsset(maybeElement);
// };

export const parseLinkField = (data: MaybeArrayOf<LinkFieldFragment>): Link | null => {
  const link = firstNonNullable(data);

  if (!link || !link.url) return null;

  const { target, type, text, title, url: href, customText } = link;

  const download = type === 'asset';

  const field: Link = {
    href: toString(href),
    target: download ? '_blank' : toStringOrNull(target) ?? undefined,
    children: toStringOrNull(text) ?? undefined,
    customText: toStringOrNull(customText) ?? undefined,
    title: toStringOrNull(title) ?? undefined,
    download,
  };

  return deleteUndefined(field);
};

export const parseLinkFieldMultiple = (
  data: MaybeArrayOf<LinkCtaMultipleFragment | EntryLinksFragment>
) => makeNonNullableArray(makeNonNullableArray(data).map(({ linkCta }) => parseLinkField(linkCta)));
