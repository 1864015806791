import Container from '@componentPrimitives/Container';
import Grid from '@componentPrimitives/Grid';
import { createToggleStore } from '@lib/utils/createToggleStore';
import useViewportWidth from '@lib/utils/useViewportWidth';
import Box from '@primitive/Box';

import IconButton from '@components/IconButton';
import * as style from './TestGrid.css';

export const useDevMode = createToggleStore(false, 'devMode');

/**
 * Grid overlay or dev purposes
 */
const TestGrid = () => {
  const { value: isDevMode, toggle } = useDevMode();
  const [breakpointMin] = useViewportWidth();

  const nCols = breakpointMin('md') ? 12 : 6;

  const items = Array(nCols).fill('');

  const showGrid = isDevMode;

  return (
    <Box className={style.root}>
      {showGrid && (
        <Container className={style.gridContainer}>
          <Grid columns={nCols}>
            {items.map((v, i) => (
              <Grid.Col xs={1} key={i}>
                <Box className={style.gridItemInner} />
              </Grid.Col>
            ))}
          </Grid>
        </Container>
      )}
      <Box className={style.optionsContainer}>
        <IconButton
          icon="settings"
          size="small"
          variant="filled"
          color="primary"
          onClick={() => toggle()}
        />
      </Box>
    </Box>
  );
};

export default TestGrid;
