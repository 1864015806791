import Container from '@componentPrimitives/Container';
import Box from '@primitive/Box';
import Grid from '@componentPrimitives/Grid';
import Image from '@components/Image';
import Txt from '@primitive/Txt';
import { Slide } from '@primitives';
import { useTranslations } from 'next-intl';
import { useMegaMenuItem, useMegaMenuVariant } from './useMegaMenu';
import { MegaMenuSubMenu } from './MegaMenu.SubMenu';
import * as style from './MegaMenu.css';
import Btn from '@componentPrimitives/Btn';
import Flex from '@componentPrimitives/Flex';
import OpenHours from '@components/OpenHours';
import { useGlobal } from '@lib/store';

export type MegaMenuTopLevelItemProps = {
  itemId: ID;
};

const Inner = ({ itemId }: MegaMenuTopLevelItemProps) => {
  const { isDesktop } = useMegaMenuVariant();
  const t = useTranslations('common');
  const { item, toggleActive } = useMegaMenuItem(itemId);

  const { title, descriptionHtml, image, subItems } = item;
  const handleClose = () => toggleActive(false);
  const openHours = useGlobal('openHours');

  return (
    <Container>
      <Grid
        cx={{
          rowGap: 'sm',
          alignItems: 'stretch',
        }}>
        <Grid.Col lg={6}>
          <Txt variant="h3">{title}</Txt>
        </Grid.Col>
        {isDesktop && (
          <Grid.Col lg={6} cx={{ textAlign: 'right' }}>
            <Btn endIcon="close" variant="nav" onClick={handleClose}>
              {t('close')}
            </Btn>
          </Grid.Col>
        )}
        <Grid.Col lg={4}>
          <Txt as="div" html cx={{ marginBottom: 'md' }}>
            {descriptionHtml}
          </Txt>
          {image && (
            <Image ratio="landscapeWide" transform="landscapeSmCrop" alt={title} {...image} />
          )}
        </Grid.Col>
        <Grid.Col
          lg={6}
          offsetLg={2}
          cx={{ display: 'flex', flexDirection: 'column', justifyContent: 'between' }}>
          <MegaMenuSubMenu rootItem={item} items={subItems} />
          {isDesktop && (
            <Txt html as="div">
              {openHours?.content}
            </Txt>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export const MegaMenuTopLevelItem = ({ itemId }: MegaMenuTopLevelItemProps) => {
  const { isDesktop } = useMegaMenuVariant();
  const { active, toggleActive } = useMegaMenuItem(itemId);

  const handleBackClick = () => {
    toggleActive(false);
  };

  const t = useTranslations('common');

  if (isDesktop) {
    return active ? (
      <Box cx={{ paddingY: 'xl' }}>
        <Inner itemId={itemId} />
      </Box>
    ) : null;
  }

  return (
    <Slide in={active} direction="left">
      <Flex paper spacing="md" className={style.topLevelItemSlideWrap}>
        <Container>
          <Btn onClick={handleBackClick} variant="nav" startIcon="arrowLeft">
            {t('back')}
          </Btn>
        </Container>
        <Inner itemId={itemId} />
      </Flex>
    </Slide>
  );
};
