import type { PaletteMode } from '@mui/material';
import { createTheme as MuiCreateTheme } from '@mui/material/styles';
import { getThemeComponents } from './themeComponents';
import { getThemePalette } from './themePalette';
// import { themeTypography } from './themeTypography';

const createTheme = (mode: PaletteMode) => {
  let theme = MuiCreateTheme({
    palette: getThemePalette(mode),
    // typography: themeTypography,
  });

  // Apply component overrides after the rest of the theme is created
  theme = MuiCreateTheme(theme, { components: getThemeComponents(theme) });

  return theme;
};

const themeDark = createTheme('dark');
const themeLight = createTheme('light'); // default

export { themeLight as theme, themeDark, themeLight };
