import { Link } from '@lib/features/common';
import { Entry, isEntry } from '@lib/features/entries';
import { useGlobal, useView } from '@lib/store';
import { useNextHydrated } from '@lib/utils/createToggleStore';
import { fmtDateRange, fmtTimeRange } from '@lib/utils/format';
import { trimSlashes } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import { useIsRTC } from './useSite';

type EventEntryMeta = {
  /** Tickets are sold online */
  isTicketed: boolean;
  /** Included in admission */
  isFree: boolean;
  eventDateFormatted?: string;
  eventTicketString: string;
  eventTimesFormatted?: string[];
  buyTicketsLink?: Link;
  showTicketStr?: boolean;
  priceDescription?: string;
  dateDescription?: string;
  locationDescription?: string;
};

/** Apply further parsing for Event entries */
export const useEvent = <T extends Entry>(
  maybeItem?: T
): Partial<Entry<'event'> & EventEntryMeta> => {
  const t = useTranslations('event');
  const view = useView();
  const eventSettings = useGlobal('eventSettings');
  const bookingUrl = eventSettings?.bookingUrl;
  const vivaticketUrl = eventSettings?.vivaticketUrl;
  /** Avoid date rendering mismatch */
  const hydrated = useNextHydrated();
  const item = maybeItem ? maybeItem : view;

  const isRtc = useIsRTC();
  const showTicketStr = !isRtc;

  if (!isEntry(item, 'event')) return {};

  const { eventMeta, eventDateStart, eventDateEnd, isEverydayEvent } = item;

  const priceDescription = eventMeta?.priceDescription ?? undefined;
  const dateDescription = eventMeta?.dateDescription ?? undefined;
  const locationDescription = eventMeta?.locationDescription ?? undefined;

  const isTicketed = isEntry(item, 'event', 'ticketed');
  const isFree = isTicketed ? false : !!item.isFreeEvent;
  const eventTimes = !isTicketed ? item.eventTimes : null;

  const eventTimesFormatted =
    eventTimes?.reduce((result, item) => {
      if (!item) return result;

      const { start, end } = item;

      const formatted = fmtTimeRange(start, end, '-', { timeZoneName: 'short' });
      if (formatted) return [...result, formatted];

      return result;
    }, [] as string[]) ?? [];

  let buyTicketsUrl = bookingUrl ?? '';

  if (isTicketed) {
    buyTicketsUrl = `${trimSlashes(vivaticketUrl ?? '')}${item.vtCode}`;

    item.vtDescription;
    item.pageTitle = item.pageTitle || item.vtAltName || null;
    item.entrySummary = item.entrySummary || item.vtDescription || null;
  }

  const buyTicketsLink: Link = {
    href: buyTicketsUrl,
    children: isTicketed ? t('buyTicketsCta') : t('bookCta'),
  };

  const eventDateFormatted = hydrated
    ? isEverydayEvent
      ? t('everyday')
      : fmtDateRange(eventDateStart, eventDateEnd, ' - ')
    : '';

  const eventTicketString = !isFree ? t('ticketed') : t('notTicketed');

  return {
    ...item,
    isFree,
    isTicketed,
    eventDateFormatted,
    eventTicketString,
    eventTimesFormatted,
    buyTicketsLink,
    showTicketStr,
    priceDescription,
    dateDescription,
    locationDescription,
  };
};
