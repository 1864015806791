import Card from '@components/Card';
import { EntryCardProps } from './EntryCard';
import { fmtCategoryNames } from '@lib/utils/format';

export type ProfileCardProps = EntryCardProps<'profile'>;

export const ProfileCard = ({ item, ...props }: ProfileCardProps) => {
  const { uri: href, title, entryImage: image, profileCategory } = item;

  // const padding
  const profileCategoryName = fmtCategoryNames(profileCategory);
  return (
    <Card
      {...props}
      item={{
        href,
        title,
        meta: profileCategoryName,
        image,
      }}>
      <Card.Image ratio="landscape" transform="landscapeSmCrop" />
      <Card.Body cx={{ pX: 'xs', pT: 'md' }}>
        <Card.Title variant="h6" />
        <Card.Meta cx={{ mT: '3xs' }} />
      </Card.Body>
      <Card.Foot cx={{ pX: 'xs', pB: 'md', pT: 'md' }}>
        <Card.Cta variant="outlined" size="small" endIcon="arrowRight" />
      </Card.Foot>
    </Card>
  );
};
