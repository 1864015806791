import Link from '@component/Link';
import Box, { BoxProps } from '@primitive/Box';
import Txt, { TxtProps } from '@primitive/Txt';
import { NavMenuItem } from '@lib/features/navigation/navigation';
import { useNavMenu } from '@lib/store';
import { List, ListItem, ListProps } from '@primitives';

type NavMenuProps = ListProps<'ul', { items?: NavMenuItem[]; TxtProps?: TxtProps<'span'> }>;

const NavMenu = ({ items, TxtProps, ...props }: NavMenuProps) => {
  return items?.length ? (
    <List {...props}>
      {items.map((item) => (
        <NavMenuItem key={item.id} item={item} TxtProps={TxtProps} />
      ))}
    </List>
  ) : null;
};
type NavMenuItemProps = {
  item: NavMenuItem;
  TxtProps?: TxtProps<'span'>;
};

const NavMenuItem = ({ item, TxtProps }: NavMenuItemProps) => {
  const { href, title, subItems, depth } = item;

  const topLevel = depth === 0;

  const inner = (
    <Txt variant="bodyAlt" bold={topLevel} as="span" {...TxtProps}>
      {title}
    </Txt>
  );

  const linkItem = href ? <Link href={href}>{inner}</Link> : inner;

  return (
    <span>
      <ListItem disablePadding>{linkItem}</ListItem>
      {topLevel && <NavMenu items={subItems} />}
    </span>
  );
};

export const FooterNav = (props: BoxProps<'nav'>) => {
  const { items } = useNavMenu('footerNav') ?? {};

  /** If any menu items have sub items */
  const itemsHaveChildren = !!items?.find((v) => !!v.subItems?.length);

  return (
    <Box as="nav" {...props}>
      <NavMenu
        items={items}
        TxtProps={
          itemsHaveChildren
            ? {}
            : {
                bold: false,
              }
        }
        sx={{
          display: 'flex',
          ...(itemsHaveChildren
            ? {
                flexDirection: 'row',
                columnGap: 3,
                rowGap: 3,
              }
            : {
                flexDirection: 'column',
                // columnGap: 3,
                rowGap: 0.5,
              }),
        }}
      />
    </Box>
  );
};
