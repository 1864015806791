import React from 'react';
import Btn, { BtnComponent, BtnProps } from '@componentPrimitives/Btn';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'use-intl';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardCtaProps<D extends React.ElementType = 'button'> = BtnProps<D>;

export const CardCta: BtnComponent = <D extends React.ElementType>({
  children,
  ...props
}: CardCtaProps<D>) => {
  const t = useTranslations('cta');
  const { customText } = useCard();

  return (
    <Btn variant="text" underline="always" {...mergePropsClassName(props, style.cta)}>
      {children || customText || t('more')}
    </Btn>
  );
};
