import Txt from '@componentPrimitives/Txt';
import Card from '@components/Card';
import Icon from '@components/Icon';
import { useEvent, useIsRTC } from '@lib/hooks';
import { EntryCardProps } from './EntryCard';

export type EventCardProps = EntryCardProps<'event'>;

export const EventCard = ({ item, ...props }: EventCardProps) => {
  const {
    eventDateFormatted,
    isFree,
    eventTicketString,
    uri: href,
    title,
    entrySummary: description,
    entryImage: image,
    showTicketStr,
    priceDescription,
    dateDescription,
  } = useEvent(item);

  const eventMeta: string[] = [];

  if (eventDateFormatted) eventMeta.push(eventDateFormatted);

  const isRtc = useIsRTC();

  return (
    <Card
      {...props}
      item={{
        href,
        title,
        meta: eventMeta,
        description,
        image,
      }}>
      <Card.Image ratio="landscape" transform="landscapeSmCrop" />
      <Card.Body cx={{ pX: 'xs', pT: 'md' }}>
        {isRtc ? (
          // The RTC version
          <>
            <Card.Meta bold cx={{ mB: '3xs' }} />
            <Card.Title variant="h6" cx={{ mB: '3xs' }} />
            <Card.Meta
              cx={{
                mY: '3xs',
              }}>
              {dateDescription ?? false}
            </Card.Meta>
            <Card.Description
              maxChars={100}
              cx={{
                mY: '3xs',
              }}
            />
            <Card.Meta
              cx={{
                mT: '3xs',
                mB: 'xs',
              }}>
              {priceDescription ?? false}
            </Card.Meta>
          </>
        ) : (
          // The default version
          <>
            <Card.Title variant="h6" cx={{ mB: '3xs' }} />
            {showTicketStr && (
              <Txt variant="small" color={isFree ? 'primary' : 'error'}>
                <Icon name="ticket" /> {eventTicketString}
              </Txt>
            )}
            <Card.Meta cx={{ mT: '3xs' }} />
            <Card.Description maxChars={100} cx={{ mY: 'xs' }} />
          </>
        )}
      </Card.Body>
      <Card.Foot cx={{ pX: 'xs', pB: 'md' }}>
        <Card.Cta variant="outlined" size="small" endIcon="arrowRight" />
      </Card.Foot>
    </Card>
  );
};
