import NavBarDrawer from '@components/NavBarDrawer';
import { useRouterChangeStart } from '@lib/utils/useRouter';
import { MegaMenuTopLevelItem } from './MegaMenu.TopLevelItem';
import { MegaMenuTopLevelDrawer } from './MegaMenu.TopLevelDrawer';
import { MegaMenuSubLevelDrawer } from './MegaMenu.SubLevelDrawer';
import { MegaMenuTopLevel } from './MegaMenu.TopLevel';
import { useMegaMenu, useMegaMenuVariant } from './useMegaMenu';
import * as style from './MegaMenu.css';
import { combineComponentChildren } from '@componentPrimitives/Polymorphic';
import { MegaMenuHamburger } from './MegaMenu.Hamburger';
import { MegaMenuSubMenu } from './MegaMenu.SubMenu';
import { MegaMenuSubMenuItem } from './MegaMenu.SubMenuItem';
import { MegaMenuTopLevelButton } from './MegaMenu.TopLevelButton';

// export type MegaMenuProps = {
//   /**/
// };

export const MegaMenuRoot = () => {
  const ids = useMegaMenu((s) => s.ids);
  const open = useMegaMenu((s) => !!s.activeItem);
  const close = useMegaMenu((s) => () => s.setActiveItem(null));
  const { isDesktop } = useMegaMenuVariant();

  // Close the menu on navigation
  useRouterChangeStart(close);

  return (
    <>
      {isDesktop ? (
        <>
          <MegaMenuTopLevel direction="row" spacing="xs" />

          <NavBarDrawer
            open={open}
            onClose={() => close()}
            keepMounted
            PaperProps={{ className: style.itemPaper }}>
            {ids.map((id) => (
              <MegaMenuTopLevelItem key={id} itemId={id} />
            ))}
          </NavBarDrawer>
        </>
      ) : (
        <MegaMenuTopLevelDrawer />
      )}
    </>
  );
};

const MegaMenu = combineComponentChildren(MegaMenuRoot, {
  Hamburger: MegaMenuHamburger,
  SubMenu: MegaMenuSubMenu,
  SubMenuItem: MegaMenuSubMenuItem,
  TopLevel: MegaMenuTopLevel,
  TopLevelDrawer: MegaMenuTopLevelDrawer,
  SubLevelDrawer: MegaMenuSubLevelDrawer,
  TopLevelButton: MegaMenuTopLevelButton,
  TopLevelItem: MegaMenuTopLevelItem,
});

export default MegaMenu;
