// import createStore from 'zustand';
import { CategoryFragment } from '@gql/_generated/categories.generated';
import { EntriesFragment } from '@gql/_generated/entries.generated';
import { ENTRIES_PER_PAGE } from '@lib/constants';
import client from '@lib/fetch/client';
import { withSite } from '@lib/hooks';
import { parseApiError } from '@lib/parse/parseApiError';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import { Category, CategoryTypeId, parseCategories } from '../categories/categories';

import { Entry, EntrySectionId, parseEntries, parseEntry } from '../entries';
import {
  getOrderByDefault,
  OrderByQueryString,
  ORDER_BY_FIELD_ENUM,
  ORDER_BY_ORDER_ENUM,
} from './orderBy';
import { EntryIndexQueryVariables, getSdk } from './_generated/getEntryIndexData.generated';

gql`
  query entryIndex(
    $site: [String] = "default"
    $query: String
    $includeCategories: Boolean = false
    $entryLimit: Int = 12
    $entryOffset: Int = 0
    $idQuery: [QueryArgument]
    $categoryLimit: Int = 99
    $entrySection: [String]
    $categoryType: [String]
    $entryCategoryIds: [QueryArgument]
    $entryCategoryQuery: [CategoryCriteriaInput]
    $entryOrderBy: String
    $categoryOrderBy: String
    # Event only queries
    $eventDateStart: [QueryArgument] = null
    $eventDateEnd: [QueryArgument] = null
  ) {
    entries: entries(
      site: $site
      id: $idQuery
      search: $query
      section: $entrySection
      relatedTo: $entryCategoryIds
      relatedToCategories: $entryCategoryQuery
      limit: $entryLimit
      offset: $entryOffset
      orderBy: $entryOrderBy
      eventDateStart: $eventDateStart
      eventDateEnd: $eventDateEnd
    ) {
      ...entryCard
    }

    featuredEntry: entry(
      # isFeatured: true
      site: $site
      search: $query
      section: $entrySection
      relatedTo: $entryCategoryIds
      relatedToCategories: $entryCategoryQuery
      orderBy: $entryOrderBy
    ) {
      ...entryCard
    }

    categories: categories(
      site: $site
      search: $query
      group: $categoryType
      limit: $categoryLimit
      orderBy: $categoryOrderBy
    ) @include(if: $includeCategories) {
      ...category
    }

    entryCount(
      site: $site
      search: $query
      section: $entrySection
      relatedTo: $entryCategoryIds
      relatedToCategories: $entryCategoryQuery
      eventDateStart: $eventDateStart
      eventDateEnd: $eventDateEnd
      id: $idQuery
    )
  }
`;

export interface PaginationQuery {
  /** The current page for paginated results */
  page?: number;
  /** The number of results per page */
  perPage?: number;
}

export type EntryIndexQueryProps<
  E extends EntrySectionId,
  C extends CategoryTypeId
> = PaginationQuery & {
  query?: string;
  /** The "section" of the entries to include */
  entrySection?: E | E[];
  /** The "groups" of the categories to include */
  categoryType?: C | C[];
  /** The "ids" of categories to which entries should be related */
  categoryIds?: ID[];
  /** The "ids" of categories to which entries should be related, grouped by category handle */
  categorySelection?: Record<string, ID[]>;
  /** Query for category results */
  includeCategories?: boolean;
  /** The order of results */
  orderBy?: OrderByQueryString;
};

export type EntryIndexQueryPropsState<
  E extends EntrySectionId,
  C extends CategoryTypeId
> = EntryIndexQueryProps<E, C> & {
  entrySection?: E[];
  /** The "groups" of the categories to include */
  categoryType?: C[];
};

export type EntryIndexQueryResult<E extends EntrySectionId, C extends CategoryTypeId> = {
  args: EntryIndexQueryProps<E, C>;
  customVariables?: EntryIndexQueryVariables;
  entries: Entry<E>[];
  featuredEntry: Maybe<Entry<E>>;
  categories: { [P in C]?: Category<C>[] };
  entryCount: number;
  /** The current page */
  page: number;
  /** Total number of pages available */
  pages: number;
};

export const parseEntryIndexArgs = <E extends EntrySectionId, C extends CategoryTypeId>(
  maybeArgs: Partial<EntryIndexQueryProps<E, C>>
) => {
  const { categorySelection = {} } = maybeArgs;

  const page = Math.max(maybeArgs.page ?? 1, 1);
  const entryLimit = Math.abs(maybeArgs.perPage ?? ENTRIES_PER_PAGE);
  // const excludeIds = maybeArgs.
  const entrySection: E[] = makeNonNullableArray(maybeArgs.entrySection);
  const categoryType: C[] = makeNonNullableArray(maybeArgs.categoryType);

  const args: EntryIndexQueryPropsState<E, C> = {
    includeCategories: true,
    orderBy: getOrderByDefault(),
    ...maybeArgs,
    categoryType,
    entrySection,
    perPage: entryLimit,
    page,
  };

  return args;
};

export const getEntryIndexData = async <E extends EntrySectionId, C extends CategoryTypeId>(
  maybeArgs: EntryIndexQueryProps<E, C>,
  customVariables?: EntryIndexQueryVariables
) => {
  const args = parseEntryIndexArgs(maybeArgs);

  const {
    entrySection,
    categoryType,
    query,
    orderBy,
    categoryIds,
    includeCategories,
    page: maybePage,
    perPage: entryLimit,
  } = args;

  // console.log({ includeCategories });

  // ----------------------------------------------------------------------------------------------
  // ---- Calculate pagination ----
  const page = maybePage || 1;
  const entryOffset = page > 1 ? (page - 1) * (entryLimit ?? ENTRIES_PER_PAGE) : 0;

  // ----------------------------------------------------------------------------------------------
  // ---- Build the query ----
  // const {} = args;

  const isSearch = !!query;

  const variables: EntryIndexQueryVariables = {
    entryLimit,
    entryOffset,
    entrySection,
    categoryType,
    entryCategoryIds: categoryIds ? categoryIds : undefined,
    includeCategories: includeCategories || !categoryType,
    entryOrderBy: orderBy,
    // categoryOrderBy: orderBy,
    ...customVariables,
  };

  // console.log(variables);

  if (isSearch) {
    const searchOrderBy = `${ORDER_BY_FIELD_ENUM.SEARCH_SCORE} ${ORDER_BY_ORDER_ENUM.DESC}`;
    variables.query = query;
    variables.entryOrderBy = orderBy ?? searchOrderBy;
    variables.categoryOrderBy = orderBy ?? searchOrderBy;
  }

  const sdk = getSdk(client);

  const response = await sdk.entryIndex(withSite(variables)).catch((err) => {
    console.warn(parseApiError(err));
  });

  const {
    entries: maybeEntries,
    categories: maybeCategories,
    featuredEntry: maybeFeaturedEntry,
    entryCount = 0,
  } = response ?? {};

  // ----------------------------------------------------------------------------------------------
  // ---- Parse the entries ----

  /*
  TODO: the following functions can be restricted by '__typename', but in reality we
  shouldn't have any incorrect types by this point - so we're just using a type assertion.
  */
  const entries = parseEntries(
    maybeEntries as EntriesFragment[] /* , entrySection */
  ) as Entry<E>[];
  const featuredEntry = parseEntry(
    maybeFeaturedEntry as EntriesFragment /* , entrySection */
  ) as Maybe<Entry<E>>;

  // ----------------------------------------------------------------------------------------------
  // ---- Split the categories and parse ----

  const categories = (categoryType ?? []).reduce((results, catId) => {
    return {
      ...results,
      [catId]: parseCategories(maybeCategories as CategoryFragment[], catId),
    };
  }, {} as { [P in C]: Category<C>[] });

  const result: EntryIndexQueryResult<E, C> = {
    args,
    entries,
    featuredEntry,
    categories,
    entryCount,
    page,
    pages: entryLimit ? Math.ceil(entryCount / entryLimit) : 1,
  };

  return result;
};
