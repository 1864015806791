import { mapObject } from '@liquorice/allsorts-craftcms-nextjs';
import defaultMessages from '@lib/l10n/default';

export const localeDefault = 'en';

const createTranslations = <T extends Record<string, RecursivePartial<Messages>>>(partials: T) => {
  return mapObject(partials, (maybeStrings) => {
    return { ...maybeStrings, ...defaultMessages };
  }) as { [P in keyof T]: Messages };
};

const appStrings = createTranslations({
  [localeDefault]: defaultMessages,
});

export type AppLocale = keyof typeof appStrings;

export const getDefaultMessages = () => defaultMessages;

export const isAppLocale = (x?: string): x is AppLocale => {
  return !!x && x in appStrings;
};

export const getMessages = async (locale?: AppLocale | string): Promise<Messages> => {
  return isAppLocale(locale) ? appStrings[locale] : appStrings[localeDefault];
};
