import Card from '@components/Card';
import { EntryCardProps } from './EntryCard';

export type ArticleCardProps = EntryCardProps<'article'>;

export const ArticleCard = ({ item, ...props }: ArticleCardProps) => {
  const { uri: href, title, entrySummary: description, entryImage: image, postDate } = item;

  // const padding

  return (
    <Card
      {...props}
      item={{
        href,
        title,
        meta: postDate,
        description,
        image,
      }}>
      <Card.Image ratio="landscape" transform="landscapeSmCrop" />
      <Card.Body cx={{ pX: 'xs', pT: 'md' }}>
        <Card.Title variant="h6" />
        <Card.Meta cx={{ mT: '3xs' }} />
        <Card.Description maxChars={100} cx={{ mY: 'xs' }} />
      </Card.Body>
      <Card.Foot cx={{ pX: 'xs', pB: 'md' }}>
        <Card.Cta variant="outlined" size="small" endIcon="arrowRight" />
      </Card.Foot>
    </Card>
  );
};
