import { Theme, ThemeOptions } from '@mui/material';
// import { fontFaceRules } from './partials/typography.css';

export const getThemeComponents = (_theme?: Theme): ThemeOptions['components'] => ({
  /*
  MuiButton: {
    defaultProps: {
      variant: 'contained',
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const { typography } = theme;
        const { size, color = 'inherit' } = ownerState;

        switch (color) {
          case 'primary':
            break;

          default:
            break;
        }

        // const a = color in palette ? palette[color as keyof typeof palette] : palette.primary;

        // const colorMain = color in palette ? palette[color].main : 'white';

        return {
          borderRadius: 999,
          ...(size === 'large' && {
            ...typography.buttonLg,
          }),
        };
        //     return ({
        //     ...(ownerState.variant === 'contained' && {
        //       ':hover': {
        //         backgroundColor: theme.palette.,
        //       },
        //     }),
        //   }),
        // },}
      },
    },
  },
  */
  MuiFormControl: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
      fullWidth: true,
    },
  },
  MuiLink: {
    defaultProps: {
      color: 'inherit',
      underline: 'hover',
    },
  },
  // MuiTypography: {
  //   defaultProps: {
  //     variant: 'body',
  //     variantMapping: {
  //       xl: 'span',
  //       lede: 'p',
  //       small: 'span',
  //       large: 'span',
  //       buttonLg: 'button',
  //       body: 'p',
  //       bodyAlt: 'p',
  //       // Built-in
  //       h1: 'h1',
  //       h2: 'h2',
  //       h3: 'h3',
  //       h4: 'h4',
  //       h5: 'h5',
  //       h6: 'h6',
  //       button: 'button',
  //     },
  //   },
  // },
});
