/* eslint-disable @typescript-eslint/no-explicit-any */
import { CategoryFragment } from '@gql/_generated/categories.generated';
import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';

import * as Typed from '@liquorice/allsorts-craftcms-nextjs/lib/sanitiser/sanitiserTypes';
import { sanitiseAnything, SanitisedElement } from '../../parse/sanitiseElements';

/**
 * Union of top level "Categories" defined with a '__typename'
 */
export type RawCategories = CategoryFragment;

/**
 * __typename of Category
 */
type CategoryTypename = Typed.Typename<RawCategories>;

// ----------------------------------------------------------------------------------------------------
// --- Extracted Type Ids (group handles) ---

export type CategoryTypeId<T extends CategoryTypename | string = CategoryTypename> =
  T extends CategoryTypename ? (T extends `${infer R}_Category` ? R : never) : never;

export type CategoryTypenameFromTypeId<T extends CategoryTypeId> = Extract<
  CategoryTypename,
  `${T}_Category`
>;

export const extractCategoryTypenameTypeId = <T extends CategoryTypename>(name: T) => {
  const re = /(.+)_Category/;
  const result = re.exec(name) ?? [];

  return result[0] ? (result[1] as CategoryTypeId<T>) : null;
};

export const makeCategoryTypenameFromTypeId = <T extends CategoryTypeId>(name: T) => {
  return `${name}_Category` as CategoryTypenameFromTypeId<T>;
};

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

export type SanitisedCategory<T extends CategoryTypename = CategoryTypename> = SanitisedElement<T>;

// ----------------------------------------------------------------------------------------------------

export const sanitiseCategory = <
  T extends CategoryFragment,
  Name extends CategoryTypename = Typed.Typename<T>
>(
  maybeCategory?: T | null,
  typeNames?: MaybeArrayOf<Name>
) =>
  maybeCategory
    ? (sanitiseAnything.one(maybeCategory, typeNames) as SanitisedCategory<Name>)
    : null;

export const parseSanitisedCategory = <T extends CategoryTypename>(
  sanitisedCategory: SanitisedCategory<T> | null
) => {
  if (!sanitisedCategory) return null;
  // ...more parsing here
  return sanitisedCategory;
};

export const parseCategories = <
  T extends CategoryFragment,
  Name extends CategoryTypename = Typed.Typename<T>,
  TypeId extends CategoryTypeId = CategoryTypeId<Name>
>(
  maybeCategories: MaybeArrayOf<T>,
  typeIds?: MaybeArrayOf<TypeId>
) => {
  const rawCategoriesArr = makeNonNullableArray(maybeCategories);
  const parsedCategories = rawCategoriesArr.map((e) => parseCategory(e, typeIds));
  return makeNonNullableArray(parsedCategories);
};

export const parseCategory = <
  T extends CategoryFragment,
  Name extends CategoryTypename = Typed.Typename<T>,
  TypeId extends CategoryTypeId = CategoryTypeId<Name>
>(
  maybeCategory?: T | null,
  typeIds?: MaybeArrayOf<TypeId>
) => {
  const typeNames = (makeNonNullableArray(typeIds) as CategoryTypeId[]).map(
    makeCategoryTypenameFromTypeId
  ) as Name[];
  const sanitisedCategory = sanitiseCategory(
    maybeCategory,
    typeNames.length ? typeNames : undefined
  );
  return sanitisedCategory ? parseSanitisedCategory<Name>(sanitisedCategory) : null;
};

export type Category<T extends CategoryTypeId = CategoryTypeId> = Exact<
  Typed.ExtractByTypename<ReturnType<typeof parseCategory>, CategoryTypenameFromTypeId<T>>
>;

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

export const isCategoryType = <T extends CategoryTypeId>(x: any, typeId: T): x is Category<T> => {
  return !!x && x.__typename === makeCategoryTypenameFromTypeId(typeId);
};
