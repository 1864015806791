import Box from '@componentPrimitives/Box/Box';
import CardGrid, { CardGridProps } from '@components/CardGrid';
import { Entry } from '@lib/features/entries';
import { useEntryIndex } from '@lib/features/entryIndexes';
import Txt from '@primitive/Txt';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { EntryCard } from './EntryCard';

const NoResults = () => {
  const t = useTranslations('common');

  return (
    // <Container>
    <Box rounded colorSet="secondary" elevation paper cx={{ p: 'xl' }}>
      <Txt variant="large" as="span">
        {t('noResults')}
      </Txt>
    </Box>
    // </Container>
  );
};

export type EntryIndexGridProps<T extends Entry> = {
  CardGridProps?: Omit<CardGridProps<T>, 'items'>;
  hideFeatured?: boolean;
};

export const EntryIndexGrid = <T extends Entry>({
  hideFeatured,
  CardGridProps,
}: // ...props
EntryIndexGridProps<T>) => {
  const isLoading = useEntryIndex((s) => s.isLoading);
  const entriesRaw = useEntryIndex((s) => s.entries ?? []) ?? [];
  const featuredEntry = useEntryIndex(({ featuredEntry }) => featuredEntry);
  const entries =
    hideFeatured && featuredEntry
      ? entriesRaw.filter((item) => item.id !== featuredEntry.id)
      : entriesRaw;

  return (
    <AnimatePresence>
      {entries.length ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isLoading ? 0.2 : 1 }}
          exit={{ opacity: 0 }}>
          <CardGrid
            {...({
              ...(CardGridProps ?? {
                sm: 6,
                md: 4,
                lg: 3,
                Component: EntryCard,
              }),
              items: entries,
            } as CardGridProps<T>)}
          />
        </motion.div>
      ) : (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <NoResults />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
