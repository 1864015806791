import Box from '@primitive/Box';
import Grid from '@componentPrimitives/Grid';
import Image from '@component/Image';
import Btn from '@componentPrimitives/Btn';
import Txt from '@primitive/Txt';
import { Entry } from '@lib/features/entries';

import { parseHref } from '@liquorice/allsorts-craftcms-nextjs';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';
import * as styles from './EntryIndex.css';

export type EntryIndexFeaturedEntryProps = {
  item: Maybe<Entry>;
  hidden?: boolean;
};

export const EntryIndexFeaturedEntry = ({ item, hidden }: EntryIndexFeaturedEntryProps) => {
  // const image = useDefaultImage(item?.image);
  const image = item?.entryImage;
  const t = useTranslations();

  if (!item) return <></>;
  const { title, postDate: date, entrySummary: summary, uri } = item ?? {};

  const href = parseHref(uri).href;
  const hasLink = !!href;
  // const show = !isFiltered && page === 1;
  const show = !hidden;

  const output = (
    <Box className={styles.featuredEntry} as="article" rounded>
      <Grid noGutter>
        <Grid.Col lg={6} className={styles.featuredEntryImageCol}>
          <Image
            alt=""
            ratio="landscape"
            // transform={{ xs: 'landscapeSmCrop', md: 'landscapeLgCrop' }}
            {...image}
            ShimProps={{ className: styles.featuredEntryImageWrap }}
          />
        </Grid.Col>
        <Grid.Col lg={6}>
          <Box className={styles.featuredEntryContentWrap}>
            {date && (
              <Txt variant="h6" color="primary">
                {date}
              </Txt>
            )}

            <Txt variant="h3" bold as="h2" color="primary">
              {title}
            </Txt>

            <Txt variant="large" maxChars={180}>
              {summary}
            </Txt>

            {hasLink && <Btn href={href}>{t('cta.more')}</Btn>}
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );

  const inner = hasLink ? <NextLink href={href}>{output}</NextLink> : output;

  return (
    <AnimatePresence>
      {show && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {inner}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
