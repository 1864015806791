import Box, { BoxProps } from '@componentPrimitives/Box';
import Btn, { BtnProps } from '@componentPrimitives/Btn';

import { useNavMenu } from '@lib/store';

export type CtaNavProps = BoxProps & {
  BtnProps?: Partial<BtnProps<'a'>>;
};

export const CtaNav = ({ BtnProps, ...props }: CtaNavProps) => {
  const { items } = useNavMenu('ctaNav') ?? {};

  return items?.length ? (
    <Box {...props}>
      {items.map((item) => (
        <Btn
          key={item.id}
          {...{
            variant: 'filled',
            ...BtnProps,
            ...(item.href && {
              href: item.href,
              target: item.newWindow ? '_blank' : '_self',
            }),
          }}>
          {item.title}
        </Btn>
      ))}
    </Box>
  ) : null;
};
