import { useNavbarState } from '@component/NavBar';
import React, { useCallback } from 'react';
import { SelectElementInput, selectHTMLElement } from './selectHTMLElement';

/**
 * Retrieve a callback to scroll to the position of the HTMLElement
 */
export const useScrollToElement = () => {
  const scrollOffset = useNavbarState((s) => s.height) ?? 0;

  const scrollToElement = useCallback(
    (ref?: SelectElementInput) => {
      const target = selectHTMLElement(ref);

      if (!target) return true;

      const targetY = target.offsetTop - scrollOffset;

      window.scrollTo({ top: targetY, behavior: 'smooth' });
    },
    [scrollOffset]
  );

  return scrollToElement;
};

export type EntryIndexLoaderProps = ScrollTopProps & {
  id?: string;
};

export type ScrollTopProps = {
  defaultTop?: number;
  margin?: number;
  scrollDown?: boolean;
  behavior?: ScrollBehavior;
};

export const useScrollToTop = (ref: React.RefObject<HTMLElement>, props?: ScrollTopProps) => {
  const defaultMargin = useNavbarState((s) => s.height) ?? 0;

  const scrollToTop = useCallback(
    (singleProps?: ScrollTopProps, options?: ScrollToOptions) => {
      const {
        defaultTop,
        margin = defaultMargin,
        scrollDown = false,
        behavior = 'smooth',
      } = { ...props, ...singleProps };

      const top = ref.current?.offsetTop ?? defaultTop ?? null;
      const current = window.scrollY ?? 0;
      // console.log({ margin });

      if (top === null || (!scrollDown && current < top)) return;

      window.scrollTo({ top: top - margin, behavior, ...options });
    },
    [defaultMargin, props, ref]
  );

  return scrollToTop;
};
