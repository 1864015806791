/* eslint-disable @typescript-eslint/no-explicit-any */
import forIn from 'lodash/forIn';
import { FormErrors } from '@formie/useForm';
import { parseApiError } from '@lib/parse/parseApiError';

export const parseFormSubmissionErrors = (maybeError: any): FormErrors | null => {
  try {
    const err = parseApiError(maybeError);
    if (!err) return null;
    const {
      response: { /* data, */ errors = [] /* , status */ },
    } = err;

    return errors.reduce((results, item) => {
      const { message } = item;
      const parsedMessage = JSON.parse(message) as FormErrors;

      forIn(parsedMessage, (fieldErrors, fieldKey) => {
        results[fieldKey] = ((fieldKey in results && results[fieldKey]) || []).concat(
          fieldErrors || []
        );
      });

      return results;
    }, {} as FormErrors);
  } catch (error) {
    console.warn(error);
  }

  return null;
};
