import classNames from 'classnames';
import * as style from './Txt.css';

export const useHtmlProps = <
  C extends React.ElementType,
  P extends React.ComponentPropsWithoutRef<C>
>(
  props: P,
  html?: boolean
) => {
  const { children, ...rest } = props;
  const className = classNames(style.withHtml, props.className);
  return {
    // Append original props
    ...rest,
    ...(html && typeof children === 'string'
      ? {
          // Set the content as HTML
          className,
          dangerouslySetInnerHTML: { __html: children },
        }
      : {
          // Set the content normally
          children,
        }),
  } as P;
};
