import Txt from '@primitive/Txt';
import { useGlobal } from '@lib/store';
import { useTranslations } from 'next-intl';
import React from 'react';

export const Copyright = () => {
  const copyright = useGlobal('copyright');
  const t = useTranslations('common');
  return (
    <Txt variant="small">
      {`${t('copyrightSymbol')} ${copyright?.label ?? t('siteName')} ${new Date().getFullYear()}`}
    </Txt>
  );
};
