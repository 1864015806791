import type { LogoSvgProps } from './Logo';

export const LogoSvg_Primary = (props: LogoSvgProps) => (
  <svg {...props} viewBox="0 0 340.2 107.7" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M333.7 101.3H238V6.4h95.7v94.9zm-102.2 6.4h108.7V0H231.5v107.7z"></path>
    <path
      fill="currentColor"
      d="M265.4 71.4c-2.8 0-6.3-2.1-7.4-8l-9.4 2c2.5 9.7 8.5 13.8 16.2 13.8 10 0 15.8-5.6 15.8-14.1 0-15.4-21.2-16-21.2-23.4 0-3.3 1.9-5.4 5.2-5.4 3.3 0 5.4 2 7 6.6l8.5-2.5c-1.7-7-6.8-11.9-15.3-11.9-9.8 0-14.9 5.3-14.9 13.8 0 13.8 21.2 13.9 21.2 23.2.2 3.7-2.4 5.9-5.7 5.9m30.8-14.7h13.4V78h9.5V28.7h-9.5v19.7h-8.9l-4.5 6.2V28.7h-9.5V78h9.5V56.7zM0 0v51.6h218.5V0H0zm20.3 42.6c-5.2 0-9.2-2.8-10.9-9.3l6.3-1.3c.7 4 3.1 5.4 5 5.4 2.2 0 3.9-1.5 3.9-3.9 0-6.2-14.3-6.3-14.3-15.6 0-5.7 3.4-9.3 10.1-9.3 5.7 0 9.2 3.4 10.3 8L25 18.3c-1.1-3.1-2.5-4.5-4.7-4.5-2.3 0-3.5 1.4-3.5 3.6 0 5 14.3 5.4 14.3 15.7-.1 5.8-4.1 9.5-10.8 9.5zm35-11.1c0 8.2-4.4 11.1-10.6 11.1s-10.6-2.9-10.6-11.1V19.7c0-8.2 4.4-11.1 10.6-11.1s10.6 2.9 10.6 11.1v11.8zm16.6 10.7h-7.4L56 9h6.3l7.2 29.1.5-12.4L74.1 9h6.3l-8.5 33.2zm28.8 0h-18V9h17.5v5.6H89.1v7.6h8.5v5.6h-8.5v8.7h11.6v5.7zm19.1 0l-5.6-13.5h-3.1v13.5h-6.4V9h9.3c7.7 0 11.3 3.7 11.3 9.9 0 4.1-1.5 7.1-5 8.4l6.3 14.8-6.8.1zm28 0h-18V9h17.5v5.6h-11.1v7.6h8.5v5.6h-8.5v8.7h11.6v5.7zm10.4 0h-6.4V9h6.4v33.2zm15.4-5.2c2.4 0 3.9-1.7 3.9-4.6v-2H173v-5.2h10.9v17h-3.3l-1-2.8c-1.7 1.9-3.6 3.2-6 3.2-6.2 0-10.6-2.9-10.6-11.1V19.7c0-8.2 4.4-11.1 10.6-11.1 6.5 0 10.1 3.7 10.3 11.2h-6.4c0-4.3-1.6-5.6-3.9-5.6-2.5 0-4.2 1-4.2 4.7v13.5c0 3.6 1.6 4.6 4.2 4.6zm36.6 5.2h-6.1l-9.8-21.4h-.1v21.4h-5.8V9h7l12.2 27.4-3.3-12V9h5.8v33.2h.1z"></path>
    <path
      fill="currentColor"
      d="M44.7 14.2c-2.5 0-4.2 1-4.2 4.7v13.5c0 3.7 1.6 4.7 4.2 4.7 2.5 0 4.2-1 4.2-4.7V18.9c0-3.7-1.6-4.7-4.2-4.7zm69.8.1h-3.3v9.2h3.3c2.8 0 4.5-1.4 4.5-4.6s-1.7-4.6-4.5-4.6zM127 56.1v51.6h91.5V56.1H127zm33 42.4h-6.4V84.2h-9v14.3h-6.4V65.3h6.4v17.4l3-4.2h6V65.3h6.4v33.2zm10.8 0h-6.4V65.3h6.4v33.2zm21 0h-16.7V65.3h6.4v27.5h10.3v5.7zm18.8 0h-16.7V65.3h6.4V97l3-4.1h7.3v5.6z"></path>
    <path
      fill="currentColor"
      d="M127 56.1v51.6h91.5V56.1H127zm33 42.4h-6.4V84.2h-9v14.3h-6.4V65.3h6.4v17.4l3-4.2h6V65.3h6.4v33.2zm10.8 0h-6.4V65.3h6.4v33.2zm21 0h-16.7V65.3h6.4v27.5h10.3v5.7zm18.8 0h-16.7V65.3h6.4V97l3-4.1h7.3v5.6z"></path>
  </svg>
);
