import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type ImageSize_OriginalFragment = { __typename?: 'assetsVolume_Asset', original_url?: string | null, original_height?: number | null, original_width?: number | null };

export type ImageSize_BannerCropFragment = { __typename?: 'assetsVolume_Asset', bannerCrop_url?: string | null, bannerCrop_width?: number | null, bannerCrop_height?: number | null };

export type ImageSize_FullFitFragment = { __typename?: 'assetsVolume_Asset', fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null };

export type ImageSize_LandscapeLgCropFragment = { __typename?: 'assetsVolume_Asset', landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null };

export type ImageSize_LandscapeMdCropFragment = { __typename?: 'assetsVolume_Asset', landscapeMdCrop_url?: string | null, landscapeMdCrop_width?: number | null, landscapeMdCrop_height?: number | null };

export type ImageSize_LandscapeSmCropFragment = { __typename?: 'assetsVolume_Asset', landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null };

export type ImageSize_SquareMdFitFragment = { __typename?: 'assetsVolume_Asset', squareMdFit_url?: string | null, squareMdFit_width?: number | null, squareMdFit_height?: number | null };

export type ImageSize_SquareSmFitFragment = { __typename?: 'assetsVolume_Asset', squareSmFit_url?: string | null, squareSmFit_width?: number | null, squareSmFit_height?: number | null };

export type ImageSize_SquareLgCropFragment = { __typename?: 'assetsVolume_Asset', squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null };

export type ImageSize_SquareMdCropFragment = { __typename?: 'assetsVolume_Asset', squareMdCrop_url?: string | null, squareMdCrop_width?: number | null, squareMdCrop_height?: number | null };

export type ImageSize_SquareSmCropFragment = { __typename?: 'assetsVolume_Asset', squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null };

export type ImageSize_AllFragment = { __typename?: 'assetsVolume_Asset', original_url?: string | null, original_height?: number | null, original_width?: number | null, bannerCrop_url?: string | null, bannerCrop_width?: number | null, bannerCrop_height?: number | null, fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null, landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null, landscapeMdCrop_url?: string | null, landscapeMdCrop_width?: number | null, landscapeMdCrop_height?: number | null, landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null, squareMdFit_url?: string | null, squareMdFit_width?: number | null, squareMdFit_height?: number | null, squareSmFit_url?: string | null, squareSmFit_width?: number | null, squareSmFit_height?: number | null, squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null, squareMdCrop_url?: string | null, squareMdCrop_width?: number | null, squareMdCrop_height?: number | null, squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null };

export type ImageSize_CommonFragment = { __typename?: 'assetsVolume_Asset', original_url?: string | null, original_height?: number | null, original_width?: number | null, bannerCrop_url?: string | null, bannerCrop_width?: number | null, bannerCrop_height?: number | null, fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null, landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null, landscapeMdCrop_url?: string | null, landscapeMdCrop_width?: number | null, landscapeMdCrop_height?: number | null, landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null, squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null, squareMdCrop_url?: string | null, squareMdCrop_width?: number | null, squareMdCrop_height?: number | null, squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null };

export type ImageBaseFragment = { __typename?: 'assetsVolume_Asset', mimeType?: string | null, id?: string | null, alt?: string | null, title?: string | null, url?: string | null, height?: number | null, width?: number | null };

export type ImageFragment = { __typename?: 'assetsVolume_Asset', mimeType?: string | null, id?: string | null, alt?: string | null, title?: string | null, url?: string | null, height?: number | null, width?: number | null, original_url?: string | null, original_height?: number | null, original_width?: number | null, bannerCrop_url?: string | null, bannerCrop_width?: number | null, bannerCrop_height?: number | null, fullFit_url?: string | null, fullFit_width?: number | null, fullFit_height?: number | null, landscapeLgCrop_url?: string | null, landscapeLgCrop_width?: number | null, landscapeLgCrop_height?: number | null, landscapeMdCrop_url?: string | null, landscapeMdCrop_width?: number | null, landscapeMdCrop_height?: number | null, landscapeSmCrop_url?: string | null, landscapeSmCrop_width?: number | null, landscapeSmCrop_height?: number | null, squareLgCrop_url?: string | null, squareLgCrop_width?: number | null, squareLgCrop_height?: number | null, squareMdCrop_url?: string | null, squareMdCrop_width?: number | null, squareMdCrop_height?: number | null, squareSmCrop_url?: string | null, squareSmCrop_width?: number | null, squareSmCrop_height?: number | null };

export const ImageSize_OriginalFragmentDoc = gql`
    fragment imageSize_original on AssetInterface {
  original_url: url
  original_height: width
  original_width: height
}
    `;
export const ImageSize_BannerCropFragmentDoc = gql`
    fragment imageSize_bannerCrop on AssetInterface {
  bannerCrop_url: url @transform(transform: "bannerCrop")
  bannerCrop_width: width @transform(transform: "bannerCrop")
  bannerCrop_height: height @transform(transform: "bannerCrop")
}
    `;
export const ImageSize_FullFitFragmentDoc = gql`
    fragment imageSize_fullFit on AssetInterface {
  fullFit_url: url @transform(transform: "fullFit")
  fullFit_width: width @transform(transform: "fullFit")
  fullFit_height: height @transform(transform: "fullFit")
}
    `;
export const ImageSize_LandscapeLgCropFragmentDoc = gql`
    fragment imageSize_landscapeLgCrop on AssetInterface {
  landscapeLgCrop_url: url @transform(transform: "landscapeLgCrop")
  landscapeLgCrop_width: width @transform(transform: "landscapeLgCrop")
  landscapeLgCrop_height: height @transform(transform: "landscapeLgCrop")
}
    `;
export const ImageSize_LandscapeMdCropFragmentDoc = gql`
    fragment imageSize_landscapeMdCrop on AssetInterface {
  landscapeMdCrop_url: url @transform(transform: "landscapeMdCrop")
  landscapeMdCrop_width: width @transform(transform: "landscapeMdCrop")
  landscapeMdCrop_height: height @transform(transform: "landscapeMdCrop")
}
    `;
export const ImageSize_LandscapeSmCropFragmentDoc = gql`
    fragment imageSize_landscapeSmCrop on AssetInterface {
  landscapeSmCrop_url: url @transform(transform: "landscapeSmCrop")
  landscapeSmCrop_width: width @transform(transform: "landscapeSmCrop")
  landscapeSmCrop_height: height @transform(transform: "landscapeSmCrop")
}
    `;
export const ImageSize_SquareMdFitFragmentDoc = gql`
    fragment imageSize_squareMdFit on AssetInterface {
  squareMdFit_url: url @transform(transform: "squareMdFit")
  squareMdFit_width: width @transform(transform: "squareMdFit")
  squareMdFit_height: height @transform(transform: "squareMdFit")
}
    `;
export const ImageSize_SquareSmFitFragmentDoc = gql`
    fragment imageSize_squareSmFit on AssetInterface {
  squareSmFit_url: url @transform(transform: "squareSmFit")
  squareSmFit_width: width @transform(transform: "squareSmFit")
  squareSmFit_height: height @transform(transform: "squareSmFit")
}
    `;
export const ImageSize_SquareLgCropFragmentDoc = gql`
    fragment imageSize_squareLgCrop on AssetInterface {
  squareLgCrop_url: url @transform(transform: "squareLgCrop")
  squareLgCrop_width: width @transform(transform: "squareLgCrop")
  squareLgCrop_height: height @transform(transform: "squareLgCrop")
}
    `;
export const ImageSize_SquareMdCropFragmentDoc = gql`
    fragment imageSize_squareMdCrop on AssetInterface {
  squareMdCrop_url: url @transform(transform: "squareMdCrop")
  squareMdCrop_width: width @transform(transform: "squareMdCrop")
  squareMdCrop_height: height @transform(transform: "squareMdCrop")
}
    `;
export const ImageSize_SquareSmCropFragmentDoc = gql`
    fragment imageSize_squareSmCrop on AssetInterface {
  squareSmCrop_url: url @transform(transform: "squareSmCrop")
  squareSmCrop_width: width @transform(transform: "squareSmCrop")
  squareSmCrop_height: height @transform(transform: "squareSmCrop")
}
    `;
export const ImageSize_AllFragmentDoc = gql`
    fragment imageSize_all on AssetInterface {
  ...imageSize_original
  ...imageSize_bannerCrop
  ...imageSize_fullFit
  ...imageSize_landscapeLgCrop
  ...imageSize_landscapeMdCrop
  ...imageSize_landscapeSmCrop
  ...imageSize_squareMdFit
  ...imageSize_squareSmFit
  ...imageSize_squareLgCrop
  ...imageSize_squareMdCrop
  ...imageSize_squareSmCrop
}
    `;
export const ImageBaseFragmentDoc = gql`
    fragment imageBase on AssetInterface {
  mimeType
  id
  alt
  title
  url @transform(transform: "fullFit")
  height @transform(transform: "fullFit")
  width @transform(transform: "fullFit")
}
    `;
export const ImageSize_CommonFragmentDoc = gql`
    fragment imageSize_common on AssetInterface {
  ...imageSize_original
  ...imageSize_bannerCrop
  ...imageSize_fullFit
  ...imageSize_landscapeLgCrop
  ...imageSize_landscapeMdCrop
  ...imageSize_landscapeSmCrop
  ...imageSize_squareLgCrop
  ...imageSize_squareMdCrop
  ...imageSize_squareSmCrop
}
    `;
export const ImageFragmentDoc = gql`
    fragment image on AssetInterface {
  ...imageBase
  ...imageSize_common
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;