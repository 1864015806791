import Image, { ImageProps } from '@components/Image';
import { useDefaultImage } from '@lib/store';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardImageProps = ImageProps;

export const CardImage = ({ ShimProps = {}, ...props }: CardImageProps) => {
  const { title, image: maybeImage } = useCard();

  const image = useDefaultImage(maybeImage);

  return (
    <Image
      alt={title ?? ''}
      {...mergePropsClassName(
        {
          transform: 'landscapeLgCrop',
          ratio: 'landscapeWide',
          ...image,
          ...props,
          // Max 600px wide
          sizes: '(max-width: 600px) 100vw, 680px',
        },
        style.image
      )}
      ShimProps={mergePropsClassName(ShimProps, style.imageWrap)}
    />
  );
};
