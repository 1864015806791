import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { FieldBaseFragmentDoc, FieldCommonFragmentDoc, FieldAttributeFragmentDoc, FieldOptionFragmentDoc } from '../../gql/_generated/fieldCommon.generated';
export type FieldAgreeFragment = { __typename: 'Field_Agree', uncheckedValue?: string | null, checkedValue?: string | null, inputTypeName?: string | null, description?: string | null, prePopulate?: string | null, defaultState?: boolean | null, defaultValue?: string | null, id?: string | null, name?: string | null, placeholder?: string | null, required?: boolean | null, labelPosition?: string | null, instructions?: string | null, handle?: string | null, errorMessage?: string | null, cssClasses?: string | null, conditions?: string | null, enableConditions?: boolean | null, inputAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null, containerAttributes?: Array<{ __typename?: 'FieldAttribute', label?: string | null, value?: string | null } | null> | null };

export const FieldAgreeFragmentDoc = gql`
    fragment fieldAgree on Field_Agree {
  ...fieldBase
  uncheckedValue
  checkedValue
  inputTypeName
  description
  prePopulate
  defaultState
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;