import { EntriesFragment } from '@gql/_generated/entries.generated';
import { withSite } from '@lib/hooks';
import { cleanHtml } from '@lib/utils/htmlHelpers';
import {
  makeNonNullableArray,
  mapObject,
  maybeGet,
  parseHref,
  toString,
} from '@liquorice/allsorts-craftcms-nextjs';
import { gql } from 'graphql-request';
import client from '../../fetch/client';
import { sanitiseEntry } from '../entries';
import { ImageEntry, parseImage } from '../images';
import { getSdk, NavigationNodeFragment } from './_generated/navigation.generated';

// ---------------------------------------------------------------------------------------------- //
// ---- GQL parts ----

gql`
  fragment navigationNode on NodeInterface {
    __typename
    id
    uri
    url
    level
    title
    newWindow
    typeLabel
    classes
    parent {
      id
    }
    element {
      uri
      language
    }
    ... on primaryNav_Node {
      image {
        ...image
      }
      descriptionHtml: htmlContentSimple
      entry: element {
        ...entryCard
      }
    }
  }
`;

// ---------------------------------------------------------------------------------------------- //
// ---- Set up the Types

export type NavMenuItem = {
  /**
   * List of CSS classes
   */
  classes?: string[] | null;
  /**
   * If this item links to the current view
   */
  current?: boolean;
  /**
   * If this item is the parent of an item that links to the current view
   */
  currentParent?: boolean;
  /**
   * Number of parent menus this item has
   */
  depth?: number;
  /**
   * Extended text for this item
   */
  descriptionHtml?: string | null;
  /**
   * Links to an external resource
   */
  external?: boolean;
  /**
   * URL for the link
   */
  href?: string | null;
  /**
   * The unique ID of this item
   */
  id: ID;
  /**
   * An image associated with this node
   */
  image: ImageEntry | null;
  /**
   * Should the link open in a new window
   */
  newWindow?: boolean;
  /**
   * The order within the flat list of items in this navigation
   */
  order?: number;
  /**
   * The unique ID of this item's parent, if exists
   */
  parentId?: ID | null;
  /**
   * Child items
   */
  subItems?: NavMenuItem[];
  /**
   * Txt label to display
   */
  title: string;
  /**
   * The item has a link or should be treated as a passive label
   */
  type: 'link' | 'passive';
};

export type NavMenuId = string;

export type NavMenu = {
  name?: string;
  items?: NavMenuItem[];
};

export type NavMenuItemCollection = { [key: ID]: NavMenuItem };

// ---------------------------------------------------------------------------------------------- //
// ---- Parsing Functions

/**
 * Recursively create a tree of NavMenuItems from the queried nodes
 */
const makeNavTree = (
  list: NavMenuItem[],
  collection: NavMenuItemCollection,
  branchId: ID | null = null,
  depth = 0
) => {
  const children = list.filter((item) => item.parentId === branchId);

  return children.map((item) => {
    (item.depth = depth), (item.subItems = makeNavTree(list, collection, item.id, depth + 1));
    return item;
  });
};

/**
 * Parsed the queried fragment
 */
const parseNavigation = (name?: string, data?: MaybeArrayOf<NavigationNodeFragment>): NavMenu => {
  const rawItems = makeNonNullableArray(data);

  const navItemsList = rawItems.reduce((results, item, order) => {
    const { classes, url, uri, newWindow, typeLabel, title, element } = item;

    const { uri: elementUri /* language  */ } = element ?? {};
    // Prefer the element relative uri
    const maybeHref = elementUri ?? uri ?? url;

    const id: ID = toString(item.id);
    const parentId: ID | null = item.parent?.id ? toString(item.parent.id) : null;

    const type = typeLabel?.toLowerCase() === 'passive' ? 'passive' : 'link';

    const { external, href } = parseHref(maybeHref);

    // -------------------------------------------------------------------------------------------------------
    // ---- Extract Entry from element where supplied

    const entry = sanitiseEntry(maybeGet(item, 'entry') as EntriesFragment);

    const entrySummary = maybeGet(entry, 'entrySummary');
    const entryImage = maybeGet(entry, 'entryImage');

    // -------------------------------------------------------------------------------------------------------

    /** Image provided directly to Nav Node */
    const customImage = parseImage(maybeGet(item, 'image'));

    const image = customImage ?? entryImage ?? null;

    /** Txt provided directly to Nav Node */
    const customDescriptionHtml =
      'descriptionHtml' in item ? cleanHtml(item.descriptionHtml as Maybe<string>) : null;

    const descriptionHtml = customDescriptionHtml || entrySummary || null;

    /** The final item */
    const navItem: NavMenuItem = {
      order,
      id,
      image,
      classes: toString(classes).split(' '),
      type,
      parentId,
      href,
      external,
      title: title ?? '',
      newWindow: newWindow === '1',
      descriptionHtml,
    };

    return results.concat(navItem);
  }, [] as NavMenuItem[]);

  const navItemsCollection = navItemsList.reduce(
    (results, item) => ({
      ...results,
      [item.id]: item,
    }),
    {} as NavMenuItemCollection
  );

  const items: NavMenuItem[] = makeNavTree(navItemsList, navItemsCollection);

  return {
    name,
    items,
  };
};

// ---------------------------------------------------------------------------------------------- //
// ---- The Getter ----

gql`
  query navMenus($site: [String] = "default") {
    ctaNavNodes: navigationNodes(site: $site, navHandle: "ctaNav") {
      ...navigationNode
    }
    primaryNavNodes: navigationNodes(site: $site, navHandle: "primaryNav") {
      ...navigationNode
    }
    policiesNavNodes: navigationNodes(site: $site, navHandle: "policiesNav") {
      ...navigationNode
    }
    footerNavNodes: navigationNodes(site: $site, navHandle: "footerNav") {
      ...navigationNode
    }
  }
`;

export const getAppNavigationMenus = async () => {
  const sdk = getSdk(client);
  const navMenus = await sdk.navMenus(withSite());

  const parsedMenus = mapObject(
    navMenus,
    (maybeMenu, key) => {
      if (!maybeMenu || typeof maybeMenu === 'string') return null;
      return parseNavigation(key, maybeMenu);
    },
    (v) => !!v
  );

  return {
    ctaNav: parsedMenus.ctaNavNodes,
    primaryNav: parsedMenus.primaryNavNodes,
    policiesNav: parsedMenus.policiesNavNodes,
    footerNav: parsedMenus.footerNavNodes,
  };
};

export type AppNavigationMenus = ReturnOrPromiseType<typeof getAppNavigationMenus>;

export type AppNavigationMenuName = keyof AppNavigationMenus;
