/* eslint-disable @typescript-eslint/no-explicit-any */

import { cleanHtml } from '@lib/utils/htmlHelpers';
import {
  createPropSanitiser,
  SanitiserTypes,
  toBoolean,
  toId,
  toStringOrNull,
  trimSlashes,
  Typename,
} from '@liquorice/allsorts-craftcms-nextjs';

import { parseFormieForm } from '@component/Formie/parse/formieParser';
import { EntriesFragment } from '@gql/_generated/entries.generated';
import { RawBlocks } from '@lib/features/blocks/blocks';
import { RawCategories } from '@lib/features/categories/categories';
import {
  parseDocument,
  parseLinkField,
  parseLinkFieldMultiple,
  parseNColumns,
  parsePageTemplate,
  parseTable,
} from '@lib/features/common';
import { parseColorTheme } from '@lib/features/custom';
import { RawGlobals } from '@lib/features/globals/globals';
import { parseImage, parseImageMany } from '@lib/features/images/image';
import { parseSeo } from '@lib/features/seo/seo';

/**
 * Union of all Typed Elements that may be passed to the sanitiser
 */
type RawElements = RawBlocks | RawGlobals | RawCategories | EntriesFragment;

/**
 * __typename of top level Elements
 */
type ElementTypename = Typename<RawElements>;

// ----------------------------------------------------------------------------------------------------
// --- Define the callbacks ---

export const sanitiseAnything = createPropSanitiser((): RawElements | null => null, {
  // alignment: parseAlignment,
  // textAlignment: parseTextAlignment,
  // ancestors: parseAncestors,
  content: cleanHtml,
  heading: toStringOrNull,
  caption: toStringOrNull,
  flipAlignment: toBoolean,
  id: toId,
  image: parseImage,
  bannerImage: parseImage,
  file: parseDocument,
  images: parseImageMany,
  // table: parseTable,
  label: toStringOrNull,
  linkCta: parseLinkField,
  linkCtaMultiple: parseLinkFieldMultiple,
  nColumns: parseNColumns,
  // pagesBranch: parsePagesTree,
  uri: (value?: string | null) => (value ? `/${trimSlashes(value)}/` : ''),
  title: toStringOrNull,
  pageTitle: toStringOrNull,
  pageTemplate: parsePageTemplate,
  form: parseFormieForm,
  entryLinks: parseLinkFieldMultiple,
  entryImage: parseImage,
  table: parseTable,
  colorTheme: parseColorTheme,
  seomatic: parseSeo,
  // parent: parseCommonLink,

  /**
   * Recursively sanitise child elements
   */
  blocks: 'sanitise',
  children: 'sanitise',
  articleSingle: 'sanitiseSingle',
  profileSingle: 'sanitiseSingle',
  entrySingle: 'sanitiseSingle',
  eventMultiple: 'sanitise',
  // Article Categories
  articleCategory: 'sanitise',
  // Learning Categories
  learningCategory: 'sanitise',
  learningLevel: 'sanitise',
  // Event Categories
  eventType: 'sanitise',
  ageGroup: 'sanitise',
  accessibility: 'sanitise',
  eventLocationSingle: 'sanitiseSingle',
  // Profile Categories
  profileCategory: 'sanitise',
  //
  socialLinks: 'sanitiseSingle',
  eventMeta: 'sanitiseSingle',
});

// ----------------------------------------------------------------------------------------------------
// --- Extracted sanitised types ---

type SanitiserReturnMap = SanitiserTypes<typeof sanitiseAnything, 'ReturnMap'>;

export type SanitisedElement<T extends ElementTypename = ElementTypename> = SanitiserReturnMap[T];

// ----------------------------------------------------------------------------------------------------
// --- Type guards ---

export const isSanitisedElement = <T extends ElementTypename>(
  x: any,
  typename: T
): x is SanitisedElement<T> => {
  return !!x && x.__typename === typename;
};
