import * as Types from '../../../../_generated/types';

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type AssetFragment = { __typename: 'assetsVolume_Asset', url?: string | null, id?: string | null, filename: string, extension: string, size?: string | null, title?: string | null };

export type LinkFieldFragment = { __typename: 'linkField_Link', target?: string | null, text?: string | null, title?: string | null, type?: string | null, url?: string | null, customText?: string | null };

export type LinkCtaMultipleFragment = { __typename: 'linkCtaMultiple_link_BlockType', linkCta?: { __typename: 'linkField_Link', target?: string | null, text?: string | null, title?: string | null, type?: string | null, url?: string | null, customText?: string | null } | null };

export type EntryLinksFragment = { __typename: 'entryLinks_link_BlockType', linkCta?: { __typename: 'linkField_Link', target?: string | null, text?: string | null, title?: string | null, type?: string | null, url?: string | null, customText?: string | null } | null };

export const AssetFragmentDoc = gql`
    fragment asset on assetsVolume_Asset {
  __typename
  url
  id
  filename
  extension
  size
  title
}
    `;
export const LinkFieldFragmentDoc = gql`
    fragment linkField on linkField_Link {
  __typename
  target
  text
  title
  type
  url
  customText
}
    `;
export const LinkCtaMultipleFragmentDoc = gql`
    fragment linkCtaMultiple on linkCtaMultiple_MatrixField {
  ... on linkCtaMultiple_link_BlockType {
    __typename
    linkCta {
      ...linkField
    }
  }
}
    `;
export const EntryLinksFragmentDoc = gql`
    fragment entryLinks on entryLinks_MatrixField {
  ... on entryLinks_link_BlockType {
    __typename
    linkCta {
      ...linkField
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {

  };
}
export type Sdk = ReturnType<typeof getSdk>;