/** The current site\
 *  Either Rare Trade Centre or Sovereign Hill (default)
 */
export const SITE =
  process.env.NEXT_PUBLIC_SITE === 'rareTradesCentre' ? 'rareTradesCentre' : 'default';

/** Is Rare Trades Centre site */
export const IS_RTC = SITE === 'rareTradesCentre';

/** App in 'development' environment */
export const IS_DEV = process.env.NODE_ENV === 'development';

export const TIMEZONE = process.env.NEXT_PUBLIC_TIMEZONE ?? 'Australia/Melbourne';
export const LOCALE = process.env.NEXT_PUBLIC_LOCALE ?? 'en-AU';
export const ENTRIES_PER_PAGE = 12;

export const SEPARATOR = `•`;

export enum IMG_RATIO {
  SQUARE = 1,
  PORTRAIT = 1.2,
  LANDSCAPE = 496 / 744, // 0.715,
  LANDSCAPE_TALL = 0.8, // ~4:3
  LANDSCAPE_WIDE = 0.54, // 16:9
  VIDEO = 0.5625, // 16:9
}

export type ImgRatios = typeof IMG_RATIO;
export type ImgRatio = keyof ImgRatios;
