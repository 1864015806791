import { unpackProps } from '@liquorice/allsorts-craftcms-nextjs';
import classNames from 'classnames';
import * as style from './Grid.css';
import { SprinklesProps } from '@theme';
import { Sprinkles } from '@theme/_sprinkles.css';

export type GridStyleProps = Exact<style.GridVariants> & SprinklesProps;

export type UseGridStyleProps = GridStyleProps & {
  className?: string;
};

export const useGridStyle = <T extends UseGridStyleProps>(props: T) => {
  const { rest, unpacked } = unpackProps(props, [
    'noGutter',
    'rowGutter',
    'columns',
    'columnsXs',
    'columnsSm',
    'columnsMd',
    'columnsLg',
    'columnsXl',
    'columnsMx',
  ]);

  const className = classNames(style.gridStyle(unpacked), props.className);

  const cx: Sprinkles = {
    flexWrap: 'wrap',
    flexDirection: 'row',
    ...props.cx,
  };

  return {
    ...rest,
    cx,
    className,
  };
};

export type GridColStyleProps = style.GridColVariants;

export type UseGridColStyleProps = GridColStyleProps & {
  className?: string;
};

export const useGridColStyle = <T extends UseGridColStyleProps>(props: T) => {
  const { rest, unpacked } = unpackProps(props, [
    'span',
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'mx',
    'offset',
    'offsetXs',
    'offsetSm',
    'offsetMd',
    'offsetLg',
    'offsetXl',
    'offsetMx',
  ]);

  const className = classNames(style.gridColStyle(unpacked), props.className);

  return {
    ...rest,
    className,
  };
};
