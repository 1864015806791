import CtaButton from '@component/CtaButton';
import Flex, { FlexProps } from '@componentPrimitives/Flex';
import { useGlobal } from '@lib/store';
import Txt from '@primitive/Txt';

export type OpenHoursProps = FlexProps<
  'div',
  {
    disableCta?: boolean;
  }
>;

const OpenHours = ({ disableCta, ...props }: OpenHoursProps) => {
  const openHours = useGlobal('openHours');

  if (!openHours) return null;

  const { content, heading, linkCta } = openHours;
  const { customText: _customText, ...ctaProps } = linkCta ?? {};
  return (
    <Flex /* spacing="xs" */ alignItems="start" {...props}>
      <Txt variant="bodyAlt" as="h5" bold autoMargin>
        {heading}
      </Txt>
      <Txt html>{content}</Txt>
      {!disableCta && linkCta?.href && <CtaButton {...ctaProps} cx={{ mT: 'sm' }} />}
    </Flex>
  );
};

export default OpenHours;
