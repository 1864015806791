import { CardProps } from '@components/Card/Card';
import SimpleGrid, { SimpleGridProps } from '@components/SimpleGrid';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import classNames from 'classnames';
import React from 'react';
import * as style from './CardGrid.css';
import { CardGridStyleProps, useCardGridStyle } from './useCardGridStyle';

type CardItem = { id: string | number };

export type CardGridProps<T extends CardItem> = Omit<SimpleGridProps, 'children'> & {
  items: T[];
  Component: (props: CardProps<T>) => React.ReactElement | null;
  CardProps?: Omit<CardProps<T>, 'item'>;
} & CardGridStyleProps;

const CardGrid = <T extends CardItem>({
  Component,
  CardProps,
  items,
  ItemProps: maybeItemProps = {},
  ...rest
}: CardGridProps<T>) => {
  // Apply root styles
  const props = useCardGridStyle(rest);

  // Add grid item class
  const ItemProps: SimpleGridProps['ItemProps'] = {
    className: classNames(maybeItemProps.className, style.gridItem),
    ...maybeItemProps,
  };

  // Add component class
  const componentProps = mergePropsClassName(CardProps, style.card);

  return (
    <SimpleGrid
      {...{
        ...props,
        ItemProps,
      }}>
      {items.map((item, i) => (
        <Component key={item.id ?? i} item={item} {...componentProps} />
      ))}
    </SimpleGrid>
  );
};

export default CardGrid;
