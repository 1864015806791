// import NavBarDrawer from '@components/NavBarDrawer';
import Flex, { FlexProps } from '@componentPrimitives/Flex';
import { MegaMenuTopLevelButton, MegaMenuTopLevelButtonProps } from './MegaMenu.TopLevelButton';
import { useMegaMenu, useMegaMenuVariant } from './useMegaMenu';

export type MegaMenuTopLevelProps<C extends React.ElementType = 'div'> = FlexProps<
  C,
  {
    ButtonProps?: Omit<MegaMenuTopLevelButtonProps, 'itemId'>;
  }
>;

export const MegaMenuTopLevel = <C extends React.ElementType = 'div'>({
  ButtonProps = {},
  ...props
}: MegaMenuTopLevelProps<C>) => {
  const ids = useMegaMenu((s) => s.ids);
  const { isDesktop } = useMegaMenuVariant();

  return (
    <Flex {...(props as FlexProps)}>
      {ids.map((id) => (
        <MegaMenuTopLevelButton key={id} variant="nav" {...ButtonProps} itemId={id} />
      ))}
    </Flex>
  );
};
