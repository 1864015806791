import Box from '@componentPrimitives/Box';
import Btn from '@componentPrimitives/Btn';
import Container from '@componentPrimitives/Container';
import Grid from '@componentPrimitives/Grid';
import EntryIndex from '@components/EntryIndex';
import { useRouterChangeStart } from '@lib/utils/useRouter';
import { Drawer } from '@mui/material';
import { useTranslations } from 'next-intl';
import React from 'react';
import { SearchDialogResults } from './SearchDialog.Results';
import { SearchDialogToggleButton, useSearchDialogToggle } from './SearchDialog.ToggleButton';
import * as style from './SearchDialog.css';
import SearchForm from '@components/SearchIndex/SearchForm';

export type SearchDialogProps = {
  /**/
};

const SearchDialog = ({}: SearchDialogProps) => {
  const t = useTranslations('common');
  const { value: open, toggle } = useSearchDialogToggle();

  const handleClose = () => toggle(false);
  const formRef = React.useRef<HTMLFormElement>(null);

  // Close the dialog on navigation
  useRouterChangeStart(handleClose);

  return (
    <>
      <SearchDialogToggleButton />
      <Drawer
        {...{
          open,
          onClose: handleClose,
          anchor: 'top',
          sx: { zIndex: 4000 },
          PaperProps: {
            elevation: 0,
            sx: {
              backgroundColor: 'transparent',
              overflow: 'visible',
            },
          },
        }}>
        <Box className={style.inner} paper>
          <EntryIndex indexKey="search">
            <Container cx={{ pT: '2xl', pB: '5xl' }}>
              <Grid>
                <Grid.Col cx={{ textAlign: 'right' }}>
                  <Btn onClick={handleClose} variant="nav" endIcon="close">
                    {t('close')}
                  </Btn>
                </Grid.Col>
                <Grid.Col md={10} lg={8}>
                  {/* <Txt as='input' variant='h4'  ></Txt> */}
                  <SearchForm ref={formRef} liveSubmit InputProps={{ variant: 'h4' }} />

                  {open && <SearchDialogResults />}
                </Grid.Col>
              </Grid>
            </Container>
          </EntryIndex>
        </Box>
      </Drawer>
    </>
  );
};

export default SearchDialog;
