import { NavMenuItem } from '@lib/features/navigation/navigation';
import * as style from './MegaMenu.css';
import { MegaMenuSubMenuItem } from './MegaMenu.SubMenuItem';

export type MegaMenuItemProps = {
  itemId: ID;
};

type MegaMenuSubMenuProps = {
  rootItem: NavMenuItem;
  items?: NavMenuItem[];
};

export const MegaMenuSubMenu = ({ items, ...props }: MegaMenuSubMenuProps) => {
  const rootItem = {
    ...props.rootItem,
    title: `${props.rootItem.title} Overview`,
  };

  return (
    <ul className={style.subMenu}>
      <li className={style.subMenuItem}>
        <MegaMenuSubMenuItem item={rootItem} size="large" />
      </li>
      {items?.map((item) => (
        <li key={item.id} className={style.subMenuItem}>
          <MegaMenuSubMenuItem item={item} />
        </li>
      ))}
    </ul>
  );
};
