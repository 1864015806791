import { IS_RTC, SITE } from '@lib/constants';

export const useSite = () => {
  return SITE;
};

/** Is the current site `rareTradesCentre` */
export const useIsRTC = () => IS_RTC;

export const withSite = <T extends Record<string, any>>(variables?: T | null) => {
  return {
    ...variables,
    // site: SITE,
  } as T & { site: string };
};
